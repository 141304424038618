import React, { useEffect } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "react-responsive";

const Share = () => {
  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View
      style={[
        styles.socialContainer,
        isDesktop && {
          backgroundColor: "#C2BAFF",
          paddingHorizontal: 8,
          paddingVertical: 4,
          shadowColor: "#e4e0db",
          shadowRadius: 0,
          shadowOffset: {
            width: 3,
            height: 3,
          },
        },
      ]}
    >
      <FacebookShareButton url={window.location.href}>
        <FontAwesomeIcon
          icon={faFacebook}
          style={{ fontSize: 20, lineHeight: 20, color: "#202020" }}
          width={20}
          height={20}
        />
      </FacebookShareButton>
      <TwitterShareButton url={window.location.href}>
        <FontAwesomeIcon
          icon={faTwitter}
          style={{
            fontSize: 20,
            lineHeight: 20,
            color: "#202020",
            marginLeft: 8,
          }}
          width={20}
          height={20}
        />
      </TwitterShareButton>
    </View>
  );
};

const styles = StyleSheet.create({
  socialContainer: {
    flexDirection: "row",
  },
  social: {
    marginHorizontal: 25,
    marginVertical: 10,
    flexDirection: "row",
  },
});

export default Share;
