import React from "react";
import { View, Text, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function InfoBadge(props: PropTypes.object) {
  return (
    <View
      style={[
        styles.mainContainer,
        commonStyles.smallRound,
        { backgroundColor: "#F9F8F7" },
      ]}
    >
      <Text style={[styles.text, commonStyles.headlineFont]}>{props.text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  text: {
    color: "#888683",
  },
});
