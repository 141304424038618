import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Author from "../author";
import ShareReact from "../sharereact";
import PropTypes from "prop-types";

function Comment(props: PropTypes.object) {
  return (
    <View
      style={[
        styles.mainContainer,
        props.inResponseTo !== null && styles.response,
      ]}
    >
      <Author
        name={
          props.user.firstName && props.user.lastName
            ? props.user.firstName + " " + props.user.lastName
            : null
        }
        party={props.user.party}
      />
      <Text style={styles.commentText}>{props.textContent}</Text>
      <View style={styles.shareReactContainer}>
        <ShareReact
          showDelete={props.showDelete}
          comment={props}
          showRespondButton={props.showRespondButton}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "#e8e8e8",
    paddingTop: 12,
  },
  response: {
    marginLeft: 24,
  },
  commentText: {
    marginTop: 12,
    marginBottom: 12,
  },
  shareReactContainer: {
    marginBottom: 12,
    flexDirection: "column",
  },
});

export default Comment;
