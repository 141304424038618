import React from "react";
import { StyleSheet, View, Text, Linking, Pressable } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import commonStyles from "../commonstyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { useNavigation } from "@react-navigation/native";
import { footerLinks, soMeLinks } from "./footer";

const contactDetails = [
  {
    icon: faLocationDot,
    detail: "Starrbäcksgatan 3, 172 74 Sundbyberg",
  },
  {
    icon: faPhone,
    detail: "08 641 10 10",
  },
  {
    icon: faEnvelope,
    detail: "chair@change-equity.com",
  },
];

export default function Footer() {
  const navigation = useNavigation();
  return (
    <View style={styles.footer}>
      <View style={styles.footerWrapper}>
        <View style={[styles.infoBoxLeft, commonStyles.smallShadow]}>
          {footerLinks.map((link, index) => (
            <Hoverable key={index}>
              {(isHovered) => (
                <Pressable onPress={() => navigation.navigate(link.screenName)}>
                  <Text
                    style={[styles.footerText, isHovered && styles.hovered]}
                  >
                    {link.footerText}
                  </Text>
                </Pressable>
              )}
            </Hoverable>
          ))}
        </View>
        <View style={[styles.infoBoxCenter, commonStyles.smallShadow]}>
          {contactDetails.map((contact, index) => (
            <Text key={index} style={styles.infoBoxCenterText}>
              <FontAwesomeIcon
                icon={contact.icon}
                style={{
                  fontSize: 20,
                  lineHeight: 20,
                  color: "#202020",
                  marginLeft: 8,
                }}
                width={20}
                height={20}
              />
              {"     "}
              {contact.detail}
            </Text>
          ))}
        </View>
        <View style={[styles.infoBoxRight, commonStyles.smallShadow]}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={[
                commonStyles.standardFont,
                { fontSize: 15, fontWeight: "400", lineHeight: 26 },
              ]}
            >
              © 2022 Noterat{" "}
            </Text>
          </View>
          <View style={[styles.infoBoxRightSocial, commonStyles.smallShadow]}>
            {soMeLinks.map((soMelink, index) => (
              <Pressable
                key={index}
                onPress={() => Linking.openURL(soMelink.link)}
              >
                <FontAwesomeIcon
                  icon={soMelink.icon}
                  style={{
                    fontSize: 20,
                    lineHeight: 20,
                    color: "#202020",
                    marginLeft: 8,
                  }}
                  width={17}
                  height={17}
                />
              </Pressable>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    backgroundColor: "#E4E0DB",
    flexDirection: "column",
    padding: 10,
  },
  footerWrapper: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  infoBoxLeft: {
    alignSelf: "flex-start",
    left: 25,
    textAlign: "left",
    justifyContent: "center",
  },
  infoBoxCenter: {
    flexStart: "left",
    justifyContent: "center",
    alignSelf: "flex-start",
  },
  infoBoxCenterText: {
    lineHeight: 30,
  },
  infoBoxRight: {
    alignSelf: "flex-start",
    right: 25,
    alignItems: "flex-end",
    justifyContent: "center",
    textAlign: "center",
  },
  footerText: {
    color: "#2D2D2D",
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 24,
  },
  infoBoxRightSocial: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 5,
  },
  hovered: {
    textDecorationLine: "underline",
  },
});
