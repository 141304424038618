/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
//import { REDIRECT_URL } from '@env'
const REDIRECT_URL = process.env.REDIRECT_URL;
const awsmobile = {
  aws_project_region: "eu-north-1",
  aws_cognito_identity_pool_id:
    "eu-north-1:186de0cb-0c20-461c-b472-72ec13f295bd",
  aws_cognito_region: "eu-north-1",
  aws_user_pools_id: "eu-north-1_cJIreioYx",
  aws_user_pools_web_client_id: "2085k21tcm30f9bgd0kq9ulseb",
  oauth: {
    domain: "auth.noterat.io",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: REDIRECT_URL || "http://localhost:19006/redirect",
    redirectSignOut: REDIRECT_URL || "http://localhost:19006/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
