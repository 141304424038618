import React, { useEffect } from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";
import { useMediaQuery } from "react-responsive";
import Header from "../../header/header";
import Footer from "../../footer/footer";
import { useRoute } from "@react-navigation/native";
import Back from "../../navigation/back";
import PropTypes from "prop-types";
import { FaqContent } from "./faqContent";
import commonStyles from "../../commonstyles";

export default function FAQ(props: PropTypes.object) {
  const route = useRoute();

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isTabletOrMobileDevice = useMediaQuery({
    maxWidth: 1200,
  });

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  return (
    <>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {!isMobile && <Back navigation={props.navigation} />}
        <View>
          {/* <View
            style={[
              !isTabletOrMobileDevice && commonStyles.purpleBackground,
              !isTabletOrMobileDevice && {
                height: 40,
                justifyContent: "center",
              },
              { marginTop: 48, alignSelf: "center" },
            ]}
          >
            <Text
              style={[
                styles.homeText,
                commonStyles.headlineFontBold,
                isTabletOrMobileDevice && { fontSize: 24 },
              ]}
            >
              FAQ
            </Text>
          </View> */}
          <FaqContent />
        </View>
      </ScrollView>
      <Footer />
    </>
  );
}
const styles = StyleSheet.create({
  homeText: {
    textAlign: "center",
    fontSize: 42,
    color: "#202020",
  },
});
