import React from "react";
import { View, StyleSheet, Text } from "react-native";
import PropTypes from "prop-types";
import OrganFiltering from "./organfiltering";
import { minutesToHoursAndMinutes, TimeRange } from "../../helpers";
import { useMediaQuery } from "react-responsive";
import commonStyles from "../commonstyles";
import TimePeriodFiltering from "./timeperiodfiltering";
import { useSelector } from "react-redux";

export default function TalkTable(props: PropTypes.object) {
  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });
  const timePeriod = useSelector((state) => state.data.timePeriod);

  return (
    <View>
      <View
        style={[
          styles.summaryBox,
          !isDesktop && { flexDirection: "column" },
          commonStyles.smallShadow,
          commonStyles.smallRound,
        ]}
      >
        <View style={isDesktop ? { flex: 1 } : { marginBottom: 4 }}>
          <Text
            style={[
              styles.summaryTitle,
              commonStyles.standardFont,
              { color: "#888683" },
            ]}
          >
            FILTRERA PÅ ÄMNE
          </Text>
          <OrganFiltering />
        </View>
        {isDesktop && <View style={{ width: 16 }}></View>}
        <View style={isDesktop && { flex: 1 }}>
          <Text
            style={[
              styles.summaryTitle,
              commonStyles.standardFont,
              { color: "#888683" },
            ]}
          >
            TOPP FEM TALARTID
          </Text>
          {/* <TimePeriodFiltering /> */}
          {props.subjects !== undefined && props.subjects.length !== 0 ? (
            props.subjects.slice(0, 5).map((subject, index) => (
              <View style={styles.link} key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Text
                    style={[
                      {
                        color: "#E4E0DB",
                        marginRight: 12,
                      },
                      commonStyles.headlineFont,
                    ]}
                  >
                    {index + 1}
                  </Text>
                  <Text
                    style={[{ color: "#888683" }, commonStyles.standardFont]}
                  >
                    {subject}
                  </Text>
                </View>
                {/* TODO  remove below when backend is implemented */}
                <Text
                  style={[
                    { color: "#E4E0DB", marginLeft: 12 },
                    commonStyles.standardFont,
                  ]}
                >
                  {timePeriod}
                </Text>
                <Text
                  style={[
                    { color: "#E4E0DB", marginLeft: 12 },
                    commonStyles.standardFont,
                  ]}
                >
                  {minutesToHoursAndMinutes(props.minutes[index])}
                </Text>
              </View>
            ))
          ) : (
            <Text style={styles.noneFoundText}>Ingen data att visa!</Text>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  summaryBox: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#F9F8F7",
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
  },
  summaryTitle: {
    color: "#4a4a4a",
    marginBottom: 12,
  },
  link: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
    padding: 12,
    borderWidth: 1,
    borderColor: "#E4E0DB",
    borderRadius: 6,
    marginBottom: 8,
    shadowColor: "#efefef",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    width: "100%",
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
    marginLeft: 16,
  },
  noneFoundText: {
    color: "#4a4a4a",
    alignSelf: "center",
    width: "100%",
    justifyContent: "center",
    padding: 12,
    borderWidth: 1,
    borderColor: "#efefef",
    borderRadius: 6,
    marginBottom: 8,
    shadowColor: "#efefef",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
  },
});
