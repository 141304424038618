import React from "react";
import { View, StyleSheet, Text } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function MissionBox(props: PropTypes.object) {
  return (
    <View
      style={[
        {
          marginTop: 12,
          flexDirection: "row",
          marginLeft: 16,
        },
        !props.isDesktop && { flexDirection: "column", marginLeft: 0 },
      ]}
    >
      <View style={props.isDesktop && { flex: 1 }}>
        <Text
          style={[styles.title, { marginBottom: 8 }, commonStyles.headlineFont]}
        >
          Offentliga åtaganden och uppdrag
        </Text>

        {props.missions.map((mission, index) => (
          <View key={index}>
            <Text style={[{ marginBottom: 2 }, commonStyles.standardFontBold]}>
              {mission.roleCode}
            </Text>
            <Text
              style={[
                !(index === props.missions.length - 1) && {
                  marginBottom: 8,
                },
                commonStyles.standardFont,
              ]}
            >
              {mission.status === "Tjänstgörande" && "Tjänstgörande"}
              {mission.type === "uppdrag" &&
                "Uppdrag inom " + mission.assignment}
              {mission.roleCode === "Statsrådsersättare" &&
                "Ersättare för " + mission.assignment}
              {mission.roleCode === "Ersättare" &&
                "Ersättare för " + mission.assignment}
              {mission.type === "Riksdagsorgan" &&
                mission.roleCode === "Suppleant" &&
                "Uppdrag i riksdagsorgan " + mission.assignment}
            </Text>
          </View>
        ))}
      </View>
      {props.isDesktop && (
        <View
          style={{
            width: 1,
            height: "100%",
            backgroundColor: "#f0f0f0",
            marginHorizontal: 16,
          }}
        ></View>
      )}
      <View
        style={[
          props.isDesktop && { flex: 1 },
          !props.isDesktop && { marginTop: 16 },
        ]}
      >
        <Text
          style={[styles.title, { marginBottom: 8 }, commonStyles.headlineFont]}
        >
          Bakgrund
        </Text>
        {props.personal.map((background, index) => {
          if (background.assignment) {
            return (
              <View key={index}>
                <Text
                  style={[{ marginBottom: 2 }, commonStyles.standardFontBold]}
                >
                  {background.code}
                </Text>
                <Text
                  style={[
                    !(index === props.personal.length - 1) && {
                      marginBottom: 8,
                    },
                    commonStyles.standardFont,
                  ]}
                >
                  {background.assignment}
                </Text>
              </View>
            );
          } else {
            return <View key={index}></View>;
          }
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  title: { color: "#a4a4a4" },
});
