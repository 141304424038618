const data = {
  title: "",
  rows: [
    {
      title: "Vilka ligger bakom Noterat?",
      content:
        "Plattformen initierades av Change Equity’s Ordförande, Allan Caman, efter ett samtal med Tobias Franzén, VD för The Amazing Society och en av initiativtagarna till ”Kavla upp-kampanjen”. Samtalet cirkulerade kring svårigheten att följa upp och få en översiktlig bild av förtroendevaldas faktiska aktiviteter och resultat, vilket vi ansåg var problematiskt då det leder till att politiker utvärderas på deras kampanjbudskap och inte deras faktiska handlingar i den demokratiska apparaten samt att intransparenta processer skapar en grogrund för desinformation och feltolkningar. Teamet bakom Noterat.io består idag av 2 jurister, 8 programmerare, en ekonom, ett Advisory board bestående av tre personer, samt ett brett nätverk av rådgivare och stödresurser.",
    },
    {
      title: "Varför finns noterat?",
      content:
        "Vi lever i en hektisk tid där uppmärksamheten är dyrbar. Den politiska processen är samtidigt komplex och svår att följa. Orienteringen mellan partierna blir än mer komplicerade under valrörelsen, då stora mängder energi och resurser riktas åt att påverka väljarnas uppfattning - av flertalet aktörer med skilda agendor. Förtroendet för den politiska processen är en grundpelare i ett demokratiskt samhälle. Vi anser därför att det är viktigt att den utvärderas på sakliga grunder i högsta möjliga utsträckning.Vi vill därför tillhandahålla en neutral, användarvänlig och transparent källa för att skapa goda förutsättningar för en nykter maktfördelning och ett konstruktivt politiskt klimat. Genom att ge underlag till en politisk debatt kring specifika händelser snarare än politiska narrativ hoppas vi fördjupa den politiska allmänbildningen",
    },
    {
      title: "Var får ni informationen ifrån?",
      content:
        "All vår data är extraherad från Riksdagens öppna API eller hemsida. I syfte att bevara plattformens neutralitet har vi valt att inte förenkla, översätta eller sammanfatta offentliga handlingar, utan istället publicera dem i originalform och kronologisk ordning. På plattformen kan ni även hitta bearbetad statistik. Dessa sammanställningar har baserats på vedertagna statistiska metoder och syftar endast till att skapa en översikt kring den datan som hålls tillgänglig av Riksdagen. Notera att vissa korrigeringar har gjorts i form av exkluderade datapunkter, som enligt Riksdagens egna uppgifter är felaktiga på grund av tekniska problem i samband med mätningar av talartid.",
    },
    {
      title: "Var får ni finansiering ifrån?",
      content:
        "Projektet initierades och har hittills finansierats av den ideella föreningen Change Equity, vars syfte är att främja projekt med en anknytning till något utav FN:s globala mål för hållbar utveckling.Noterat.io har även fått stöd i form av kompetensresurser, som t.ex. rådgivning inom UX, utveckling, marknadsföring och PR såväl som privata donationer från allmänheten.Vi välkomnar alla bidrag eller insatser till organisationen, under förutsättning att det inte har en negativ inverkan på organisationens oberoende eller plattformens neutralitet. Vill du vara med och stötta Noterat?Swisha isåfall en valfri summa till 1236229454 eller kontakta oss med ett förslag på chair@change-equity.com.",
    },
    {
      title: " Hur behandlar ni personuppgifter?",
      content:
        "Noterat.io tar din personliga integritet på största allvar. Dina personuppgifter är säkra hos oss och används endast enligt förutbestämd laglig grund och verksamhetens syfte. Samtlig data av politisk karaktär är krypterad, vilket innebär att vi inte har möjlighet att identifiera enskilda användares politiska bevakningar eller aktiviteter, och behandlar samtliga personuppgifter du lämnar över till oss i enlighet med EU:s dataskyddsförordning General Data Protection Regulation (GDPR).Personuppgiftsansvarig är Change Equity, representerad av Allan Caman. Du kan läsa mer om vår behandling av personuppgifter i vår >Integritetspolicy<. Vid frågor eller funderingar, var god kontakta chair@change-equity.com.",
    },
  ],
};

export default data;
