import React from "react";
import { View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../../slices/dataslice";
import Checkbox from "../../event/checkbox";

export default function Type() {
  const filters = useSelector((state) => state.data.filters.filter);
  const dispatch = useDispatch();

  return (
    <View>
      <View>
        <Checkbox
          text="Skriftliga frågor och svar"
          checked={filters["SKRIFTLIG FRÅGA"]}
          onChange={() => dispatch(setFilters("SKRIFTLIG FRÅGA"))}
        />
        <Checkbox
          text="KU-anmälningar"
          checked={filters["KU ANMÄLAN"]}
          onChange={() => dispatch(setFilters("KU ANMÄLAN"))}
        />
        <Checkbox
          text="Motioner"
          checked={filters.MOTION}
          onChange={() => dispatch(setFilters("MOTION"))}
        />
        <Checkbox
          text="Interpellation"
          checked={filters.IP}
          onChange={() => dispatch(setFilters("IP"))}
        />
        <Checkbox
          text="Yttrande"
          checked={filters.YTTRANDE}
          onChange={() => dispatch(setFilters("YTTRANDE"))}
        />
      </View>
    </View>
  );
}
