import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { getStatisticsUrl, getStatisticsUrlParliament } from "../../helpers";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import TalkTable from "./talktable";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import CheckButton from "../header/checkbutton";

export default function Politician(props: PropTypes.object) {
  const [rawStatistics, setRawStatistics] = useState({
    initial: false,
    foundNone: false,
    data: [],
  });
  const [displayStatistics, setDisplayStatistics] = useState(undefined);

  const top20 = 20;
  const organs = useSelector((state) => state.data.committees);
  const timePeriod = useSelector((state) => state.data.timePeriod);

  const dispatch = useDispatch();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "left" as const,
      },
      title: {
        display: false,
        text: "Talartid",
      },
    },
    radius: "90%",
  };

  const getLabels = () => {
    let labels;
    // Check if all organs are selected, if so, use organ titles.
    if (organs.filter((organ) => organ.selected).length === organs.length) {
      labels = rawStatistics.data.map((obj) => {
        if (organs.find((orgObj) => orgObj.committee === obj.organ)) {
          return organs.find((orgObj) => orgObj.committee === obj.organ).title;
        }
        return "Annat";
      });
    } else {
      labels = rawStatistics.data.map((e) => e.title);
    }
    return labels;
  };

  useEffect(() => {
    if (props.identifier === undefined || organs.length === 0) {
      return;
    }
    if (props.parliament) {
      var url = getStatisticsUrlParliament(
        organs.filter((organ) => organ.selected),
        timePeriod
      );
    } else {
      var url = getStatisticsUrl(
        props.politicianOrParty,
        props.identifier,
        organs.filter((organ) => organ.selected),
        timePeriod
      );
    }
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        json.length !== 0
          ? setRawStatistics({ initial: false, foundNone: false, data: json })
          : setRawStatistics({ initial: false, foundNone: true, data: [] });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [organs, timePeriod, props.identifier]);

  useEffect(() => {
    if (rawStatistics.initial || rawStatistics.foundNone) {
      setDisplayStatistics(undefined);
    }
    setDisplayStatistics({
      labels: getLabels().slice(0, top20),
      datasets: [
        {
          data: rawStatistics.data
            .slice(0, top20)
            .map((e) => Math.floor(e.talkTime / 60)),
          backgroundColor: rawStatistics.data.slice(0, top20).map((e, i) => {
            if (i % 2 === 0) {
              return "#F9F8F7";
            }
            return "#E4E0DB";
          }),
          hoverOffset: 4,
        },
      ],
    });
  }, [rawStatistics]);

  return (
    <View>
      {organs.length === 0 && (
        <View style={[styles.loader, { marginTop: 0, marginBottom: 0 }]}>
          <Text style={styles.loaderText}>Laddar...</Text>
        </View>
      )}
      {organs.length !== 0 && (
        <View style={{ width: "100%" }}>
          {rawStatistics.initial && (
            <View style={styles.loader}>
              <Text style={styles.loaderText}>Loading...</Text>
            </View>
          )}
          {rawStatistics.foundNone && (
            <View style={styles.loader}>
              <Text style={styles.loaderText}>Ingen data att visa!</Text>
            </View>
          )}
          {!rawStatistics.initial &&
            !rawStatistics.foundNone &&
            displayStatistics !== undefined && (
              <View style={{ height: 300, marginTop: 1, alignSelf: "center" }}>
                <Doughnut data={displayStatistics} options={options} />
              </View>
            )}
          <View
            style={{
              width: "100%",
              alignItems: "center",
              marginTop: 1,
              marginBottom: 16,
            }}
          ></View>
          <TalkTable
            subjects={
              displayStatistics !== undefined
                ? displayStatistics.labels
                : undefined
            }
            minutes={
              displayStatistics !== undefined
                ? displayStatistics.datasets[0].data
                : undefined
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContent: {
    width: 1000,
    alignItems: "center",
    alignSelf: "center",
  },
  loader: {
    justifyContent: "center",
    backgroundColor: "#fafafa",
    borderRadius: 8,
    height: 42,
    paddingHorizontal: 16,
    marginBottom: 15,
    textAlign: "center",
  },
  loaderText: {
    color: "#4a4a4a",
  },
  mainContentSmall: {
    width: "100%",
    paddingHorizontal: 24,
    alignItems: "center",
  },
  segmentContainer: {
    width: "100%",
    padding: 16,
    marginBottom: 16,
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 12,
  },
  pictureShadow: {
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 8,
    alignSelf: "flex-start",
  },
  picture: {
    width: 192 / 2,
    height: 256 / 2,
    borderRadius: 8,
  },
  nameText: {
    marginLeft: 16,
    fontSize: 20,
    color: "#202020",
    fontWeight: "700",
  },
  expandInfoButton: {
    marginTop: 16,
    width: "100%",
    height: 42,
    borderRadius: 8,
    backgroundColor: "#fafafa",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
    marginLeft: 16,
  },
  segmentTitle: {
    color: "#a4a4a4",
    fontSize: 14,
    marginBottom: 16,
  },
});
