import React, { useState } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import commonStyles from "../commonstyles";
import StaticCoverage from "./staticcoverage";
import PartyChooser from "./../politicianregistry/partychooser";
import PoliticianSearchBox from "../header/filtersegment/politiciansearchbox";
import FeedOrganFiltering from "../header/filtersegment/feedorganfiltering";
import { useSelector, useDispatch } from "react-redux";
import { setCoverage } from "../../slices/dataslice";
import { baseURLForServer } from "../../config";

export default function Coverage() {
  const [view, setView] = useState(0);
  const [savedCoverage, setSavedCoverage] = useState("");

  const userData = useSelector((state) => state.data.userData);
  const dispatch = useDispatch();

  const saveCoverage = () => {
    fetch(baseURLForServer + "/users/" + userData.id + "/filter-presets", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
      body: JSON.stringify(userData.filterPresets),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <View style={styles.container}>
      {view === 0 && (
        <View style={{ width: "100%" }}>
          <StaticCoverage />
          <Pressable
            style={[
              styles.button,
              commonStyles.yellowBackground,
              commonStyles.smallRound,
            ]}
            onPress={() => {
              setSavedCoverage(userData.filterPresets);
              setView(1);
            }}
          >
            <Text style={commonStyles.headlineFont}>
              Konfigurera bevakningar
            </Text>
          </Pressable>
        </View>
      )}
      {view === 1 && (
        <View style={styles.stepView}>
          <Text style={[commonStyles.headlineBold, styles.stepHeadline]}>
            Ämne
          </Text>
          <FeedOrganFiltering coverageMode />
          <View style={styles.stepperView}>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => {
                dispatch(setCoverage(savedCoverage));
                setView(0);
              }}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Avbryt
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => setView(2)}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Nästa
              </Text>
            </Pressable>
          </View>
        </View>
      )}
      {view === 2 && (
        <View style={styles.stepView}>
          <Text style={[commonStyles.headlineBold, styles.stepHeadline]}>
            Parti
          </Text>
          <Text style={[commonStyles.standardFont, styles.stepText]}>
            Välj partier att bevaka.
          </Text>
          <PartyChooser coverageMode />
          <View style={styles.stepperView}>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => {
                dispatch(setCoverage(savedCoverage));
                setView(0);
              }}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Avbryt
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => setView(3)}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Nästa
              </Text>
            </Pressable>
          </View>
        </View>
      )}
      {view === 3 && (
        <View style={styles.stepView}>
          <Text style={[commonStyles.headlineBold, styles.stepHeadline]}>
            Politiker
          </Text>
          <Text style={[commonStyles.standardFont, styles.stepText]}>
            Välj politiker att bevaka.
          </Text>
          <PoliticianSearchBox coverageMode />
          <View style={styles.stepperView}>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => {
                dispatch(setCoverage(savedCoverage));
                setView(0);
              }}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Avbryt
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.nextCancelButton,
                commonStyles.smallRound,
                commonStyles.smallShadow,
              ]}
              onPress={() => {
                saveCoverage();
                setView(0);
              }}
            >
              <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
                Spara
              </Text>
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    alignItems: "center",
    padding: 12,
  },
  stepView: {
    width: "100%",
    alignItems: "center",
  },
  stepperView: {
    flexDirection: "row",
    marginTop: 16,
    gap: 16,
  },
  nextCancelButton: {
    width: 120,
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  stepHeadline: {
    marginBottom: 4,
    color: "#202020",
  },
  stepText: {
    marginBottom: 16,
    color: "#202020",
  },
});
