import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

function ProfileChooser(props: PropTypes.object) {
  return (
    <View style={styles.mainContainer}>
      <Pressable
        style={[
          styles.optionView,
          { marginLeft: 0 },
          commonStyles.yellowBackground,
          props.chosen === 0 && commonStyles.purpleBackground,
        ]}
        onPress={() => props.onChoose(0)}
      >
        <Text style={[styles.optionText, commonStyles.headlineFont]}>
          Mina bevakningar
        </Text>
      </Pressable>
      <Pressable
        style={[
          styles.optionView,
          commonStyles.yellowBackground,
          props.chosen === 1 && commonStyles.purpleBackground,
        ]}
        onPress={() => props.onChoose(1)}
      >
        <Text style={[styles.optionText, commonStyles.headlineFont]}>
          Sparade händelser
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 24,
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 12,
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 5,
  },
  optionView: {
    paddingHorizontal: 12,
    paddingVertical: 12,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
    borderRadius: 8,
  },
  optionText: {
    color: "#202020",
    textAlign: "center",
  },
});

export default ProfileChooser;
