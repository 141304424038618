import React, { useState } from "react";
import { View, Modal, Pressable, StyleSheet, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { baseURLForServer } from "../../config";
import { newPolitician } from "../../slices/dataslice";
import commonStyles from "../commonstyles";
import ModalLogin from "../modallogin";

const followButton = ({ politician }) => {
  const { userData } = useSelector((state) => state.data);
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  const addPolitician = (p) => {
    if (userData.filterPresets) {
      var politician = JSON.parse(userData.filterPresets.politician) || [];
    } else {
      var politician: any = [];
    }
    if (politician) {
      politician.push({
        id: String(p.id),
        name: p.firstName + " " + p.familyName,
        party: p.party,
      });
      return JSON.stringify(politician);
    } else {
      politician.push({
        id: String(p.id),
        name: p.firstName + " " + p.familyName,
        party: p.party,
      });
      return JSON.stringify(politician);
    }
  };

  const removePolitician = (p) => {
    // Not really necessary...
    if (!userData.filterPresets) {
      return "[]";
    }
    let politician = JSON.parse(userData.filterPresets.politician);
    if (politician) {
      politician = politician.filter(
        (politician) => String(politician.id) !== String(p.id)
      );
      return JSON.stringify(politician);
    } else {
      return "[]";
    }
  };

  const handleTogglePolitician = (p) => {
    if (!follows(p.id)) {
      var newPoliticians = addPolitician(p);
    } else {
      var newPoliticians = removePolitician(p);
    }
    if (Object.keys(userData).length !== 0) {
      fetch(baseURLForServer + "/users/" + userData.id + "/filter-presets", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userData.jwt,
        },
        body: JSON.stringify({
          politician: newPoliticians,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(newPolitician(newPoliticians));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setModalVisible(true);
    }
  };

  const follows = (politicianId) => {
    return (
      userData &&
      userData.filterPresets &&
      userData.filterPresets !== null &&
      userData.filterPresets.politician &&
      userData.filterPresets.politician !== null &&
      JSON.parse(userData.filterPresets.politician)
        .map((p) => String(p.id))
        .includes(String(politicianId))
    );
  };

  return (
    <View>
      <Pressable
        onPress={() => handleTogglePolitician(politician)}
        style={[
          follows(politician.id)
            ? commonStyles.purpleBackground
            : { backgroundColor: "#E4E0DB" },
          commonStyles.smallRound,
          { paddingHorizontal: 24, height: 24, justifyContent: "center" },
        ]}
      >
        <Text style={[commonStyles.headlineFontBold, { color: "white" }]}>
          {follows(politician.id) ? "Följer" : "Följ"}
        </Text>
      </Pressable>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <ModalLogin close={() => setModalVisible(false)} />
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    backgroundColor: "red",
    alignItems: "center",
    marginHorizontal: "auto",
  },
});

export default followButton;
