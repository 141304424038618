import { ActivityIndicator, StyleSheet, View } from "react-native";
import React from "react";

export default function RedirectPage() {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color="#C2BAFF" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    justifyContent: "center",
  },
});
