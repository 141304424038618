import React from "react";
import { StyleSheet, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import DesktopFooter from "./desktopFooter";
import MobileFooter from "./mobileFooter";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export const footerLinks = [
  {
    screenName: "OmOss",
    footerText: "Om Noterat",
  },
  {
    screenName: "FAQ",
    footerText: "Vanliga frågor",
  },
  {
    screenName: "Contact",
    footerText: "Kontakt",
  },
  {
    screenName: "Användarvillkor",
    footerText: "Allmänna villkor",
  },
  {
    screenName: "Integritetspolicy",
    footerText: "Integritetspolicy",
  },
];

export const soMeLinks = [
  {
    link: "https://www.facebook.com/",
    icon: faFacebook,
  },
  {
    link: "https://twitter.com/",
    icon: faTwitter,
  },
  {
    link: "https://www.linkedin.com/",
    icon: faLinkedin,
  },
];

export default function Footer() {
  const isDesktop = useMediaQuery({
    minWidth: 600,
  });
  return isDesktop ? (
    <View style={styles.footer}>
      <DesktopFooter />
    </View>
  ) : (
    <View style={styles.footer}>
      <MobileFooter />
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    bottom: 0,
    right: 0,
    width: "100%",
    fontFamily: "Museo Sans",
  },
});
