import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Pressable,
  Image,
} from "react-native";
import { Auth } from "aws-amplify";
import commonStyles from "./commonstyles";
import ModalRecoverPassword from "./modalrecoverpassword";

export default function ModalConfirmation(props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [recoverPassword, setRecoverPassword] = useState(false);
  const [emailToRecoverPassword, setEmailToRecoverPassword] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState("");
  const [showResendCodeButton, setShowResendCodeButton] = useState(false);

  const submitVerificationCode = async () => {
    setShowResendCodeButton(false);

    try {
      await Auth.confirmSignUp(props.username, verificationCode);
      setConfirmationStatus(
        "Användare skapad med framgång.Välkommen till Noterat.io!"
      );
      await props.onLogin({
        email: props.username,
        password: props.password,
      });
    } catch (error) {
      if (error.code === "ExpiredCodeException") {
        setShowResendCodeButton(true);
      } else if (error.code === "CodeMismatchException") {
        setConfirmationStatus(
          "Ogiltig verifieringskod har angetts, försök igen"
        );
      } else {
        setConfirmationStatus("Något gick fel");
        console.log(error);
      }
    }
  };

  const resendConfirmationCode = async () => {
    setShowResendCodeButton(false);
    try {
      await Auth.resendSignUp(props.username);
      setConfirmationStatus("En ny kod har skickats till din e-post");
    } catch (err) {
      setConfirmationStatus("Det gick inte att skicka koden igen");
    }
  };

  const submitRecoverPassword = () => {
    try {
      Auth.forgotPassword(emailToRecoverPassword);
      setRecoverPassword(true);
    } catch (err) {
      console.log(err);
      setConfirmationStatus("Felmeddelande. Koden skickades inte till e-post");
      setRecoverPassword(false);
    }
  };

  return (
    <View style={[{ width: 400 }, styles.centeredView]}>
      {recoverPassword ? (
        <ModalRecoverPassword close={() => props.close()} />
      ) : (
        <View
          style={[
            styles.modalView,
            commonStyles.bigRound,
            commonStyles.bigShadow,
          ]}
        >
          <Pressable style={styles.button} onPress={() => props.close()}>
            <Image
              source={require("../public/images/cross.svg")}
              style={{ width: 8, height: 8 }}
            />
          </Pressable>
          <View>
            <Text style={styles.message}>
              {props.forgotPassword
                ? "Vänligen ange din e-postadress nedan så att vi kan skicka en länk till dig för att återställa ditt lösenord."
                : "Vänligen kontrollera din e-post, vi har redan skickat en verifieringskod."}
            </Text>
            <TextInput
              style={styles.input}
              onChangeText={
                props.forgotPassword
                  ? setEmailToRecoverPassword
                  : setVerificationCode
              }
              value={
                props.forgotPassword ? emailToRecoverPassword : verificationCode
              }
              placeholder={props.forgotPassword ? "" : "Ex: 123456"}
            />
            <Pressable
              onPress={
                props.forgotPassword
                  ? submitRecoverPassword
                  : submitVerificationCode
              }
              disabled={
                verificationCode.length < 4 && emailToRecoverPassword.length < 4
                  ? true
                  : false
              }
              style={styles.submitButton}
            >
              <Text style={{ textAlign: "center" }}>Bekräfta</Text>
            </Pressable>
            {showResendCodeButton ? (
              <Text style={{ marginVertical: 5 }}>
                Denna kod har upphört att gälla, klicka
                <Pressable onPress={resendConfirmationCode}>
                  <Text style={styles.link}> här</Text>
                </Pressable>{" "}
                för att skicka en ny verifieringskod till din e-post.
              </Text>
            ) : null}
            <Text style={styles.confirmationStatus}>{confirmationStatus}</Text>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: "#F9F8F7",
    margin: 20,
    padding: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    marginTop: 22,
    marginHorizontal: "auto",
  },
  button: {
    alignSelf: "flex-end",
    width: 24,
    height: 24,
    borderColor: "#202020",
    borderWidth: 2,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    lineHeight: 18,
    marginVertical: 5,
  },
  input: {
    borderRadius: 3,
    borderWidth: 1,
    fontSize: 16,
    padding: 6,
    marginVertical: 5,
  },
  submitButton: {
    backgroundColor: "#FFFE9A",
    borderRadius: 8,
    marginVertical: 5,
    marginHorizontal: "auto",
    padding: 10,
    width: "100%",
  },
  link: {
    color: "#214FEE",
    fontWeight: "bold",
    paddingHorizontal: 4,
  },
  confirmationStatus: {
    backgroundColor: "#C2BAFF",
    marginTop: 2,
  },
});
