import React from "react";
import { StyleSheet, Text, Pressable } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function Button(props: PropTypes.object) {
  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable
          onPress={() => props.onThePress(props.button)}
          style={[
            styles.menuButton,
            props.fixedWidth && {
              width: props.fixedWidth,
              justifyContent: "center",
            },
            commonStyles.smallShadow,
            commonStyles.borderStyle,
            props.wide && { width: "100%", justifyContent: "center" },
            props.style,
          ]}
        >
          <Text
            style={[
              styles.buttonText,
              commonStyles.headlineFont,
              isHovered && commonStyles.buttonIsHovered,
            ]}
          >
            {props.button}
          </Text>
        </Pressable>
      )}
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  menuButton: {
    paddingHorizontal: 16,
    height: 36,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText: {
    color: "#202020",
  },
  arrow: {
    width: 7,
    height: 12,
    marginRight: 16,
    opacity: 1,
  },
});
