import React from "react";
import { Text } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { baseURLForClient } from "./config";
import { Provider } from "react-redux";
import store from "./store";
import All from "./all";
import { navigationRef } from "./root_navigation";

if (typeof window !== 'undefined') {
  require('smoothscroll-polyfill').polyfill()
}

const linking = {
  prefixes: [baseURLForClient],
  config: {
    screens: {
      LandingPage: "/",
      Feed: "/feed",
      Kategorier: "/bevakningar/kategorier",
      Partier: "/bevakningar/partier",
      Politiker: "/bevakningar/politiker",
      Event: "/event/:id",
      Profile: "/profile",
      Statistik: "/statistik",
      Politician: "/politician/:id",
      FAQ: "/faq",
      Contact: "/contact",
      OmOss: "/om-oss",
      Användarvillkor: "/allmanna-villkor",
      Integritetspolicy: "/integritetspolicy",
      Redirect: "/redirect",
      Maintenance: "/maintenance",
      Politicianregistry: "/politikerregister",
    },
  },
};

export default function App() {
  return (
    <Provider store={store}>
      <NavigationContainer
        documentTitle={{ formatter: () => `Noterat` }}
        linking={linking}
        ref={navigationRef}
        fallback={<Text>Loading...</Text>}
      >
        <All />
      </NavigationContainer>
    </Provider>
  );
}
