import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import "@expo/match-media";
import { useDispatch } from "react-redux";
import { setMobileExpanded } from "../../../slices/dataslice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PropTypes from "prop-types";

export default function HeaderMenuIcons(props: PropTypes.object) {
  const dispatch = useDispatch();

  return (
    <View style={styles.mainContainer}>
      <Pressable
        onPress={() => {
          dispatch(setMobileExpanded("links"));
        }}
      >
        <FontAwesomeIcon
          style={{ fontSize: 32, lineHeight: 32 }}
          width={32}
          height={32}
          icon={solid("bars")}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
});
