import React, { useState } from "react";
import { View, Text, Pressable, StyleSheet, Modal } from "react-native";
import commonStyles from "../../commonstyles";
import { useMediaQuery } from "react-responsive";
import ModalLogin from "../../modallogin";
import { Hoverable } from "react-native-web-hooks";

const CreateAccount = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalNewAccount, setModalNewAccount] = useState(true);

  const showModal = (newAccount) => {
    setModalNewAccount(newAccount);
    setModalVisible(true);
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <View
      style={[
        styles.container,
        isMobile && { flexDirection: "column", marginBottom: 32 },
      ]}
    >
      <Hoverable>
        {(isHovered) => (
          <Pressable
            onPress={() => showModal(true)}
            style={[styles.submitButton, isHovered ? styles.hovered : null]}
          >
            <Text
              style={[
                {
                  textAlign: "center",
                  color: "#202020",
                },
                commonStyles.headlineFont,
                isHovered ? styles.hovered : null,
              ]}
            >
              Skapa ett konto
            </Text>
          </Pressable>
        )}
      </Hoverable>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <ModalLogin
          newAccount={modalNewAccount}
          close={() => setModalVisible(false)}
        />
      </Modal>
      <Hoverable>
        {(isHovered) => (
          <Text
            onPress={() => showModal(false)}
            style={{
              fontSize: 15,
              fontWeight: "500",
              padding: 10,
            }}
          >
            {" "}
            eller{" "}
            <Text style={[styles.textLink, isHovered ? styles.link : null]}>
              Logga in
            </Text>
          </Text>
        )}
      </Hoverable>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 76,
  },
  modalLogin: {
    backgroundColor: "#F9F8F7",
    margin: 20,
    padding: 16,
  },
  submitButton: {
    borderRadius: 5,
    padding: 10,
    width: 174,
    height: 43,
    borderColor: "#2D2D2D",
    borderWidth: 1,
  },
  textLink: {
    textDecorationLine: "underline",
  },
  link: {
    textDecorationLine: "none",
  },
  hovered: {
    backgroundColor: "#2D2D2D",
    color: "white",
  },
});
export default CreateAccount;
