import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import commonStyles from "../../commonstyles";
import Button from "../button";
import FeedOrganFiltering from "./feedorganfiltering";
import PoliticianSearchBox from "./politiciansearchbox";
import Type from "./type";

const FilterTypePickerMobile = () => {
    const [filterTypeIsActive, setFilterTypeIsActive] = useState(true);
    const [filterÄmneIsActive, setFilterÄmneIsActive] = useState(false);
    const [filterPolitikerIsActive, setFilterPolitikerIsActive] = useState(false);

    const handleFilters = (value: string) => {
        switch (value) {
            case "typ":
                setFilterTypeIsActive(!filterTypeIsActive);
                setFilterÄmneIsActive(false);
                setFilterPolitikerIsActive(false);
                break
            case "ämne":
                setFilterÄmneIsActive(!filterÄmneIsActive);
                setFilterTypeIsActive(false);
                setFilterPolitikerIsActive(false);
                break
            case "politiker":
                setFilterPolitikerIsActive(!filterPolitikerIsActive);
                setFilterTypeIsActive(false);
                setFilterÄmneIsActive(false);
                break
        }
    };

    return (
        <View style={styles.mainContainer}>
            <View style={styles.buttonsContainer}>
                <Button
                    fixedWidth={"31%"}
                    onThePress={() => handleFilters("typ")}
                    button="Typ"
                    style={[filterTypeIsActive ? commonStyles.purpleBackground : styles.button, commonStyles.bigShadow]}
                />
                <Button
                    fixedWidth={"31%"}
                    onThePress={() => handleFilters("ämne")}
                    button="Ämne"
                    style={[filterÄmneIsActive ? commonStyles.purpleBackground : styles.button, commonStyles.bigShadow]}
                />
                <Button
                    fixedWidth={"31%"}
                    onThePress={() => handleFilters("politiker")}
                    button="Politiker"
                    style={[filterPolitikerIsActive ? commonStyles.purpleBackground : styles.button, commonStyles.bigShadow]}
                />
            </View>
            <View>
                {filterTypeIsActive && (
                    <View style={[styles.filterContainer, commonStyles.borderStyle]}>
                        <Type isDesktop />
                    </View>
                )}
                {filterÄmneIsActive && (
                    <View style={[styles.filterContainer, commonStyles.borderStyle]}>
                        <FeedOrganFiltering />
                    </View>
                )}
                {filterPolitikerIsActive && (
                    <View style={[styles.filterContainer, commonStyles.borderStyle]}>
                        <PoliticianSearchBox />
                    </View>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "#F9F8F7",
        borderRadius: 8,
        marginTop: 16,
        padding: 8,
    },
    buttonsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    button: {
        backgroundColor: '#fff',
        borderWidth: 2,
    },
    filterContainer: {
        backgroundColor: "#fff",
        marginTop: 10,
        padding: 8,
    },
});

export default FilterTypePickerMobile;
