import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Pressable,
  Image,
} from "react-native";
import { Auth } from "aws-amplify";
import commonStyles from "./commonstyles";

export default function ModalRecoverPassword(props) {
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const defineNewPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      setConfirmationStatus("Lösenordet har ändrats med framgång");
    } catch (err) {
      if (err.code === "CodeMismatchException") {
        setConfirmationStatus(
          "Ogiltig verifieringskod har angetts, försök igen"
        );
      } else {
        console.log(err);
        setConfirmationStatus("Något gick fel");
      }
    }
  };

  return (
    <View style={[{ width: 400 }, styles.centeredView]}>
      <View
        style={[
          styles.modalView,
          commonStyles.bigRound,
          commonStyles.bigShadow,
        ]}
      >
        <Pressable style={styles.button} onPress={() => props.close()}>
          <Image
            source={require("../public/images/cross.svg")}
            style={{ width: 8, height: 8 }}
          />
        </Pressable>
        <Text>E-post</Text>
        <TextInput
          style={styles.textInput}
          onChangeText={setUsername}
          value={username}
          keyboardType="email-address"
        />
        <Text>kod</Text>
        <TextInput
          style={styles.textInput}
          onChangeText={setCode}
          value={code}
        />
        <Text>Nytt lösenord</Text>
        <TextInput
          style={styles.textInput}
          onChangeText={setNewPassword}
          value={newPassword}
        />
        <Pressable onPress={defineNewPassword} style={styles.submitButton}>
          <Text style={{ textAlign: "center" }}>Definiera nytt lösenord</Text>
        </Pressable>
        <Text style={styles.confirmationStatus}>{confirmationStatus}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: "#F9F8F7",
    margin: 20,
    padding: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    marginTop: 22,
    marginHorizontal: "auto",
  },
  button: {
    alignSelf: "flex-end",
    width: 24,
    height: 24,
    borderColor: "#202020",
    borderWidth: 2,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    borderRadius: 3,
    borderWidth: 1,
    fontSize: 8,
    padding: 6,
    marginTop: 4,
    marginBottom: 15,
  },
  submitButton: {
    backgroundColor: "#FFFE9A",
    borderRadius: 8,
    marginVertical: 5,
    marginHorizontal: "auto",
    padding: 10,
    width: "100%",
  },
  confirmationStatus: {
    backgroundColor: "#C2BAFF",
    marginTop: 2,
  },
});
