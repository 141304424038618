import React from "react";
import { StyleSheet, View, Text } from "react-native";
import BigPartyBadge from "./bigpartybadge";
import Statistics from "../statistics/statistics";
import commonStyles from "../commonstyles";
import Share from "../share/share";

const names = {
  V: ["Vänsterpartiet", 28],
  S: ["Socialdemokraterna", 100],
  MP: ["Miljöpartiet", 16],
  C: ["Centerpartiet", 31],
  L: ["Liberalerna", 20],
  M: ["Moderaterna", 70],
  KD: ["Kristdemokraterna", 22],
  SD: ["Sverigedemokraterna", 62],
  R: ["Hela riksdagen", 349],
};

export default function PartyBox(props: {
  party: string[];
  parliament: boolean;
}) {
  return (
    <View style={styles.container}>
      <Text style={[styles.segmentTitle, commonStyles.standardFont]}>
        STATISTIK
      </Text>
      <View style={styles.partyInfo}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            position: "relative",
            top: 30,
          }}
        ></View>
        <View style={{ flexDirection: "row" }}>
          <BigPartyBadge party={props.party[0]} />
          <View style={{ marginLeft: 12 }}>
            <Text style={[{ fontSize: 20 }, commonStyles.headlineFontBold]}>
              {names[props.party[0]][0]}
            </Text>
            <Text style={[{ color: "#4a4a4a" }, commonStyles.standardFont]}>
              {names[props.party[0]][1]}{" "}
              {props.parliament ? "ledamöter" : "mandat i riksdagen"}
            </Text>
          </View>
        </View>
      </View>
      <Statistics
        parliament={props.parliament}
        politicianOrParty="party"
        identifier={props.party}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
    width: "100%",
  },
  segmentTitle: {
    color: "#a4a4a4",
    fontSize: 14,
    marginBottom: 16,
  },
  partyInfo: {},
  gradient: {
    width: "80%",
    height: 12,
    marginTop: 16,
    marginBottom: 2,
    opacity: 0.02,
    alignSelf: "center",
    resizeMode: "stretch",
  },
  top20: {
    marginBottom: 15,
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#efefef",
    padding: 8,
    borderRadius: 4,
    height: 32,
    backgroundColor: "white",
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});
