import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { useDispatch } from "react-redux";
import { setFilterSegmentStateType } from "../../../slices/dataslice";
import commonStyles from "../../commonstyles";
import ComponentExpandable from "../../commons/componentExpandable";
import Type from "./type";
import FeedOrganFiltering from "./feedorganfiltering";
import PoliticianSearchBox from "./politiciansearchbox";

function FilterTypePicker() {
  const dispatch = useDispatch();

  return (
    <View style={[styles.mainContainer]}>
      <Text style={[{ color: "#4a4a4a" }, commonStyles.headlineFont]}>
        FILTRERA
      </Text>
      <ComponentExpandable
        onPress={() => dispatch(setFilterSegmentStateType("Typ"))}
        textButton="TYP"
        content={<Type />}
      />
      <ComponentExpandable
        onPress={() => dispatch(setFilterSegmentStateType("Ämne"))}
        textButton="ÄMNE"
        content={<FeedOrganFiltering />}
      />
      <ComponentExpandable
        onPress={() => dispatch(setFilterSegmentStateType("Politiker"))}
        textButton="POLITIKER"
        content={<PoliticianSearchBox />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    backgroundColor: "#F9F8F7",
    borderRadius: 8,
    gap: 12,
    marginTop: 16,
    padding: 8,
  },
});

export default FilterTypePicker;
