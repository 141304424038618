import { Dimensions, StyleSheet } from "react-native";

const commonStyles = StyleSheet.create({
  smallRound: {
    borderRadius: 6,
  },
  bigRound: {
    borderRadius: 12,
  },
  arrow: {
    width: 7,
    height: 12,
  },
  yellowBackground: {
    backgroundColor: "#FFFE9A",
  },
  orangeBackground: {
    backgroundColor: "#E8AF7D",
  },
  purpleBackground: {
    backgroundColor: "#C2BAFF",
  },
  lightBeigeBackground: {
    backgroundColor: "#F9F8F7",
  },
  borderStyle: {
    borderColor: "#e4e4e4b3",
    borderRadius: 8,
    borderWidth: 1,
  },
  buttonIsHovered: {
    backgroundColor: "#2D2D2D",
    color: "#FFFE9A",
  },
  headlineFont: {
    fontFamily: "Poppins_500Medium",
  },
  headlineFontBold: {
    fontFamily: "Poppins_Bold",
  },
  standardFont: {
    fontFamily: "museo_sans500",
    fontWeight: "300",
    fontStyle: "normal",
  },
  standardFontBold: {
    fontFamily: "museo_sans700",
    fontWeight: "300",
    fontStyle: "normal",
  },
  headline: {
    fontFamily: "Poppins_500Medium",
    fontSize: 20,
    lineHeight: 28,
  },
  headlineBold: {
    fontFamily: "Poppins_Bold",
    fontSize: 20,
    lineHeight: 28,
  },
  standardText: {
    fontFamily: "museo_sans700",
    fontStyle: "normal",
    color: "#202020",
  },
  smallShadow: {
    shadowColor: "#E4E0DB",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.65,
    shadowRadius: 2,
    elevation: 2,
  },
  bigShadow: {
    shadowColor: "#E4E0DB",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.65,
    shadowRadius: 4,
    elevation: 5,
  },
  extraBigShadow: {
    shadowColor: "#E4E0DB",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12,
    elevation: 10,
  },
  mainContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "#e8e8e8",
    paddingTop: 12,
  },
  response: {
    marginLeft: 24,
  },
  commentText: {
    marginTop: 12,
    marginBottom: 12,
  },
  shareReactContainer: {
    marginBottom: 12,
    flexDirection: "column",
  },
  contentSec: {
    display: "flex",
    flexDirection: "row",
    height: Dimensions.get("window").height / 2,
    backgroundColor: "#F9F8F7",
    paddingVertical: "16%",
  },
  contentSecWhite: {
    display: "flex",
    flexDirection: "row",
    height: Dimensions.get("window").height / 2,
    backgroundColor: "#FFFFFF",
    paddingVertical: "16%",
  },
  btnText: {
    textShadowColor: "yellow",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    color: "#202020",
    fontFamily: "Poppins_500Medium",
  },
});

export default commonStyles;
