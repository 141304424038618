import { createSlice } from "@reduxjs/toolkit";

const findCommentIndices = (comments, id) => {
  for (let i = 0; i < comments.length; i++) {
    if (comments[i] === undefined) {
      continue;
    }
    if (comments[i].id === id) {
      return ["base", i];
    }
    for (let j = 0; j < comments[i].responses.length; j++) {
      if (
        comments[i].responses[j] !== undefined &&
        comments[i].responses[j].id === id
      ) {
        return ["response", i, j];
      }
    }
  }
  return ["none"];
};

export const feedAndEventSlice = createSlice({
  name: "fae",
  initialState: {
    feedEvents: [],
    eventComments: [],
  },
  reducers: {
    setEvents: (state, action) => {
      state.feedEvents = action.payload;
    },
    appendEvents: (state, action) => {
      const newEvents = state.feedEvents.concat(action.payload);
      state.feedEvents = newEvents;
    },
    setEventComments: (state, action) => {
      state.eventComments = action.payload;
    },
    addComment: (state, action) => {
      if (action.payload.inResponseTo === null) {
        const newComments = [action.payload].concat([...state.eventComments]);
        state.eventComments = newComments;
      } else {
        const newComments = [...state.eventComments];
        const index = newComments.findIndex(
          (comment) => comment.id === action.payload.inResponseTo
        );
        newComments[index].responses = newComments[index].responses.concat(
          action.payload
        );
        state.eventComments = newComments;
      }
      // Also add to feed event comment?
    },
    removeComment: (state, action) => {
      const newComments = [...state.eventComments];
      const indices = findCommentIndices(newComments, action.payload);
      if (indices[0] === "base") {
        delete newComments[indices[1]];
      } else {
        newComments[indices[1]].responses.splice(indices[2], 1);
      }
      state.eventComments = newComments;
      // Remove also in feed?
    },
    reactToComment: (state, action) => {
      updateEventReactions(
        state.eventComments,
        action.payload.commentId,
        action.payload.newUserReaction
      );
      updateFeedReactions(
        state.feedEvents,
        action.payload.commentId,
        action.payload.newUserReaction
      );
      state.eventComments = [...state.eventComments];
      state.feedEvents = [...state.feedEvents];
    },
    like: (state, action) => {
      let feedEvents = [...state.feedEvents];
      feedEvents = feedEvents.map((event) => {
        if (event.id === action.payload.eventId) {
          return { ...event, userEvent: action.payload.userEvent };
        } else {
          return event;
        }
      });
      state.feedEvents = feedEvents;
    },
    delike: (state, action) => {
      let feedEvents = [...state.feedEvents];
      feedEvents = feedEvents.map((event) => {
        if (event.id === action.payload) {
          return { ...event, userEvent: null };
        } else {
          return event;
        }
      });
      state.feedEvents = feedEvents;
    },
  },
});

const updateEventReactions = (eventComments, commentId, newUserReaction) => {
  const eventIndices = findCommentIndices(eventComments, commentId);
  if (eventIndices[0] === "base") {
    eventComments[eventIndices[1]].newUserReaction = newUserReaction;
  } else if (eventIndices[0] === "response") {
    eventComments[eventIndices[1]].responses[eventIndices[2]].newUserReaction =
      newUserReaction;
  }
};

const updateFeedReactions = (feedEvents, commentId, newUserReaction) => {
  const feedIndex = feedEvents.findIndex(
    (event) => event.comments.length > 0 && event.comments[0].id === commentId
  );
  if (feedIndex >= 0) {
    feedEvents[feedIndex].comments[0].newUserReaction = newUserReaction;
  }
};

export const {
  setEvents,
  appendEvents,
  setEventComments,
  addComment,
  removeComment,
  reactToComment,
  like,
  delike,
} = feedAndEventSlice.actions;

export default feedAndEventSlice.reducer;
