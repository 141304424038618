import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import { useMediaQuery } from "react-responsive";
import Input from "./input";
import Comment from "./comment";
import RespondBox from "./respondbox";
import { baseURLForServer } from "../../config";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setEventComments, addComment } from "../../slices/feedandeventslice";
import { useIsFocused } from "@react-navigation/native";
import commonStyles from "../commonstyles";

function Discussion(props: PropTypes.object) {
  const [loadedComments, setLoadedComments] = useState(false);

  const userData = useSelector((state) => state.data.userData);
  const comments = useSelector((state) => state.fae.eventComments);

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const isSmall = useMediaQuery({
    minWidth: 1200,
  });

  function postComment(textContent: string) {
    fetch(baseURLForServer + "/comments", {
      method: "POST",
      body: JSON.stringify({
        eventId: props.eventId,
        inResponseTo: null,
        userId: userData.id,
        textContent: textContent,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("j", json);
        dispatch(
          addComment({
            ...json,
            responses: [],
            likes: 0,
            dislikes: 0,
            userReaction: {},
            user: {
              username: userData.username,
              firstName: userData.firstname,
              lastName: userData.lastname,
              party: userData.party,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (isFocused) {
      fetch(
        baseURLForServer +
          "/comments/?eventId=" +
          props.eventId +
          (userData.id ? "&userId=" + userData.id : ""),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userData.jwt ? userData.jwt : "",
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          dispatch(setEventComments(json));
          setLoadedComments(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoadedComments(false);
    }
  }, [userData, isFocused]);

  return (
    <View style={[styles.mainContainer, !isSmall && { padding: 24 }]}>
      <Text style={[styles.title, commonStyles.headlineBold]}>Diskussion</Text>
      <View style={styles.joinDiscussionSegment}>
        {Object.keys(userData).length !== 0 && (
          <Input
            style={{ marginBottom: 12 }}
            placeholder="Delta i diskussionen..."
            autoFocus={false}
            onCancel={() => {}}
            postComment={(textContent: string) => {
              postComment(textContent);
            }}
          />
        )}
        {Object.keys(userData).length === 0 && (
          <View
            style={[
              styles.logInToJoin,
              commonStyles.smallRound,
              commonStyles.smallShadow,
            ]}
          >
            <Text style={[{ color: "#4a4a4a" }, commonStyles.standardFont]}>
              Logga in för att delta i diskussionen!
            </Text>
          </View>
        )}
      </View>
      {comments.map((comment) => {
        if (comment === undefined) {
          return;
        }
        return (
          <View key={comment.id}>
            <Comment
              key={comment.id}
              {...comment}
              showRespondButton={comment.responses.length === 0}
              showDelete={true}
            />
            {comment.responses.map((response) => (
              <Comment showDelete={true} key={response.id} {...response} />
            ))}
            {comment.responses.length !== 0 &&
              Object.keys(userData).length !== 0 && (
                <RespondBox
                  onCancel={() => {}}
                  inThread={true}
                  inResponseTo={comment.id}
                  eventId={props.eventId}
                />
              )}
          </View>
        );
      })}
      {!loadedComments && (
        <View style={[styles.bottom, isSmall && { marginBottom: 24 }]}>
          <Text style={[{ color: "#4a4a4a" }, commonStyles.standardFont]}>
            Laddar kommentarer...
          </Text>
        </View>
      )}
      {loadedComments && comments.length === 0 && (
        <View style={[styles.bottom, isSmall && { marginBottom: 24 }]}>
          <Text style={[{ color: "#4a4a4a" }, commonStyles.standardFont]}>
            Inga kommentarer hittades!
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
  },
  limitedWidth: {
    width: "100%",
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    marginTop: 24,
    marginBottom: 12,
  },
  joinDiscussionSegment: {
    flexDirection: "row",
  },
  logInToJoin: {
    backgroundColor: "#ffffff",
    borderColor: "#F9F8F7",
    borderWidth: 1,
    padding: 16,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loadMoreButton: {
    marginTop: 12,
    backgroundColor: "#f8f8f8",
    padding: 16,
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 8,
    marginBottom: 36,
  },
  bottom: {
    marginTop: 24,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default Discussion;
