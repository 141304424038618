import React from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { navigationRef } from "../../root_navigation";
import commonStyles from "../commonstyles";
import { useMediaQuery } from "react-responsive";
import Logo from "./logo";
import PoliticianSearchBox from "../header/filtersegment/politiciansearchbox";
import { baseURLForServer } from "../../config";
import {
  resetFilters,
  resetPoliticianFilters,
  updateFilterPresets,
} from "../../slices/dataslice";

type Props = {
  onPress(): void;
  navigation: any;
  Feed: boolean;
};

const MonitoringList = (props: Props) => {
  const subjects = useSelector((state) => state.data.filters.subjects);
  const committees = useSelector((state) => state.data.committees);
  const parties = useSelector((state) => state.data.politicianFilters.parties);
  const politicians = useSelector((state) => state.data.filters.politicianIds);
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();

  const onPress = () => {
    fetch(baseURLForServer + "/users/" + userData.id + "/filter-presets/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
      body: JSON.stringify({ politician: JSON.stringify(politicians) }),
    })
      .then((response) => {
        if (response.status == 201) {
          // Update user data...
          dispatch(
            updateFilterPresets({
              ...(JSON.stringify(subjects) === "[]" && {
                subject: JSON.stringify(
                  committees.map((committee) => committee.id)
                ),
              }),
              ...(JSON.stringify(subjects) !== "[]" && {
                subject: JSON.stringify(subjects),
              }),
              party: JSON.stringify(parties),
              politician: JSON.stringify(politicians),
            })
          );
          // Reset filtering states...
          dispatch(resetFilters());
          dispatch(resetPoliticianFilters());
          return props.navigation.navigate("Feed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View style={styles.logoContainer}>
      <Logo feed={true} navigation={navigationRef} />
      <Image
        source={require("../../public/images/gradient_black.png")}
        style={[styles.gradient, { width: "60%" }]}
      />
      <View style={styles.categoriesContainer}>
        <Text
          style={[
            commonStyles.headlineBold,
            {
              marginBottom: 24,
              marginTop: 48,
              textAlign: "center",
              color: "#202020",
            },
          ]}
        >
          Vilka politiker är du intresserad av att bevaka?
        </Text>
        <View
          style={[
            { backgroundColor: "white", padding: 16 },
            commonStyles.bigShadow,
            commonStyles.bigRound,
            isDesktop ? { width: 700 } : { width: "100%" },
          ]}
        >
          <PoliticianSearchBox />
        </View>
        <View
          style={[
            styles.buttonWrapper,
            commonStyles.bigRound,
            commonStyles.smallShadow,
          ]}
        >
          <Pressable
            onPress={() => {
              // Reset filtering states...
              dispatch(resetFilters());
              dispatch(resetPoliticianFilters());
              props.navigation.navigate("Feed");
            }}
          >
            <View
              style={[
                styles.menuButton,
                commonStyles.smallRound,
                styles.wide && styles.wide,
              ]}
            >
              <Text style={[{ color: "#202020" }, commonStyles.headlineFont]}>
                Ställ in bevakningar senare
              </Text>
            </View>
          </Pressable>
          <Pressable onPress={onPress}>
            <View
              style={[
                styles.menuButton,
                commonStyles.smallRound,
                styles.wide && styles.wide,
              ]}
            >
              <Text style={[{ color: "#202020" }, commonStyles.headlineFont]}>
                Slutför
              </Text>
            </View>
          </Pressable>
        </View>
        <Text
          style={[
            { color: "#888683", marginTop: 24, marginBottom: 48 },
            commonStyles.standardFont,
          ]}
        >
          Steg 3 av 3
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    backgroundColor: "#F9F8F7",
    height: "100%",
  },
  categoriesContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: -6, // To compensate for the shadow image...
    flex: 1,
  },
  filterSegmentContent: {
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "3%",
  },
  segmentText: {
    color: "#4a4a4a",
    paddingTop: 16,
  },
  gradient: {
    width: 800,
    height: 12,
    alignSelf: "center",
    opacity: 0.02,
    resizeMode: "stretch",
  },
  buttonWrapper: {
    marginTop: 48,
    flexDirection: "row",
    padding: 12,
    backgroundColor: "white",
    gap: 16,
  },
  menuButton: {
    paddingHorizontal: 24,
    height: 42,
    flexDirection: "row",
    alignItems: "center",
    color: "#202020",
    backgroundColor: "#FFFE9A",
  },
  wide: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
  },
});

export default MonitoringList;
