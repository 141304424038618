import { FlatList, ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { useRoute } from "@react-navigation/native";
import commonStyles from "../commonstyles";
import FilterSearchBox from "../header/filtersegment/filtersearchbox";
import ProfileCard from "./profilecard";
import Card from "./card";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header/header";
import Footer from "../footer/footer";
import { urlEncodedParams } from "../../helpers";
import { baseURLForServer } from "../../config";
import { setEvents, appendEvents } from "../../slices/feedandeventslice";
import FilterTypePicker from "../header/filtersegment/filtertypepicker";
import { useMediaQuery } from "react-responsive";
import FilterTypePickerMobile from "../header/filtersegment/filtertypepickermobile";
import Back from "../navigation/back";
import PropTypes from "prop-types";

const renderItem = ({ item, index }) => {
  if (item.notices - 1 == index) {
    return (
      <View>
        <Card {...item} key={item.id + item.extraData} />
        <Text
          style={[
            { textAlign: "center", marginBottom: 20, color: "#4a4a4a" },
            commonStyles.standardFont,
          ]}
        >
          Händelser du redan sett...
        </Text>
      </View>
    );
  } else {
    return <Card {...item} key={item.id + item.extraData} />;
  }
};

export default function Feed(props: PropTypes.object) {
  const events = useSelector((state) => state.fae.feedEvents);
  const userData = useSelector((state) => state.data.userData);
  const myFlowActive = useSelector((state) => state.data.myFlowActive);
  const filters = useSelector((state) => state.data.filters);
  const showMobileFilters = useSelector(
    (state) => state.data.showMobileFilters
  );
  const organs = useSelector((state) => state.data.committees);

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const route = useRoute();
  localStorage.setItem("currentURL", route.name);

  const arrayToString = (arr) => {
    if (arr !== undefined && arr.length > 0) {
      return arr.join(",");
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!myFlowActive) {
      var params = urlEncodedParams({
        "SKRIFTLIG FRÅGA": filters.filter["SKRIFTLIG FRÅGA"],
        "KU ANMÄLAN": filters.filter["KU ANMÄLAN"],
        MOTION: filters.filter.MOTION,
        IP: filters.filter.IP,
        YTTRANDE: filters.filter.YTTRANDE,
        name: filters.search,
        page: 0,
        size: 20,
        subject: filters.subjects.map((subject) => subject),
        sort: filters.sort,
        politicianId: arrayToString(filters.politicianIds.map((p) => p.id)),
      });
    } else {
      var params = urlEncodedParams({
        "SKRIFTLIG FRÅGA": true,
        "KU ANMÄLAN": true,
        MOTION: true,
        IP: true,
        YTTRANDE: true,
        clearNotices: true,
        page: 0,
        size: 20,
        ...(userData.filterPresets &&
          userData.filterPresets.subject && {
          subject: arrayToString(JSON.parse(userData.filterPresets.subject)),
        }),
        ...(userData.id && { userId: userData.id }),
        sort: filters.sort,
        ...(userData.filterPresets &&
          userData.filterPresets.politician && {
          politicianId: arrayToString(
            JSON.parse(userData.filterPresets.politician).map((p) => p.id)
          ),
        }),
        ...(userData.filterPresets &&
          userData.filterPresets.party && {
          party: arrayToString(JSON.parse(userData.filterPresets.party)),
        }),
        ...((!userData.filterPresets || !userData.filterPresets.subject) && {
          subject: organs.map((organ) => organ.id),
        }),
      });
    }
    fetch(baseURLForServer + "/events?" + params, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt ? userData.jwt : "",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        dispatch(setEvents(json.items));
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userData, filters, myFlowActive, organs]);

  const reachingEnd = () => {
    if (!myFlowActive) {
      var params = urlEncodedParams({
        "SKRIFTLIG FRÅGA": filters.filter["SKRIFTLIG FRÅGA"],
        "KU ANMÄLAN": filters.filter["KU ANMÄLAN"],
        MOTION: filters.filter.MOTION,
        IP: filters.filter.IP,
        YTTRANDE: filters.filter.YTTRANDE,
        name: filters.search,
        page: page,
        size: 20,
        subject: arrayToString(filters.subjects),
        ...(userData.id && { userId: userData.id }),
        sort: filters.sort,
        politicianId: arrayToString(filters.politicianIds.map((p) => p.id)),
      });
    } else {
      var params = urlEncodedParams({
        "SKRIFTLIG FRÅGA": true,
        "KU ANMÄLAN": true,
        MOTION: true,
        IP: true,
        YTTRANDE: true,
        clearNotices: true,
        page: page,
        size: 20,
        ...(userData.filterPresets &&
          userData.filterPresets.subject && {
          subject: arrayToString(JSON.parse(userData.filterPresets.subject)),
        }),
        ...(userData.id && { userId: userData.id }),
        sort: filters.sort,
        ...(userData.filterPresets &&
          userData.filterPresets.politician && {
          politicianId: arrayToString(
            JSON.parse(userData.filterPresets.politician).map((p) => p.id)
          ),
        }),
        ...(userData.filterPresets &&
          userData.filterPresets.party && {
          party: arrayToString(JSON.parse(userData.filterPresets.party)),
        }),
      });
    }
    fetch(baseURLForServer + "/events?" + params, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt ? userData.jwt : "",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        dispatch(appendEvents(json.items));
        setPage(page + 1);
      });
  };

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {isDesktop && (
          <Back pageName="Nyhetsflöde" navigation={props.navigation} />
        )}
        <View
          style={[
            styles.mainContainer,
            isDesktop ? { width: "60%" } : { width: "90%" },
          ]}
        >
          <Text style={[commonStyles.headlineFontBold, styles.title]}>
            Nyhetsflöde
          </Text>
          <FilterSearchBox />
          <View
            style={[
              styles.content,
              isDesktop && {
                flexDirection: "row",
                marginTop: 30,
                marginBottom: 40,
              },
            ]}
          >
            <View>
              {isDesktop && <ProfileCard />}
              {isDesktop && <FilterTypePicker />}
              {!isDesktop && showMobileFilters && <FilterTypePickerMobile />}
            </View>
            <View style={[!isDesktop && { marginTop: 16 }]}>
              <FlatList
                style={{ width: "100%", height: "100vh", marginBottom: 20 }}
                data={events.map((e) => {
                  return {
                    ...e,
                    ...(Object.keys(userData).length !== 0 &&
                      myFlowActive && { notices: userData.countNoticesEvent }),
                  };
                })}
                renderItem={renderItem}
                keyExtractor={(_item, index) => String(index)}
                ListEmptyComponent={<View></View>}
                onEndReached={reachingEnd}
                onEndReachedThreshold={0.25}
                showsVerticalScrollIndicator={true}
              ></FlatList>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: "auto",
  },
  title: {
    textAlign: "center",
    fontSize: 32,
    lineHeight: 48,
    marginTop: 35,
  },
  content: {
    justifyContent: "space-between",
  },
});
