import React from "react";
import { StyleSheet, TextInput } from "react-native";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setPoliticianFilterSearch } from "../../slices/dataslice";
import commonStyles from "../commonstyles";

function SearchBox(props: PropTypes.object) {
  const dispatch = useDispatch();

  return (
    <TextInput
      style={[styles.input, commonStyles.standardFont, commonStyles.bigRound]}
      placeholder="Sök politiker..."
      autoFocus={props.autoFocus}
      onFocus={() => {}}
      onChangeText={(text) => dispatch(setPoliticianFilterSearch(text))}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    width: "100%",
    height: 54,
    borderWidth: 2,
    borderColor: "#f2f2f2",
    paddingHorizontal: 16,
    outlineStyle: "none",
  },
});

export default SearchBox;
