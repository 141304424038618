import React, { useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";
import Faq from "react-faq-component";
import data from "./faqData";
import commonStyles from "../../commonstyles";
import { useMediaQuery } from "react-responsive";
import { useRoute } from "@react-navigation/native";

export const FaqContent = () => {
  const route = useRoute();

  const isTabletOrMobileDevice = useMediaQuery({
    maxWidth: 1200,
  });

  useEffect(() => localStorage.setItem("currentURL", route.name), []);
  return (
    <View style={{ paddingHorizontal: "15%" }}>
      <View style={{ marginVertical: 48, alignSelf: "center" }}>
        <Text
          style={[
            styles.faqTitle,
            commonStyles.headlineFontBold,
            isTabletOrMobileDevice && { fontSize: 24 },
            !isTabletOrMobileDevice && {
              position: "relative",
              top: -6,
              left: -6,
            },
          ]}
        >
          Frågor eller förslag?
        </Text>
        <Text
          style={[
            styles.homeText,
            commonStyles.btnText,
            isTabletOrMobileDevice && { fontSize: 24 },
            !isTabletOrMobileDevice && {
              position: "relative",
              top: -6,
              left: -6,
            },
          ]}
        >
          Vanliga frågor
        </Text>
      </View>
      <View style={{ marginVertical: "5%" }}>
        <Faq
          data={data}
          styles={{
            bgColor: "white",
            titleTextColor: "#202020",
            rowTitleColor: "#202020",
            rowTitleTextSize: "large",
            rowContentColor: "#202020",
            rowContentTextSize: "16px",
            rowContentMarginTop: "20px",
            rowContentPaddingBottom: "20px",
            rowContentMarginBottom: "20px",
              rowContentPaddingLeft: "30px",
              rowContentPaddingRight: "50px",
            arrowColor: "#202020",
          }}
          config={{
            animate: true,
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  homeText: {
    textAlign: "center",
    fontSize: 24,
    color: "#202020",
    fontWeight: "600",
  },
  faqTitle: {
    textAlign: "center",
    fontSize: 42,
    color: "#202020",
    marginVertical: "5%",
    fontWeight: "600",
  },
});
