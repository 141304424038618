import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import commonStyles from "../commonstyles";

export default function CheckButton(props: PropTypes.object) {

  return (
    <Pressable onPress={props.onPress}>
      <View style={styles.mainContainer}>
        {props.checked ? (
          <FontAwesomeIcon
            style={{
              marginRight: 12,
              fontSize: 22,
              lineHeight: 22,
              color: "#202020",
            }}
            width={22}
            height={22}
            icon={regular("square-check")}
          />
        ) : (
          <FontAwesomeIcon
            style={{
              marginRight: 12,
              fontSize: 22,
              lineHeight: 22,
              color: "#202020",
            }}
            width={22}
            height={22}
            icon={regular("square")}
          />
        )}
        <Text
          style={[
            commonStyles.standardFont,
            { color: "#202020", marginRight: 6 },
          ]}
        >
          {props.text}
        </Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
  },
});
