import React from "react";
import { View, StyleSheet, Text } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "./commonstyles";

export default function ProfilePicture(props: PropTypes.object) {
  return (
    <View
      style={[
        styles.profilePictureContainer,
        props.size && {
          width: props.size,
          height: props.size,
          borderRadius: props.size / 2,
        },
      ]}
    >
      {props.firstName && props.lastName ? (
        <View style={styles.profilePicture}>
          <Text
            style={[
              styles.initial,
              props.size && { fontSize: 14 },
              commonStyles.headlineFont,
            ]}
          >
            {props.firstName.substring(0, 1) || "?"}
          </Text>
          <Text
            style={[
              styles.initial,
              props.size && { fontSize: 14 },
              commonStyles.headlineFont,
            ]}
          >
            {props.lastName.substring(0, 1) || "?"}
          </Text>
        </View>
      ) : (
        <View style={styles.profilePicture}>
          <Text
            style={[
              styles.initial,
              props.size && { fontSize: 14 },
              commonStyles.headlineFont,
            ]}
          >
            ?
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  profilePictureContainer: {
    width: 80,
    height: 80,
    borderRadius: 50,
    backgroundColor: "#E4E0DB",
    justifyContent: "center",
  },
  profilePicture: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  initial: {
    fontSize: 32,
    color: "#d6d2ce",
  },
});
