import React from "react";
import { View, Text, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "./commonstyles";

function PartyBadge(props: PropTypes.object) {
  return (
    <View>
      {props.party !== "N" && (
        <View
          style={[
            styles.mainContainer,
            styles[props.party],
            props.noMargin && { marginLeft: 0 },
            props.border && {
              borderWidth: 0,
              borderRadius: 4,
              shadowColor: "#E4E0DB",
              shadowRadius: 0,
              shadowOffset: {
                width: 3,
                height: 3,
              },
            },
          ]}
        >
          <Text style={[commonStyles.headlineFont, styles[props.party]]}>
            {props.party}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginLeft: 8,
    paddingHorizontal: 8,
    height: 24,
    borderRadius: 6,
    justifyContent: "center",
  },
  V: {
    backgroundColor: "#B00000",
    color: "#fff",
  },
  S: {
    backgroundColor: "#ed1b34",
    color: "#000000",
  },
  MP: {
    backgroundColor: "#83CF39",
    color: "#000000",
  },
  C: {
    backgroundColor: "#009933",
    color: "#000000",
  },
  L: {
    backgroundColor: "#6BB7EC",
    color: "#000000",
  },
  M: {
    backgroundColor: "#1B49DD",
    color: "#fff",
  },
  KD: {
    backgroundColor: "#231977",
    color: "#fff",
  },
  SD: {
    backgroundColor: "#DDDD00",
    color: "#000000",
  },
  N: {
    backgroundColor: "#e8e8e8",
  },
});

export default PartyBadge;
