import React, { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  Text,
  Pressable,
  Image,
} from "react-native";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import RelatedBox from "./relatedbox";
import Discussion from "./discussion";
import ExpandableText from "./expandabletext";
import WrittenAnswerBox from "./writtenanswerbox";
import Share from "../share/share";
import { cleanHeadlineAndExtra } from "../../helpers";
import { useMediaQuery } from "react-responsive";
import { baseURLForServer } from "../../config";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import { commentFeaturToggle } from "../../featureToggles";
import FollowButton from "../feed/followbutton";
import Header from "../header/header";
import { useRoute } from "@react-navigation/native";

export default function Event(props: PropTypes.object) {
  const [info, setInfo] = useState(false);
  const [pdfPage, setPdfPage] = useState([1, null]);
  const [pdfWidth, setPdfWidth] = useState(0);
  const [pdfReady, setPdfReady] = useState(false);

  const route = useRoute();

  const isMobile = useMediaQuery({
    minWidth: 1200,
  });
  const isSmall = useMediaQuery({
    minWidth: 600,
  });

  useEffect(() => {
    fetch(baseURLForServer + "/events/" + props.route.params.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setInfo({
          ...json,
          headline: cleanHeadlineAndExtra(json.headline, json.type),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  const politicianObjectToFollowButton = (p) => {
    return {
      id: p.id,
      firstName: p.sortName.split(",")[1],
      familyName: p.sortName.split(",")[0],
      party: p.party,
    };
  };

  return (
    <>
      <Header />
      <ScrollView style={{ paddingTop: 50, marginTop: 70 }}>
        <View style={styles.container}>
          <View
            style={[!isMobile && { width: 600 }, !isSmall && { width: "100%" }]}
          >
            <View
              style={[
                styles.eventInfoContainer,
                !isMobile ? styles.limitedWidth : null,
              ]}
            >
              <View>
                <View style={{ alignSelf: "flex-end", position: "absolute" }}>
                  <Share />
                </View>
                <Text style={[styles.grey, commonStyles.standardFont]}>
                  {info && (info.type === "IP" ? "INTERPELLATION" : info.type)}
                </Text>
                <Text style={[styles.title, commonStyles.headlineBold]}>
                  {info && info.headline[0]}
                </Text>

                {info && info.headline[1] !== "" && (
                  <Text
                    style={[styles.extraTitleText, commonStyles.standardFont]}
                  >
                    {info.headline[1]}
                  </Text>
                )}
              </View>
              <View
                style={[styles.columns, !isMobile ? styles.flexColumn : null]}
              >
                <View
                  style={[
                    isMobile && { flex: 0.8, marginRight: 24 },
                    !isMobile ? { marginBottom: 16 } : null,
                  ]}
                >
                  <Text style={[styles.grey, commonStyles.standardFont]}>
                    {info && info.datePosted}
                  </Text>
                  {info &&
                    info.type === "SKRIFTLIG FRÅGA" &&
                    info.politicians.length !== 0 && (
                      <View>
                        <View
                          style={{
                            marginTop: 16,
                            marginBottom: 4,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 8,
                          }}
                        >
                          <Text style={[commonStyles.standardFont]}>
                            {"av " +
                              info.politicians
                                .find((p) => p.role === "undertecknare")
                                .sortName.split(",")[1] +
                              " " +
                              info.politicians
                                .find((p) => p.role === "undertecknare")
                                .sortName.split(",")[0] +
                              " (" +
                              info.politicians.find(
                                (p) => p.role === "undertecknare"
                              ).party +
                              ")"}
                          </Text>
                          <FollowButton
                            politician={politicianObjectToFollowButton(
                              info.politicians.find(
                                (p) => p.role === "undertecknare"
                              )
                            )}
                          />
                        </View>
                        <View
                          style={{
                            height: 1,
                            backgroundColor: "#E4E0DB",
                            opacity: 0.5,
                          }}
                        />
                        <View
                          style={{
                            marginTop: 4,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 8,
                          }}
                        >
                          <Text style={commonStyles.standardFont}>
                            {"till " +
                              info.politicians
                                .find((p) => p.role === "stalldtill")
                                .sortName.split(",")[1] +
                              " " +
                              info.politicians
                                .find((p) => p.role === "stalldtill")
                                .sortName.split(",")[0] +
                              " (" +
                              info.politicians.find(
                                (p) => p.role === "stalldtill"
                              ).party +
                              ")"}
                          </Text>
                          <FollowButton
                            politician={politicianObjectToFollowButton(
                              info.politicians.find(
                                (p) => p.role === "stalldtill"
                              )
                            )}
                          />
                        </View>
                      </View>
                    )}
                  {info &&
                    (info.type === "SKRIFTLIG FRÅGA" ||
                      info.type === "KU ANMÄLAN") &&
                    !isMobile && (
                      <View style={{ marginTop: 16 }}>
                        <ExpandableText>
                          {info &&
                            info.summaryText
                              .split("LINEBREAK")
                              .map((segment, index) => (
                                <Text
                                  style={[
                                    styles.segment,
                                    commonStyles.standardFont,
                                  ]}
                                  key={index}
                                >
                                  {segment}
                                </Text>
                              ))}
                        </ExpandableText>
                        {info.type === "SKRIFTLIG FRÅGA" && !isMobile && (
                          <WrittenAnswerBox {...info} />
                        )}
                      </View>
                    )}
                  {(info.type !== "MOTION" ||
                    info.type !== "IP" ||
                    info.type !== "YTTRANDE" ||
                    isMobile) && (
                      <RelatedBox
                        isMobile={!isMobile}
                        navigation={props.navigation}
                        events={info.relatedEvents}
                      />
                    )}
                </View>
                <View style={isMobile && { flex: 1 }}>
                  {(info.type === "SKRIFTLIG FRÅGA" ||
                    info.type === "KU ANMÄLAN") &&
                    isMobile && (
                      <ExpandableText>
                        {info &&
                          info.summaryText
                            .split("LINEBREAK")
                            .map((segment, index) => (
                              <Text
                                style={[
                                  styles.segment,
                                  commonStyles.standardFont,
                                ]}
                                key={index}
                              >
                                {segment}
                              </Text>
                            ))}
                      </ExpandableText>
                    )}
                  {info.type === "SKRIFTLIG FRÅGA" && isMobile && (
                    <WrittenAnswerBox {...info} />
                  )}
                  {(info.type === "MOTION" ||
                    info.type === "YTTRANDE" ||
                    info.type === "IP") && (
                      <View>
                        <View
                          style={[
                            styles.pdfController,
                            commonStyles.bigShadow,
                            commonStyles.bigRound,
                          ]}
                        >
                          <Pressable
                            onPress={() => {
                              if (pdfPage[0] !== 1) {
                                setPdfPage([pdfPage[0] - 1, pdfPage[1]]);
                              }
                            }}
                            style={[
                              styles.arrowContainer,
                              commonStyles.yellowBackground,
                            ]}
                          >
                            <Image
                              source={require("../../public/images/soft_arrow_black.svg")}
                              style={[
                                styles.arrow,
                                { transform: [{ rotateZ: "180deg" }] },
                              ]}
                            />
                          </Pressable>
                          <View style={styles.pageNumber}>
                            <Text
                              style={[
                                { color: "#202020" },
                                commonStyles.standardFont,
                              ]}
                            >
                              {pdfReady && `${pdfPage[0]} / ${pdfPage[1]}`}
                            </Text>
                          </View>
                          <Pressable
                            onPress={() => {
                              if (pdfPage[0] !== pdfPage[1]) {
                                setPdfPage([pdfPage[0] + 1, pdfPage[1]]);
                              }
                            }}
                            style={[
                              styles.arrowContainer,
                              commonStyles.yellowBackground,
                            ]}
                          >
                            <Image
                              source={require("../../public/images/soft_arrow_black.svg")}
                              style={styles.arrow}
                            />
                          </Pressable>
                        </View>
                        <View
                          style={pdfReady && styles.pdfContainer}
                          onLayout={(event) => {
                            setPdfWidth(event.nativeEvent.layout.width);
                          }}
                        >
                          <Document
                            file={
                              baseURLForServer +
                              "/events/" +
                              info.id +
                              "/download/?pdfUrl=" +
                              info.pdfUrl
                            }
                            onLoadSuccess={({ numPages }) => {
                              setPdfPage([pdfPage[0], numPages]);
                              setPdfReady(true);
                            }}
                            loading={
                              <View style={{ width: "100%" }}>
                                <Text style={styles.pdfInfoStyle}>Laddar...</Text>
                              </View>
                            }
                            error={
                              <View style={{ width: "100%" }}>
                                <Text
                                  style={[
                                    styles.pdfInfoStyle,
                                    commonStyles.standardFont,
                                  ]}
                                >
                                  Fel när PDF skulle laddas...
                                </Text>
                              </View>
                            }
                          >
                            <Page pageNumber={pdfPage[0]} width={pdfWidth} />
                          </Document>
                        </View>
                      </View>
                    )}
                  {(info.type === "MOTION" ||
                    info.type === "YTTRANDE" ||
                    info.type === "IP") &&
                    !isMobile && (
                      <RelatedBox
                        isMobile={!isMobile}
                        navigation={props.navigation}
                        events={info.relatedEvents}
                      />
                    )}
                </View>
              </View>
            </View>
            {commentFeaturToggle && (
              <Discussion eventId={props.route.params.id} />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    paddingBottom: 24,
  },
  eventInfoContainer: {
    width: 952,
  },
  limitedWidth: {
    width: "100%",
    paddingHorizontal: 24,
  },
  columns: {
    flexDirection: "row",
    marginTop: 24,
  },
  flexColumn: {
    flexDirection: "column",
  },
  segment: {
    fontSize: 15,
    marginBottom: 12,
  },
  grey: {
    color: "#4a4a4a",
  },
  title: {
    fontSize: 24,
    marginTop: 16,
  },
  extraTitleText: { marginTop: 12, color: "#4a4a4a" },
  pdfContainer: {
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 5,
  },
  pdfController: {
    marginBottom: 12,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
  },
  arrowContainer: {
    height: 32,
    backgroundColor: "rgba(229, 237, 255, 0.25)",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
  },
  pageNumber: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  pdfInfoStyle: {
    marginTop: 12,
    alignSelf: "center",
    color: "#4a4a4a",
  },
});
