import React from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import CountdownTimer from "../home/countDown/countdownTimer";
import commonStyles from "../commonstyles";
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

export const Home = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxWidth: 1200,
  });

  return (
    <View style={styles.homeContainer}>
      <View style={{ alignItems: "center" }}>
        <View
          style={[
            !isTabletOrMobileDevice && commonStyles.purpleBackground,
            !isTabletOrMobileDevice && { height: 40, justifyContent: "center" },
            { marginBottom: 48 },
          ]}
        >
          <Text
            style={[
              styles.homeText,
              commonStyles.headlineFontBold,
              isTabletOrMobileDevice && { fontSize: 32 },
              !isTabletOrMobileDevice && {
                position: "relative",
                top: -6,
                left: -6,
              },
            ]}
          >
            Det är dags att synliggöra den politiska processen
          </Text>
        </View>
        <CountdownTimer />
        <View style={styles.logoContainer}>
          <Image
            source={require("../../public/images/n.png")}
            style={styles.logoImage}
          />
          <Text style={[styles.logoText, commonStyles.headlineFont]}>
            noterat
          </Text>
        </View>
        <View
          style={[
            !isTabletOrMobileDevice && commonStyles.yellowBackground,
            !isTabletOrMobileDevice && { height: 40, justifyContent: "center" },
            { marginTop: 32 },
          ]}
        >
          <Text
            style={[
              styles.homeTextBottom,
              commonStyles.headlineFontBold,
              !isTabletOrMobileDevice && {
                position: "relative",
                top: -6,
                left: -6,
              },
              isTabletOrMobileDevice && { fontSize: 32 },
            ]}
          >
            Utan värdering.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  homeContainer: {
    width: "100%",
    height: "100%",
    padding: 24,
    backgroundColor: "#F9F8F7",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    marginTop: 48,
    alignItems: "center",
  },
  logoImage: {
    width: 54,
    height: 58,
    alignSelf: "center",
  },
  logoText: {
    color: "#202020",
    fontSize: 24,
    marginTop: 2,
  },
  homeText: {
    textAlign: "center",
    fontSize: 42,
    color: "#202020",
  },
  homeTextBottom: {
    textAlign: "center",
    color: "#202020",
    fontSize: 42,
  },
});
