import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";
import commonStyles from "../commonstyles";
import PartyChooser from "./../politicianregistry/partychooser";
import { navigationRef } from "../../root_navigation";
import Logo from "./logo";
import { baseURLForServer } from "../../config";
import { resetFilters, resetPoliticianFilters } from "../../slices/dataslice";

type Props = {
  onPress(): void;
  navigation: any;
  MonitoringList: boolean;
  Feed: boolean;
};

const Parties = (props: Props) => {
  const partiesData = useSelector(
    (state) => state.data.politicianFilters.parties
  );
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();

  const nextPress = () => {
    fetch(baseURLForServer + "/users/" + userData.id + "/filter-presets/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
      body: JSON.stringify({ party: JSON.stringify(partiesData) }),
    })
      .then((response) => {
        if (response.status == 201) {
          return props.navigation.navigate("Politiker");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <View style={styles.logoContainer}>
      <Logo feed={true} navigation={navigationRef} />
      <Image
        source={require("../../public/images/gradient_black.png")}
        style={[styles.gradient, { width: "60%" }]}
      />
      <View style={styles.categoriesContainer}>
        <Text
          style={[
            commonStyles.headlineBold,
            {
              marginBottom: 24,
              marginTop: 48,
              textAlign: "center",
              color: "#202020",
            },
          ]}
        >
          Vilka partier är du intresserad av att bevaka?
        </Text>
        <PartyChooser />
        <View
          style={[
            styles.buttonWrapper,
            commonStyles.bigRound,
            commonStyles.smallShadow,
          ]}
        >
          <Pressable
            onPress={() => {
              // Reset filtering states...
              dispatch(resetFilters());
              dispatch(resetPoliticianFilters());
              props.navigation.navigate("Feed");
            }}
          >
            <View
              style={[
                styles.menuButton,
                commonStyles.smallRound,
                styles.wide && styles.wide,
              ]}
            >
              <Text style={[{ color: "#202020" }, commonStyles.headlineFont]}>
                Ställ in bevakningar senare
              </Text>
            </View>
          </Pressable>
          <Pressable onPress={nextPress}>
            <View
              style={[
                styles.menuButton,
                commonStyles.smallRound,
                styles.wide && styles.wide,
              ]}
            >
              <Text style={[{ color: "#202020" }, commonStyles.headlineFont]}>
                Nästa steg
              </Text>
            </View>
          </Pressable>
        </View>
        <Text
          style={[
            { color: "#888683", marginTop: 24, marginBottom: 48 },
            commonStyles.standardFont,
          ]}
        >
          Steg 2 av 3
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    backgroundColor: "#F9F8F7",
    height: "100%",
  },
  categoriesContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: -6, // To compensate for the shadow image...
    flex: 1,
  },
  filterSegmentContent: {
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "3%",
  },
  segmentText: {
    color: "#4a4a4a",
    paddingTop: 16,
  },
  gradient: {
    width: 800,
    height: 12,
    alignSelf: "center",
    opacity: 0.02,
    resizeMode: "stretch",
  },
  buttonWrapper: {
    marginTop: 48,
    flexDirection: "row",
    padding: 12,
    backgroundColor: "white",
    gap: 16,
  },
  menuButton: {
    paddingHorizontal: 24,
    height: 42,
    flexDirection: "row",
    alignItems: "center",
    color: "#202020",
    backgroundColor: "#FFFE9A",
  },
  wide: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
  },
});

export default Parties;
