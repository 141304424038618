import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Link } from "@react-navigation/native";
import { nameOrAnonymous } from "../../helpers";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function LinkComment(props: PropTypes.object) {
  return (
    <View style={styles.mainContainer}>
      <Text
        style={[
          { color: "#202020", marginBottom: 2 },
          commonStyles.headlineFontBold,
        ]}
      >
        {nameOrAnonymous(props.user.firstName, props.user.lastName)}
      </Text>
      <Text style={[{ color: "#4a4a4a" }, commonStyles.standardFont]}>
        {props.textContent}
      </Text>
      <View style={{ alignSelf: "flex-end" }}>
        <Link
          to={"/event/" + props.Event.id}
          style={[styles.link, commonStyles.standardFont]}
        >
          {props.Event.headline.length > 50
            ? props.Event.headline.substring(0, 50) + "..."
            : props.Event.headline}
        </Link>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 8,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#e8e8e8",
  },
  image: {
    backgroundColor: "#e8e8e8",
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  name: {
    marginLeft: 8,
    fontWeight: "700",
  },
  link: {
    textDecorationLine: "underline",
    color: "#a4a4a4",
    marginTop: 8,
  },
});
