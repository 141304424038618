import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import commonStyles from "../../commonstyles";

const mobContent = () => {
  const navigation = useNavigation();

  return (
    <View style={styles.contentWrapper}>
      <View style={[styles.contentSec]}>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1]}>
            <Image
              source={require("../../../public/images/news_feed_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.textTitle}>Anpassningsbart nyhetsflöde</Text>
          <Text style={styles.textBody}>
            Följ ett nyhetsflöde med offentliga handlingar från Riksdagen som
            uppdateras var tionde minut.{"\n"}
            Skapa ett konto för att spara händelser och skapa individuella
            bevakningar med notiser.{" "}
          </Text>

          <Pressable
            onPress={() => {
              navigation.navigate("Feed");
            }}
            style={[styles.lpButton]}
          >
            <Text
              style={[
                {
                  textShadowColor: "yellow",
                  textShadowOffset: { width: 1, height: 1 },
                  textShadowRadius: 2,
                  color: "#202020",
                },
                commonStyles.headlineFont,
              ]}
            >
              Gå till Nyhetsflödet
            </Text>
          </Pressable>
        </View>
      </View>

      <View style={[styles.contentSecWhite]}>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1]}>
            <Image
              source={require("../../../public/images/statistics_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.textTitle}>Användarvänlig statistik</Text>
          <Text style={styles.textBody}>
            Ta del av sammanställd statistik om Riksdagen som helhet, partierna
            såväl som individuella ledamöter.{"\n"} Because numbers don’t lie!
          </Text>

          <Pressable
            onPress={() => {
              navigation.navigate("Statistik");
            }}
            style={[styles.lpButton]}
          >
            <Text
              style={[
                {
                  textShadowColor: "yellow",
                  textShadowOffset: { width: 1, height: 1 },
                  textShadowRadius: 2,
                  color: "#202020",
                },
                commonStyles.headlineFont,
              ]}
            >
              Gå till Statistik
            </Text>
          </Pressable>
        </View>
      </View>

      <View style={[styles.contentSec]}>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1]}>
            <Image
              source={require("../../../public/images/politician_register_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.textTitle}>Politikerregister</Text>
          <Text style={styles.textBody}>
            Bläddra i politikerregistret och hitta statistik, offentliga
            handlingar och en personlig profil med offentlig information om
            varje ledamot i Riksdagen.
          </Text>

          <Pressable
            onPress={() => {
              navigation.navigate("Statistik");
            }}
            style={[styles.lpButton]}
          >
            <Text
              style={[
                {
                  textShadowColor: "yellow",
                  textShadowOffset: { width: 1, height: 1 },
                  textShadowRadius: 2,
                  color: "#202020",
                },
                commonStyles.headlineFont,
              ]}
            >
              Gå till Politikerregister
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  contentWrapper: {
    justifyContent: "center",
    marginVertical: "7%",
  },
  textWrapper: {
    width: "100%",
    textAlign: "center",
    clear: "both",
    justifyContent: "center",
    alignSelf: "center",
    paddingHorizontal: "6%",
    alignItems: "center",
  },
  contentSec: {
    display: "flex",
    flexDirection: "column",
    marginVertical: "5%",
    backgroundColor: "#F9F8F7",
  },
  contentSecWhite: {
    display: "flex",
    flexDirection: "column",
    marginVertical: "5%",
    backgroundColor: "#FFFFFF",
  },
  imgWrapper: {
    width: "100%",
    alignSelf: "flex-end",
    backgroundColor: "#fffff",
    marginVertical: 20,
  },
  shadowProp: {
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#171717",
    shadowOpacity: 0.8,
    shadowRadius: 6,
  },
  imgWrapper1: {
    height: 190,
  },
  textTitle: { fontWeight: "700", fontSize: 24, lineHeight: 36, padding: 10 },
  textBody: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 28,
    textAlign: "left",
  },
  textLink: { fontWeight: "600", fontSize: 14, lineHeight: 17, padding: "5%" },
  lpButton: {
    borderRadius: 5,
    padding: 10,
    width: 200,
    height: 43,
    borderColor: "#2D2D2D",
    borderWidth: 1,
    justifyContent: "flex-end",
    lineHeight: 24,
    fontWeight: "400",
    marginVertical: 20,
  },
  img: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
    shadowColor: "#E4E0DB",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12,
    elevation: 10,
  },
});

export default mobContent;
