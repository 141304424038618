import React from "react";
import { StyleSheet, Pressable, Text } from "react-native";
import PropTypes from "prop-types";

function HelpButton(props: PropTypes.object) {
  return (
    <Pressable onPress={props.onPress} style={styles.mainContainer}>
      <Text style={[styles.text, { padding: 1 }]}>?</Text>
    </Pressable>
  );
}

function CloseHelpButton(props: PropTypes.object) {
  return (
    <Pressable onPress={props.onPress} style={styles.mainContainer}>
      <Text style={styles.text}>x</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "black",
    borderRadius: 50,
    height: 18,
    marginLeft: 8,
    width: 18,
  },
  text: {
    color: "white",
    fontWeight: "700",
    textAlign: "center",
  },
});

export { HelpButton, CloseHelpButton };
