import React from "react";
import { Image, View, StyleSheet, Text, Pressable } from "react-native";
import "@expo/match-media";
import LoginButton from "../loginbutton";
import commonStyles from "../../commonstyles";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setMobileExpanded } from "../../../slices/dataslice";
import { useNavigation } from "@react-navigation/native";
import { linksHeader } from "./../header";
import { useMediaQuery } from "react-responsive";

export default function MobileLinkButtons(props: {
  csrf: string;
  loggedIn: boolean;
}) {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const isShortMobile = useMediaQuery({
    maxHeight: 720,
  });

  return (
    <View style={styles.mainContainer}>
      <View style={styles.closeButtonContainer}>
        <Pressable
          style={{ justifyContent: "center" }}
          onPress={() => {
            dispatch(setMobileExpanded("links"));
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: 32, lineHeight: 32 }}
            width={32}
            height={32}
            icon={solid("xmark")}
          />
        </Pressable>
      </View>
      <View
        style={[
          styles.linkContainer,
          isShortMobile && styles.linkContainerShort,
        ]}
      >
        <View>
          {linksHeader.map((header, index) => (
            <Pressable
              key={index}
              style={[styles.buttons]}
              onPress={() => {
                dispatch(setMobileExpanded("links"));
                navigation.navigate(header.currentURL, {});
              }}
            >
              <Text style={[styles.textButtons, commonStyles.headlineFont]}>
                {header.textLink}
              </Text>
            </Pressable>
          ))}
          <Pressable onPress={() => dispatch(setMobileExpanded("links"))}>
            <LoginButton
              csrf={props.csrf}
              loggedIn={props.loggedIn}
              wide={true}
            />
          </Pressable>
        </View>
        <View>
          <Pressable
            style={styles.logoImageAndText}
            onPress={() => {
              dispatch(setMobileExpanded("links"));
              navigation.navigate("LandingPage", {});
            }}
          >
            <Image
              source={require("../../../public/images/n.png")}
              style={styles.logoImage}
            />
            <Text style={[styles.logoText, commonStyles.headlineFont]}>
              noterat
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 16,
    width: "100%",
    height: "100vh",
    backgroundColor: "#E4E0DB",
    position: "absolute",
    zIndex: 20,
  },
  closeButtonContainer: {
    height: 85,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "flex-end",
    width: "100%",
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  linkContainer: {
    marginHorizontal: "auto",
    marginTop: "3.5rem",
    width: "65%",
  },
  linkContainerShort: {
    marginTop: 0,
  },
  buttons: {
    borderColor: "#2D2D2D",
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 24,
  },
  textButtons: {
    fontSize: 18,
    lineHeight: 45,
    textAlign: "center",
  },
  logoImageAndText: {
    alignItems: "center",
    opacity: 0.25,
    marginTop: "4rem",
  },
  logoImage: {
    height: 75,
    width: 75,
  },
  logoText: {
    fontSize: 20,
    marginTop: 7,
  },
});
