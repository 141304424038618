import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./slices/dataslice";
import feedAndEventReducer from "./slices/feedandeventslice";

export default configureStore({
  reducer: {
    data: dataReducer,
    fae: feedAndEventReducer,
  },
});
