import React, { useState } from "react";
import { Image, View, Text, TextInput, StyleSheet, Pressable, } from "react-native";
import { Auth } from "aws-amplify";
import commonStyles from "./commonstyles";
import Checkbox from "./event/checkbox";
import ModalConfirmation from "./modalconfirmation";
import { baseURLForClient, baseURLForServer } from "../config";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useRoute } from "@react-navigation/native";

export enum CognitoHostedUIIdentityProvider {
  Cognito = "COGNITO",
  Google = "Google",
  Facebook = "Facebook",
  Amazon = "LoginWithAmazon",
  Apple = "SignInWithApple",
}

const ExternalLink = (props: PropTypes.object) => (
  <Text {...props} accessibilityRole="link" target="_blank" />
);

const checkBoxText = () => {
  return (
    <Text style={{ fontSize: 13 }}>
      Genom att kryssa in i denna box godkänner du våra
      <ExternalLink
        href={baseURLForServer + "/allmanna-villkor"}
        style={styles.link}
      >
        användarvillkor
      </ExternalLink>
      och vår
      <ExternalLink
        href={baseURLForServer + "/integritetspolicy"}
        style={styles.link}
      >
        integritetspolicy.
      </ExternalLink>
    </Text>
  );
};

const ModalLogin = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [newAccount, setNewAccount] = useState(props.newAccount || false);
  const [checked, toggleChecked] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const route = useRoute();

  function googleSocialSignIn(): void {
    localStorage.setItem("currentURL", route.name);
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    props.close();
  }

  const onCreate = async () => {
    try {
      await Auth.signUp(username, password);
      setShowConfirmationModal(true);
    } catch (error) {
      if (error.code === "InvalidPasswordException") {
        setErrorMessage("Lösenordet uppfyller inte kravet");
      } else if (error.code === "UsernameExistsException") {
        try {
          await Auth.resendSignUp(username);
          setShowConfirmationModal(true);
        } catch (err) {
          setErrorMessage(
            "E-posten är inte verifierad och ett fel uppstod när koden skickades in igen"
          );
        }
      } else {
        setErrorMessage("Något gick fel");
      }
    }
  };

  const onLogin = async () => {
    try {
      await Auth.signIn(username, password);
      props.close();
    } catch (error) {
      if (error.code === "UserNotFoundException") {
        setErrorMessage("Användaren existerar inte. Vänligen skapa ett konto");
      } else if (error.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(username);
        setShowConfirmationModal(true);
      } else {
        console.log("error signing in", error);
        setErrorMessage(
          "E-postadressen eller lösenordet är ogiltigt. Var god försök igen"
        );
      }
    }
  };

  const recoverPassword = () => {
    setShowConfirmationModal(true);
    setForgotPassword(true);
  };

  const createNewAccount = () => {
    setNewAccount(true);
    setErrorMessage("");
  };

  const showLogin = () => {
    setNewAccount(false);
    setErrorMessage("");
  };

  return (
    <View style={[styles.centeredView, isMobile && { width: 370 }]}>
      {showConfirmationModal ? (
        <ModalConfirmation
          username={username}
          password={password}
          onLogin={onLogin}
          close={() => props.close()}
          forgotPassword={forgotPassword}
        />
      ) : (
        <View
          style={[
            styles.modalLogin,
            commonStyles.bigRound,
            commonStyles.bigShadow,
          ]}
        >
          <Pressable style={styles.button} onPress={() => props.close()}>
            <Image
              source={require("../public/images/cross.svg")}
              style={{ width: 8, height: 8 }}
            />
          </Pressable>
          <View>
            <Text style={[styles.modalTitle, commonStyles.headlineBold]}>
              {newAccount ? "Skapa ett konto" : "Logga in"}
            </Text>
            <Pressable
              onPress={googleSocialSignIn}
              style={styles.ContainerGoogleButton}
            >
              <View style={styles.googleButton}>
                <Image
                  source={require("../public/images/google.svg")}
                  style={{ width: 16, height: 16 }}
                />
                <Text
                  style={[
                    { margin: "auto", color: "#888683" },
                    commonStyles.standardFont,
                  ]}
                >
                  Fortsätt med Google
                </Text>
              </View>
            </Pressable>
            <Text
              style={[
                { textAlign: "center", color: "#888683", fontSize: 15 },
                commonStyles.standardFont,
              ]}
            >
              eller
            </Text>
            <View style={styles.form}>
              <Text style={[commonStyles.standardFont, styles.smallText]}>
                E-post
              </Text>
              <Pressable>
                <TextInput
                  style={styles.textInput}
                  onChangeText={setUsername}
                  value={username}
                  keyboardType="email-address"
                />
              </Pressable>
              <Text style={[commonStyles.standardFont, styles.smallText]}>
                Lösenord
              </Text>
              <Pressable>
                <TextInput
                  style={styles.textInput}
                  onChangeText={setPassword}
                  value={password}
                  keyboardType="default"
                  secureTextEntry={true}
                />
              </Pressable>
              <Pressable
                style={{
                  alignItems: "flex-end",
                  position: "relative",
                  top: -12,
                }}
                onPress={recoverPassword}
              >
                <Text style={[commonStyles.standardFont, styles.smallText]}>
                  Glömt ditt lösenord?
                </Text>
              </Pressable>
              {newAccount ? (
                <View>
                  <Text style={styles.list}>
                    {"\u2022" + " Minst en stor bokstav"}
                  </Text>
                  <Text style={styles.list}>
                    {"\u2022" + " Minst en siffra"}
                  </Text>
                  <Text style={styles.list}>
                    {"\u2022" + " Minst 8 tecken"}
                  </Text>
                  <View style={styles.checkboxContainer}>
                    <Checkbox text={checkBoxText()} onChange={() => toggleChecked(!checked)} />
                  </View>
                </View>
              ) : null}
              {newAccount ? (
                <Pressable
                  onPress={onCreate}
                  style={styles.submitButton}
                  disabled={
                    !checked || username.length < 5 || password.length < 8
                  }
                >
                  <Text
                    style={[
                      { textAlign: "center", color: "#202020" },
                      commonStyles.headlineFont,
                    ]}
                  >
                    Skapa ett konto
                  </Text>
                </Pressable>
              ) : (
                <Pressable
                  onPress={onLogin}
                  style={styles.submitButton}
                  disabled={username.length < 5 || password.length < 8}
                >
                  <Text
                    style={[
                      { textAlign: "center", color: "#202020" },
                      commonStyles.headlineFont,
                    ]}
                  >
                    Logga in
                  </Text>
                </Pressable>
              )}
              <Text style={styles.errorMessage}>{errorMessage}</Text>
            </View>
            {newAccount ? (
              <View style={{ alignSelf: "center", flexDirection: "row" }}>
                <Text style={[styles.newAccount, commonStyles.standardFont]}>
                  Har du redan ett konto?{" "}
                </Text>
                <Pressable onPress={() => showLogin()}>
                  <Text style={[styles.link, commonStyles.standardFont]}>
                    Logga in
                  </Text>
                </Pressable>
              </View>
            ) : (
              <View style={{ alignSelf: "center", flexDirection: "row" }}>
                <Text style={[styles.newAccount, commonStyles.standardFont]}>
                  Inget konto?{" "}
                </Text>
                <Pressable onPress={() => createNewAccount()}>
                  <Text style={[styles.link, commonStyles.standardFont]}>
                    Skapa konto
                  </Text>
                </Pressable>
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default ModalLogin;

const styles = StyleSheet.create({
  modalLogin: {
    backgroundColor: "#F9F8F7",
    margin: 20,
    padding: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    marginTop: 22,
    marginHorizontal: "auto",
    width: 400,
  },
  button: {
    alignSelf: "flex-end",
    width: 24,
    height: 24,
    borderColor: "#202020",
    borderWidth: 2,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    color: "#202020",
    fontSize: 20,
    textAlign: "center",
  },
  ContainerGoogleButton: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    marginVertical: 20,
    marginHorizontal: "auto",
    padding: 12,
    width: "80%",
  },
  googleButton: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    marginHorizontal: "auto",
    marginVertical: 20,
    width: "80%",
  },
  list: {
    fontSize: 15,
  },
  textInput: {
    borderRadius: 6,
    borderWidth: 2,
    borderColor: "#E4E0DB",
    fontSize: 15,
    padding: 6,
    marginTop: 4,
    marginBottom: 16,
  },
  submitButton: {
    backgroundColor: "#FFFE9A",
    borderRadius: 6,
    marginVertical: 5,
    marginHorizontal: "auto",
    padding: 10,
    width: "100%",
  },
  errorMessage: {
    backgroundColor: "#C2BAFF",
    marginTop: 2,
  },
  newAccount: {
    color: "#202020",
    marginBottom: 12,
  },
  link: {
    color: "#202020",
    textDecorationLine: "underline",
  },
  checkboxContainer: {
    marginVertical: 5,
  },
  smallText: {
    color: "#888683",
  },
});
