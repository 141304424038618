import React, { useEffect } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import PartyBox from "./partybox";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useRoute } from "@react-navigation/native";
import Back from "../navigation/back";

export default function Statisticspage(props: PropTypes.object) {
  const politicianFilters = useSelector(
    (state) => state.data.politicianFilters
  );

  const route = useRoute();

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  const isNotSmall = useMediaQuery({
    minWidth: 600,
  });

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  return (
    <>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {isDesktop && <Back navigation={props.navigation} />}
        <View style={styles.container}>
          <View
            style={[
              !isDesktop && { width: "100%", alignItems: "center" },
              isDesktop && { width: 1000 },
            ]}
          >
            <Text style={[styles.title, commonStyles.headlineBold]}>
              Statistik & politiker
            </Text>
            <Text
              style={[
                {
                  marginBottom: 12,
                  textAlign: "center",
                  paddingHorizontal: 24,
                },
                isDesktop && { width: "70%", alignSelf: "center" },
                !isDesktop && { width: 552 },
                !isNotSmall && { width: "100%" },
                commonStyles.standardFont,
              ]}
            >
              Hitta information om politiker och data över talartid för
              politiker och partier. Observera att viss data relaterad till
              talartid har exkluderats, då tekniska fel som lett till felaktiga
              värden inträffat hos källan (riksdagens öppna data).
            </Text>
            <View
              style={[
                styles.flexBox,
                !isDesktop && { flexDirection: "column", width: 552 },
                !isNotSmall && { width: "100%", paddingHorizontal: 24 },
              ]}
            >
              {politicianFilters.parties.length === 1 ? (
                <PartyBox
                  party={politicianFilters.parties[0]}
                  parliament={false}
                />
              ) : (
                <PartyBox party="R" parliament={true} />
              )}
            </View>
          </View>
          <Footer />
        </View>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    marginBottom: 8,
    textAlign: "center",
  },
  flexBox: {
    flexDirection: "row",
    marginTop: 12,
  },
});
