import React from "react";
import { View, StyleSheet, Text } from "react-native";
import Author from "../author";
import ShareReact from "../sharereact";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

function TopComment(props: PropTypes.object) {
  const isSmall = useMediaQuery({
    maxWidth: 600,
  });

  if (!props.comment) {
    return (
      <View
        style={[
          styles.mainContainer,
          commonStyles.smallShadow,
          styles.noCommentView,
        ]}
      >
        <Text style={[styles.noCommentText, commonStyles.standardFont]}>
          Inga kommentarer ännu!
        </Text>
      </View>
    );
  } else {
    return (
      <View
        style={[
          styles.mainContainer,
          commonStyles.smallShadow,
          isSmall
            ? { flexDirection: "column", alignItems: "flex-start" }
            : null,
        ]}
      >
        <View>
          <Author
            name={
              props.comment.user.firstName && props.comment.user.lastName
                ? props.comment.user.firstName +
                  " " +
                  props.comment.user.lastName
                : null
            }
            party={props.comment.user.party}
          />
          <Text style={[styles.commentText, commonStyles.standardFont]}>
            {props.comment.textContent}
          </Text>
        </View>
        <View
          style={[
            styles.shareReactContainer,
            isSmall ? styles.smallerShareReactContainer : null,
          ]}
        >
          <ShareReact comment={props.comment} showDelete={false} />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 12,
    backgroundColor: "white",
    padding: 12,
    borderRadius: 6,
    borderColor: "#e8e8e8",
  },
  commentText: {
    marginTop: 8,
    color: "#4a4a4a",
  },
  shareReactContainer: {
    alignSelf: "flex-end",
  },
  noCommentView: {
    flexDirection: "column",
  },
  noCommentText: {
    color: "#4a4a4a",
  },
  smallerShareReactContainer: {
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    width: "100%",
    alignItems: "flex-end",
    borderColor: "#e8e8e8",
  },
});

export default TopComment;
