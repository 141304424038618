import React, { useState } from "react"
import Button from "./button"
import { useSelector, useDispatch } from "react-redux"
import { Modal, StyleSheet, View } from "react-native"
import { setExpandedSegment, toggleMyFlow } from "../../slices/dataslice"
import PropTypes from "prop-types"
import ModalLogin from "../modallogin"
import commonStyles from "../commonstyles"
import { Hoverable } from "react-native-web-hooks"

export default function MyFlowButton(props: PropTypes.object) {
  const { userData, myFlowActive } = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)

  const handleMyFlow = () => {
    if (Object.keys(userData).length !== 0) {
      dispatch(toggleMyFlow());
      dispatch(setExpandedSegment("none"));
    } else {
      setModalVisible(true);
    }
  };

  return (
    <Hoverable>
      {isHovered => (
        <View
          style={[
            props.wide && { width: "100%", justifyContent: "center", left: 0 },
            props.withPadding && { ...props.withPadding },
          ]}
        >
          <Button
            fixedWidth={180}
            onThePress={() => handleMyFlow()}
            button={myFlowActive ? "Till nyhetsflödet" : "Bevakningar"}
            wide={props.wide}
            style={[
              commonStyles.yellowBackground,
              styles.myFlowButton,
              isHovered && commonStyles.buttonIsHovered
            ]}
          />
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <ModalLogin close={() => setModalVisible(false)} />
          </Modal>
        </View>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  myFlowButton: {
    height: 50,
    width: 216,
    paddingLeft: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
});
