import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import commonStyles from "../commonstyles";

function Element(props) {
  return (
    <View
      style={[
        styles.element,
        commonStyles.smallRound,
        commonStyles.smallShadow,
      ]}
    >
      <Text style={{ color: "#202020" }}>{props.text}</Text>
    </View>
  );
}

export default function StaticCoverage(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);
  const committees = useSelector((state) => state.data.committees);

  const isMobile = useMediaQuery({
    maxWidth: 1000,
  });

  const partyName = {
    V: "Vänsterpartiet",
    S: "Socialdemokraterna",
    MP: "Miljöpartiet",
    C: "Centerpartiet",
    L: "Liberalerna",
    M: "Moderaterna",
    KD: "Kristdemokraterna",
    SD: "Sverigedemokraterna",
  };

  const committeeText = (element) => {
    const first = committees.filter((co) => co.id === element)[0];
    if (first) {
      return first.fullTitle;
    } else {
      return "";
    }
  };

  return (
    <View>
      {Object.keys(userData).length !== 0 && userData.filterPresets === null && (
        <View
          style={[
            { padding: 16, alignItems: "center", marginBottom: 16 },
            commonStyles.smallShadow,
            commonStyles.smallRound,
          ]}
        >
          <Text style={[commonStyles.standardFont, { color: "#202020" }]}>
            Inga bevakningar! Tryck nedan för att konfigurera
          </Text>
        </View>
      )}
      {Object.keys(userData).length !== 0 && userData.filterPresets != null && (
        <View
          style={[styles.container, isMobile && { flexDirection: "column" }]}
        >
          <View style={[styles.column, !isMobile && { flex: 1 }]}>
            <Text style={[commonStyles.standardFont, { color: "#a4a4a4" }]}>
              ÄMNE
            </Text>
            {userData.filterPresets.subject &&
            JSON.parse(userData.filterPresets.subject) &&
            JSON.parse(userData.filterPresets.subject).length !== 0 ? (
              JSON.parse(userData.filterPresets.subject).map((element, i) => (
                <Element key={i} text={committeeText(element)} />
              ))
            ) : (
              <Text style={[styles.noCoverageText, commonStyles.standardFont]}>
                Inga ämnen bland dina bevakningar!
              </Text>
            )}
          </View>
          <View style={[styles.column, !isMobile && { flex: 1 }]}>
            <Text style={[commonStyles.standardFont, { color: "#a4a4a4" }]}>
              PARTI
            </Text>
            {userData.filterPresets.party &&
            JSON.parse(userData.filterPresets.party) &&
            JSON.parse(userData.filterPresets.party).length !== 0 ? (
              JSON.parse(userData.filterPresets.party).map((element, i) => (
                <Element key={i} text={partyName[element]} />
              ))
            ) : (
              <Text style={[styles.noCoverageText, commonStyles.standardFont]}>
                Inga partier bland dina bevakningar!
              </Text>
            )}
          </View>
          <View style={[styles.column, !isMobile && { flex: 1 }]}>
            <Text style={[commonStyles.standardFont, { color: "#a4a4a4" }]}>
              POLITIKER
            </Text>
            {userData.filterPresets.politician &&
            JSON.parse(userData.filterPresets.politician) &&
            JSON.parse(userData.filterPresets.politician).length !== 0 ? (
              JSON.parse(userData.filterPresets.politician).map(
                (element, i) => (
                  <Element key={i} text={element.name + ", " + element.party} />
                )
              )
            ) : (
              <Text style={[styles.noCoverageText, commonStyles.standardFont]}>
                Inga politiker bland dina bevakningar!
              </Text>
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    gap: 16,
  },
  column: {
    gap: 8,
  },
  element: {
    padding: 16,
  },
  noCoverageText: {
    color: "#a4a4a4",
  },
});
