import React from "react";
import { View, Text, Linking } from "react-native";
import commonStyles from "../commonstyles";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { TouchableOpacity } from "react-native-gesture-handler";

export const Cookie = () => {
  return (
    <View style={{ zIndex: 10 }}>
      <CookieConsent
        enableDeclineButton
        onAccept={() => {
          Cookies.set("firstTimeUser", "true");
        }}
        location="bottom"
        buttonText={
          <TouchableOpacity style={{ marginTop: 5, marginBottom: 5 }}>
            <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
              Acceptera alla
            </Text>
          </TouchableOpacity>
        }
        declineButtonText={
          <TouchableOpacity
            style={{
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Text style={[commonStyles.headlineFont, { color: "#202020" }]}>
              Avböj
            </Text>
          </TouchableOpacity>
        }
        style={{
          minHeight: 65,
          background: "#E4E0DB",
          flexDirection: "row",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "#FFFFFF",
          padding: 12,
          minWidth: 120,
          textAlign: "center",
          borderRadius: 6,
          border: "1 solid",
        }}
        declineButtonStyle={{
          background: "#E4E0DB",
          borderRadius: 6,
          padding: 12,
          minWidth: 120,
          border: "1 solid",
        }}
        expires={100}
      >
        <Text style={[commonStyles.standardFont, { color: "#202020" }]}>
          Vi använder cookies för att förbättra din webbupplevelse och analysera
          vår trafik. Vi lagrar inga analyser anknutna till din personliga
          profil. Genom att klicka på "Acceptera alla" samtycker du till vår
          användning av cookies.{"  "}
          <Text
            style={{ textDecorationLine: "underline" }}
            onPress={() =>
              Linking.openURL("https://api.noterat.io/integritetspolicy")
            }
          >
            Läs vår Integritetspolicy för mer info.
          </Text>
        </Text>
      </CookieConsent>
    </View>
  );
};
