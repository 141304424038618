import React, { useState, useRef } from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";
import RoundedButton from "./roundedbutton";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

function Input(props: PropTypes.object) {
  const [expanded, setExpanded] = useState(false);
  const [textContent, setTextContent] = useState("");

  const childRef = useRef();

  return (
    <View style={styles.mainContainer}>
      <TextInput
        ref={childRef}
        style={[
          styles.input,
          expanded && styles.darkBorder,
          commonStyles.standardFont,
          commonStyles.smallRound,
        ]}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        onFocus={() => setExpanded(true)}
        onChangeText={setTextContent}
        onSubmitEditing={() => {
          setExpanded(false);
          childRef.current.clear();
          props.postComment(textContent);
        }}
      />
      {expanded && (
        <View style={styles.buttonContainer}>
          <View style={{ flex: 1, marginRight: 6 }}>
            <RoundedButton
              onPress={() => {
                setExpanded(false);
                childRef.current.clear();
                props.onCancel();
              }}
              post={false}
            >
              <Text
                style={[
                  styles.buttonText,
                  { color: "#888683" },
                  commonStyles.headlineFont,
                ]}
              >
                Avbryt
              </Text>
            </RoundedButton>
          </View>
          <View style={{ flex: 1, marginLeft: 6 }}>
            <RoundedButton
              onPress={() => {
                setExpanded(false);
                childRef.current.clear();
                props.postComment(textContent);
              }}
              post={true}
            >
              <Text style={[styles.buttonText, commonStyles.headlineFont]}>
                Skicka
              </Text>
            </RoundedButton>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
  },
  input: {
    width: "100%",
    height: 36,
    borderWidth: 2,
    borderColor: "#e8e8e8",
    paddingVertical: 24,
    paddingHorizontal: 16,
    outlineStyle: "none",
  },
  darkBorder: {
    borderColor: "#d0d0d0",
  },
  buttonContainer: {
    marginTop: 12,
    flexDirection: "row",
  },
  buttonText: {
    color: "#202020",
  },
});

export default Input;
