import React from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
} from "react-native";
import PropTypes from "prop-types";
import { contactFormFeatureToggle } from "../../../featureToggles";

import Header from "../../header/header";
import Footer from "../footer";

import { useMediaQuery } from "react-responsive";
import Back from "../../navigation/back";
import ContactForm from "./contactForm";
import Contactinformation from "./contactinformation";

export default function ContactPage(props: PropTypes.object) {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isTablet = useMediaQuery({
    maxWidth: 870,
  });

  return (
    <View style={styles.main}>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        <View style={{ minHeight: "calc(100vh - 85px)" }}>
          {!isMobile && (
            <Back pageName="Kontakt" navigation={props.navigation} />
          )}
          <View
            style={[
              styles.body,
              isMobile && styles.bodyMobile,
              !contactFormFeatureToggle && styles.bodyWOForm,
            ]}
          >
            <Text style={styles.h1}>Kontakt</Text>
            <View
              style={[
                styles.article,
                isMobile && styles.articleMobile,
                !contactFormFeatureToggle && styles.articleWOForm,
              ]}
            >
              {contactFormFeatureToggle && (
                <View
                  style={[styles.formSide, isMobile && styles.formSideMobile]}
                >
                  <ContactForm />
                </View>
              )}

              <View
                style={[
                  styles.contactSide,
                  isMobile && styles.contactSideMobile,
                  !contactFormFeatureToggle && styles.contactSideWOForm,
                ]}
              >
                <Contactinformation />
              </View>
            </View>
          </View>
          <Footer />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    textAlign: "center",
  },
  body: {
    width: "80%",
    maxWidth: 1072,
    alignSelf: "center",
    flex: 1,
    justifyContent: "center",
  },
  bodyMobile: { width: "100%" },
  article: {
    flexDirection: "row",
    shadowColor: "rgba(100, 100, 111, 0.2)",
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowRadius: 29,
    elevation: 5,
    marginTop: "3rem",
    marginBottom: "5rem",
  },
  articleMobile: {
    flexDirection: "column",
    shadowColor: "none",
    marginBottom: 0,
  },
  formSide: { backgroundColor: "#F9F8F7", width: "56%", padding: "3rem" },
  formSideMobile: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    padding: "1rem",
    paddingBottom: "3rem",
  },
  contactSide: {
    width: "44%",
    padding: "2rem",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  contactSideMobile: {
    backgroundColor: "#F9F8F7",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "2rem",
  },
  titleBackground: {
    height: 20,
    justifyContent: "center",
    marginTop: 16,
    alignSelf: "center",
    backgroundColor: "#FFFE9A",
  },
  kontaktaOssTitle: { marginBottom: "3rem" },
  kontaktaOssTitleMobile: { marginBottom: "1rem" },
  kontaktuppgifterTitle: { marginBottom: "0.5rem" },
  kontaktuppgifterP: { width: "80%", marginBottom: "2rem" },
  contactDetailsWrapper: { margin: "auto" },
  contactDetail: { flexDirection: "row", marginBottom: "2rem" },
  contactDetailMobile: { flexDirection: "column" },
  contactDetailText: {
    textAlign: "left",
    marginLeft: "2rem",
    lineHeight: 25,
    fontSize: 14,
    fontWeight: "600",
  },
  contactDetailTextMobile: { textAlign: "center", marginLeft: 0 },
  contactDetailh3: { fontSize: 16, fontWeight: "700" },
  iconView: { justifyContent: "center" },
  iconViewMobile: { alignItems: "center", marginBottom: "1rem" },
  h1: {
    fontFamily: "Poppins_Bold",
    fontSize: 28,
    fontWeight: "700",
    lineHeight: 48,
    marginTop: "2rem",
  },
  h2: {
    fontFamily: "Poppins_500Medium",
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 30,
    position: "relative",
    top: -4,
    left: -4,
  },
  p: {
    fontFamily: "museo_sans500",
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 6,
    marginBottom: 20,
    flexWrap: "wrap",
  },
  pMobile: {
    lineHeight: 14,
    fontSize: 14,
  },
  bodyWOForm: { alignItems: "center" },
  articleWOForm: { width: "90%", marginBottom: 20, marginTop: "1rem" },
  contactSideWOForm: { width: "100%", paddingTop: "2rem" },
});
