import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Linking,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import commonStyles from "../commonstyles";
import { useMediaQuery } from "react-responsive";
import Header from "../header/header";
import Footer from "../footer/footer";
import PropTypes from "prop-types";
import Back from "../navigation/back";
import { useRoute } from "@react-navigation/native";

const OmOss = (props: PropTypes.object) => {
  const route = useRoute();
  localStorage.setItem("currentURL", route.name);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <View style={styles.mainView}>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {!isMobile && (
          <Back pageName="Om Noterat" navigation={props.navigation} />
        )}
        <View style={[styles.bodyView, isMobile && styles.bodyViewMobile]}>
          <Text style={[styles.h1]}>Om Noterat</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Noterat.io är en partipolitiskt obunden ideell förening initierad av
            Change Equity med syftet att synliggöra den politiska processen för
            allmänheten, genom att tillhandahålla och kommunicera offentliga
            handlingar, såväl som statistik kring offentliga aktiviteter. Change
            Equity är en religiöst- och partipolitiskt obunden ideell förening
            med syftet att skapa tvärvetenskapliga möten och främja engagemang i
            projekt med en anknytning till något av FN:s globala mål för hållbar
            utveckling.
          </Text>
          {/*<View
            style={[
              {
                height: "20rem",
                width: "100%",
                backgroundColor: "lightgrey",
                alignSelf: "center",
                marginTop: 12,
              },
              isMobile && { width: "85%", height: "10rem" },
            ]}
          />*/}
          <Text style={[commonStyles.headlineBold, styles.h2]}>
            Varför finns vi?
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vi lever i en hektisk tid där uppmärksamheten är dyrbar. Den
            politiska processen är samtidigt komplex och svår att följa.
            Orienteringen mellan partierna blir än mer komplicerade under
            valrörelsen, då stora mängder energi och resurser riktas åt att
            påverka väljarnas uppfattning - av flertalet aktörer med skilda
            agendor. Förtroendet för den politiska processen är en grundpelare i
            ett demokratiskt samhälle. Vi anser därför att det är viktigt att
            den utvärderas på sakliga grunder i högsta möjliga utsträckning.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Ett av demokratins grundförutsättningar är att all offentlig makt
            utgår från folket. Med det sagt, är det viktigt att samma allmänhet
            som väljer representanter i Riksdagen har en god och djupgående
            insyn i såväl Riksdagens som dess ledamöters utmaningar,
            prioriteringar och händelseutvecklingar. Då den politiska processen
            utgörs av en dynamik som involverar både politiker och allmänheten,
            vill vi öka transparensen kring den politiska processen.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vi vill därför tillhandahålla en neutral, användarvänlig och
            transparent källa för att skapa goda förutsättningar för en nykter
            maktfördelning och ett konstruktivt politiskt klimat. Genom att ge
            underlag till en politisk debatt kring specifika händelser snarare
            än politiska narrativ hoppas vi fördjupa den politiska
            allmänbildningen.
          </Text>
          <Text style={[commonStyles.headlineBold, styles.h2]}>
            Vilka är våra värderingar och hur reflekteras det i plattformen?
          </Text>
          <Text style={[commonStyles.headline, styles.h2]}>Oberoende</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Initiativet och plattformens oberoende har utgjort en grundpelare
            under hela projektets gång. I och med att vårt primära syfte är att
            synliggöra den politiska processen, har vi aktivt undvikit
            samarbeten och tekniska lösningar med en negativ inverkan på
            projektets neutralitet och oberoende. Av denna anledning har vi valt
            att inte förenkla eller sammanfatta offentliga handlingar, utan
            istället publicera dem i originalform och kronologisk ordning. På
            plattformen kan ni även hitta bearbetad statistik. Dessa
            sammanställningar har baserats på vedertagna statistiska metoder och
            syftar endast till att skapa en översikt kring den datan som hålls
            tillgänglig av Riksdagen.
          </Text>
          <Text style={[commonStyles.headline, styles.h2]}>
            Lättillgänglighet
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Mycket av den information vi tillhandahåller finns sedan
            tillgängligt på Riksdagens hemsida. Däremot saknas en möjlighet att
            följa enskilda politiker eller ämnesområden, och många datapunkter
            presenteras på ett sätt som gör den svårsmält för gemene väljare.
            Plattformens utformning syftar till att göra information
            lättillgänglig för alla.
          </Text>
          <Text style={[commonStyles.headline, styles.h2]}>Transparens</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Precis som vi vill främja transparens kring Riksdagens arbete, vill
            vi leda med transparens i vårt arbete med plattformen. Vi lagrar
            inga politiska åsikter anknutna till personuppgifter, och överför
            ingen data till tredjeparter, med undantag för tekniska leverantörer
            i syfte att tillhandahålla tjänsten. All personlig data är krypterad
            och är endast tillgänglig till användaren i dess okrypterade form.
            Frågor och funderingar välkomnas till{" "}
            <TouchableOpacity
              onPress={() => Linking.openURL("mailto:chair@change-equity.com")}
            >
              <Text style={{ textDecorationLine: "underline" }}>
                chair@change-equity.com
              </Text>
            </TouchableOpacity>
            .
          </Text>
          <Text style={[commonStyles.headlineBold, styles.h2]}>
            Vilka ligger bakom Noterat.io?
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Plattformen initierades av Change Equity’s Ordförande, Allan Caman,
            efter ett samtal med Tobias Franzén, VD för The Amazing Society och
            en av initiativtagarna till ”Kavla upp-kampanjen”. Samtalet
            cirkulerade kring svårigheten att följa upp och få en översiktlig
            bild av förtroendevaldas faktiska aktiviteter och resultat, vilket
            vi ansåg var problematiskt då politiker utvärderas på deras
            kampanjbudskap och inte deras faktiska handlingar samt att
            intransparenta processer skapar en grogrund för desinformation och
            feltolkningar.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Teamet bakom Noterat.io består idag av två jurister, tre
            programmerare, en ekonom, ett Advisory board bestående av tre
            personer, samt ett brett nätverk av rådgivare och stödresurser.
            Projektet initierades och har hittills finansierats av den ideella
            föreningen Change Equity, vars syfte är att främja projekt med en
            anknytning till något utav FN:s globala mål för hållbar utveckling.
          </Text>
          <Text style={[commonStyles.headlineBold, styles.h2]}>
            Vill du stötta vår satsning?
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Sedan juni 2021 har teamet på Noterat.io och Change Equity arbetat
            ideellt för att utveckla detta projekt. Men för att vi ska få den
            genomslagskraften vi strävar efter, behöver vi mer kompetens och
            utrymme att satsa mer tid, energi och resurser. Vi söker därför
            ständigt efter engagerade funktionärer samt samarbetspartners och
            finansiärer vars värderingar, målsättningar och aktiviteter
            överensstämmer med våra.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Stämmer du eller en organisation du företräder in på beskrivningen?
            Hör isåfall av dig till{" "}
            <TouchableOpacity
              onPress={() => Linking.openURL("mailto:chair@change-equity.com")}
            >
              <Text style={{ textDecorationLine: "underline" }}>
                chair@change-equity.com
              </Text>
            </TouchableOpacity>
            !
          </Text>
          <Text
            style={[
              styles.p,
              isMobile && styles.pMobile,
              { marginBottom: "7%" },
            ]}
          >
            Vill du skicka en donation för förvaltning & utveckling av
            Noterat.io?
            {"\n"}
            Swisha oss på 1236229454
          </Text>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  bodyView: { width: "80%", maxWidth: "57rem", alignSelf: "center" },
  bodyViewMobile: { width: "90%" },
  h1: {
    fontFamily: "Poppins_Bold",
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 48,
    margin: "2%",
  },
  h2: {
    marginTop: 10,
  },
  p: {
    fontFamily: "museo_sans500",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 24,
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 6,
    marginBottom: 20,
  },
  pMobile: {
    fontSize: 12,
    lineHeight: 14,
  },
});

export default OmOss;
