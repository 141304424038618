import React from "react";
import { View, StyleSheet, Text, Image, Pressable } from "react-native";
import PropTypes from "prop-types";
import { Hoverable } from "react-native-web-hooks";
import commonStyles from "../commonstyles";

export default function EventBox(props: PropTypes.object) {
  return (
    <View>
      <View style={[styles.summaryBox, commonStyles.smallShadow]}>
        <Text style={[styles.summaryTitle, commonStyles.standardFont]}>
          OFFENTLIGA HANDLINGAR
        </Text>
        {props.events !== [] &&
        props.events !== undefined &&
        props.events.length > 0 ? (
          props.events.map((event, index) => (
            <Hoverable key={index}>
              {(isHovered) => (
                <Pressable
                  style={[
                    styles.link,
                    commonStyles.smallShadow,
                    commonStyles.smallRound,
                  ]}
                  onPress={() =>
                    props.navigation.push("Event", { id: event.id })
                  }
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Text
                      style={[
                        { color: "#888683" },
                        isHovered && { color: "#5B5957" },
                        commonStyles.standardFont,
                      ]}
                    >
                      {event.headline}
                    </Text>
                    <Image
                      source={require("../../public/images/soft_arrow_beige.svg")}
                      style={styles.arrow}
                    />
                  </View>
                </Pressable>
              )}
            </Hoverable>
          ))
        ) : (
          <Text
            style={[
              styles.noneFoundText,
              commonStyles.standardFont,
              commonStyles.smallRound,
              commonStyles.smallShadow,
            ]}
          >
            Inga offentliga handlingar hittades!
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  summaryBox: {
    backgroundColor: "#F9F8F7",
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
  },
  summaryTitle: {
    color: "#888683",
    marginBottom: 12,
  },
  link: {
    alignSelf: "center",
    padding: 12,
    borderWidth: 1,
    borderColor: "#E4E0DB",
    width: "100%",
    marginBottom: 8,
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
    marginLeft: 16,
  },
  noneFoundText: {
    color: "#888683",
    alignSelf: "center",
    width: "100%",
    justifyContent: "center",
    padding: 12,
    borderWidth: 1,
    borderColor: "#E4E0DB",
    marginBottom: 8,
  },
});
