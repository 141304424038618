import React from "react";
import { Pressable } from "react-native";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { toggleSubscribes } from "../../slices/dataslice";
import { baseURLForServer } from "../../config";
import CheckButton from "../header/checkbutton";

export default function NoticesCheckbox(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);
  const subscribes = useSelector((state) => state.data.userData.notices);

  const dispatch = useDispatch();

  const onPress = () => {
    fetch(baseURLForServer + "/users/" + userData.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
      body: JSON.stringify({ notices: !subscribes }),
    })
      .then((_response) => {
        dispatch(toggleSubscribes());
      })
      .catch((err) => console.log(err));
  };

  return (
    <Pressable onPress={() => {}}>
      <CheckButton
        onPress={onPress}
        small={false}
        text={subscribes ? "Notiser är på" : "Notiser är av"}
        checked={subscribes}
      />
    </Pressable>
  );
}
