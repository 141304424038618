import React from "react";
import { View, StyleSheet, Text } from "react-native";
import PartyBadge from "./partybadge";
import PropTypes from "prop-types";
import ProfilePicture from "./profilepicture";
import commonStyles from "./commonstyles";

function Author(props: PropTypes.object) {
  return (
    <View style={styles.mainContainer}>
      <ProfilePicture
        firstName={
          props.name !== "Anonym användare" && props.name !== null
            ? props.name
            : ""
        }
        lastName={
          props.name !== "Anonym användare" && props.name !== null
            ? props.name.split(" ")[1]
            : ""
        }
        size={36}
      />
      <Text style={[styles.name, commonStyles.headlineFont]}>
        {props.name !== null ? props.name : "Anonym användare"}
      </Text>
      <PartyBadge party={props.party} />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    backgroundColor: "#e8e8e8",
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  name: {
    marginLeft: 8,
  },
});

export default Author;
