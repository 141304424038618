import React from "react";
import CountDown from "react-native-countdown-component";
import moment from "moment";
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import commonStyles from "../../commonstyles";

const CountdownTimer = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxWidth: 1200,
  });

  const date = moment().utcOffset("+05:30");
  const expirydate = "2022-07-15 17:00:00";
  const diffr: any = moment.duration(moment(expirydate).diff(moment(date)));
  const hours = parseInt(diffr.asHours());
  const minutes = parseInt(diffr.minutes());
  const seconds = parseInt(diffr.seconds());
  const totalDuration = hours * 60 * 60 + minutes * 60 + seconds;

  return (
    <CountDown
      until={totalDuration}
      size={isTabletOrMobileDevice ? 30 : 42}
      digitStyle={[
        { backgroundColor: "white", color: "#202020" },
        commonStyles.bigShadow,
        commonStyles.bigRound,
      ]}
      digitTxtStyle={[
        commonStyles.headlineFontBold,
        { color: "#202020", fontSize: 32 },
      ]}
      timeLabels={{ d: "Dagar", h: "Timmar", m: "Minuter", s: "Sekunder" }}
      timeLabelStyle={[
        commonStyles.standardFont,
        { fontSize: 15, color: "#202020", marginTop: 8 },
        !isTabletOrMobileDevice && { fontSize: 20 },
      ]}
    />
  );
};

export default CountdownTimer;
