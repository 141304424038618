import React, {useEffect, useRef, useState} from "react";
import {View, Text, StyleSheet, Dimensions, Image, Pressable, Animated} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { Cookie } from "../cookie";
import { hotjar } from "react-hotjar";
import Footer from "../../footer/footer";
import CreateAccount from "./createAccount";
import Content from "./content";
import MobContent from "./mobContent";
import { baseURLForClient, baseURLForServer } from "../../../config";
import { Hoverable } from "react-native-web-hooks";
import { ScrollView } from "react-native-gesture-handler";
import Header from "../../header/header";
import { useRoute } from "@react-navigation/native";
import { FaqContent } from "../../footer/faq/faqContent";
import Contactinformation from "../../footer/contact/contactinformation";
const env = process.env.env;

const HOTJAR_ID = env === "prod" ? 3095291 : 3101664;

export function LandingPage() {
  hotjar.initialize(HOTJAR_ID, 6);

  const route = useRoute();

  const ExternalLink = (props) => (
    <Text {...props} accessibilityRole="link" target="_blank" />
  );

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isTablet = useMediaQuery({
    maxWidth: 960,
    minWidth: 601,
  });

  const cookieConsent = getCookieConsentValue();
  if (cookieConsent === "true") {
    const firstTimeUser = Cookies.get("firstTimeUser");
    if (!firstTimeUser) {
      Cookies.set("firstTimeUser", "true");
    } else if (firstTimeUser == "true") {
      Cookies.set("firstTimeUser", "false");
    }
  }

  const scrollViewRef = useRef();

  const scrollToElement = () => {
    scrollViewRef.current.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  return (
    <>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        <View style={styles.mainContainer}>
          <View style={{ height: Dimensions.get("window").height }}>
            <View style={[styles.mainTitle]}>
              {isMobile ? (
                <View>
                  <Text
                    style={[
                      styles.homeText,
                      isMobile && {
                        fontSize: 26,
                        fontWeight: "700",
                        lineHeight: 39,
                      },
                      isTablet && {
                        fontSize: 26,
                        fontWeight: "700",
                        lineHeight: 39,
                      },
                    ]}
                  >
                    Det är dags att synliggöra
                  </Text>
                  <Text
                    style={[
                      styles.homeText,
                      isMobile && {
                        fontSize: 26,
                        fontWeight: "700",
                        lineHeight: 39,
                        width: "100%",
                      },
                      isTablet && {
                        fontSize: 26,
                        fontWeight: "700",
                        lineHeight: 39,
                      },
                    ]}
                  >
                    den politiska processen.
                  </Text>
                </View>
              ) : (
                <Text
                  style={[
                    styles.homeText,
                    isMobile && {
                      fontSize: 26,
                      fontWeight: "700",
                      lineHeight: 39,
                      width: "100%",
                    },
                    isTablet && {
                      fontSize: 26,
                      fontWeight: "700",
                      lineHeight: 39,
                    },
                  ]}
                >
                  Det är dags att synliggöra den politiska processen.
                </Text>
              )}
            </View>
            <View style={[styles.secondTitle]}>
              <Text
                style={[
                  styles.homeTextBottom,
                  isMobile && {
                    fontSize: 22,
                    fontWeight: "700",
                    lineHeight: 39,
                    width: "100%",
                  },
                  isTablet && { fontSize: 26 },
                ]}
              >
                Utan värdering.
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                alignSelf: "center",
                marginVertical: "3%",
              }}
            >
              <Text
                style={[
                  styles.textBody,
                  isMobile && {
                    fontSize: 16,
                    fontWeight: "400",
                    lineHeight: 22,
                    width: "100%",
                  },
                  isTablet && { fontSize: 20 },
                ]}
              >
                Sök runt i vår databas, eller logga in för att spara händelser,
                följa politiker och partier med notiser och skapa individuella
                bevakningar.
              </Text>
              <CreateAccount />
              <View style={[styles.imgWrapper]}>
                <Pressable onPress={() => scrollToElement()}>
                  <View style={[styles.imgWrapper1]}>
                    <Image
                        source={require("../../../public/images/arrowsDown.svg")}
                        style={[styles.img]}
                    />
                  </View>
                  <Text style={[styles.textArrow]}>Läs mer</Text>
                </Pressable>
              </View>
            </View>
          </View>
          <View ref={scrollViewRef}>
            {isMobile ? <MobContent /> : <Content  />}
          </View>
          <View  style={styles.ParagraphWhite}>
            <Text
              style={[
                styles.homeTextBottomTitle,
                isMobile && {
                  fontSize: 22,
                  fontWeight: "700",
                  lineHeight: 33,
                },
                isTablet && { lineHeight: 26, fontSize: 20 },
              ]}
            >
              Om Noterat
            </Text>
            <Text
              style={[
                styles.homeTextBottomBody,
                isMobile && {
                  fontSize: 12,
                  fontWeight: "600",
                  lineHeight: 14,
                  width: "90%",
                },
                ,
                isTablet && { lineHeight: 20, fontSize: 15 },
              ]}
            >
              Noterat.io är en partipolitiskt obunden ideell förening initierad
              av Change Equity med syftet att synliggöra den politiska processen
              för allmänheten, genom att tillhandahålla och kommunicera
              offentliga handlingar, såväl som statistik kring offentliga
              aktiviteter.{" "}
              <Hoverable>
                {(isHovered) => (
                  <ExternalLink href={baseURLForServer + "/om-oss"}>
                    <Text
                      style={[
                        styles.homeTextBottomLink,
                        isMobile && {
                          fontSize: 12,
                          fontWeight: "700",
                          lineHeight: 14,
                        },
                        isHovered ? styles.hovered : null,
                        ,
                        isTablet && { lineHeight: 20, fontSize: 15 },
                      ]}
                    >
                      Läs mer om Noterat.io
                    </Text>
                  </ExternalLink>
                )}
              </Hoverable>
            </Text>
            <Text
              style={[
                styles.homeTextSecBottomTitle,
                isMobile && {
                  fontSize: 12,
                  fontWeight: "600",
                  lineHeight: 18,
                },
                ,
                isTablet && { lineHeight: 18, fontSize: 15 },
              ]}
            >
              <Hoverable>
                {(isHovered) => (
                  <ExternalLink href={baseURLForClient + "/contact"}>
                    <Text
                      style={[
                        styles.homeTextBottomFaq,
                        isHovered ? styles.hovered : null,
                        isMobile && {
                          fontSize: 12,
                          fontWeight: "600",
                        },
                        isTablet && { lineHeight: 18, fontSize: 15 },
                      ]}
                    >
                      skicka oss ett meddelande
                    </Text>
                  </ExternalLink>
                )}
              </Hoverable>
            </Text>
          </View>
        </View>
        <View style={styles.faqSec}>
          <FaqContent />
        </View>
        <View>
          <Contactinformation />
        </View>
        <Footer />
      </ScrollView>
      <Cookie />
    </>
  );
}

const styles = StyleSheet.create({
  imgWrapper: {
    width: "50%",
    justifyContent: "flex-end",
    alignSelf: "center",
    backgroundColor: "#fffff",
  },
  imgWrapper1: {
    height: 64,
  },
  img: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  textArrow: {
    fontSize: 24,
    fontWeight: "500",
    textAlign: "center",
    marginTop: "5%",
    color: "#202020",
  },
  mainContainer: {
    width: "100%",
    flex: 1,
    paddingBottom: "8%",
    justifyContent: "center",
    textAlign: "center",
  },
  mainTitle: {
    height: 40,
    justifyContent: "center",
    marginTop: 120,
    alignSelf: "center",
    marginBottom: 30,
  },
  secondTitle: {
    height: 40,
    justifyContent: "center",
    marginTop: 16,
    alignSelf: "center",
  },
  textBody: {
    textAlign: "center",
    fontSize: 24,
    width: "60%",
    marginBottom: 34,
    fontWeight: 400,
    lineHeight: 26,
  },
  homeText: {
    backgroundColor: "#C2BAFF",
    textAlign: "center",
    fontSize: 40,
    color: "#202020",
    fontWeight: "700",
    lineHeight: 60,
    marginBottom: 10,
  },
  homeTextBottom: {
    backgroundColor: "#FFFE9A",
    textAlign: "center",
    color: "#202020",
    fontSize: 40,
    fontWeight: "700",
  },
  homeTextBottomTitle: {
    fontSize: 36,
    fontWeight: "700",
    lineHeight: 54,
  },
  homeTextBottomBody: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 29,
    textAlign: "center",
    width: "70%",
    alignItems: "center",
    alignSelf: "center",
  },
  homeTextBottomLink: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 30,
    textDecorationLine: "underline",
  },
  homeTextSecBottomTitle: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 36,
    textAlign: "center",
    marginTop: "5%",
  },
  homeTextBottomFaq: {
    fontSize: 24,
    fontWeight: "600",
    textAlign: "center",
    marginTop: "2%",
    textDecorationLine: "underline",
  },
  hovered: {
    textDecorationLine: "none",
  },
  faqSec: {
    height: "auto",
    backgroundColor: "#F9F8F7",
  },
  ParagraphWhite: {
    height: Dimensions.get("window").height / 3,
    paddingVertical: "5%",
  },
});
