import React from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";
import commonStyles from "../commonstyles";
import { useMediaQuery } from "react-responsive";
import Footer from "../footer/footer";
import Header from "../header/header";
import PropTypes from "prop-types";
import Back from "../navigation/back";

const Användarvillkor = (props: PropTypes.object) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isTablet = useMediaQuery({
    maxWidth: 960,
    minWidth: 601,
  });

  return (
    <View style={styles.aboutPage}>
      <Header
        profile={false}
        feed={false}
        navigation={props.navigation}
      ></Header>
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {!isMobile && (
          <Back pageName="Allmänna villkor" navigation={props.navigation} />
        )}
        <Text style={[styles.aboutMainTitle]}>Allmänna villkor</Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Noterat.io tillhandahåller en tjänst (hädanefter benämnd ”tjänsten”) i
          syfte att tillgängliggöra offentliga handlingar från den svenska
          statsförvaltningen till allmänheten på ett ofiltrerat sätt. Genom att
          använda tjänsten ger du oss tillåtelse att spara och bearbeta de
          personuppgifter som du lämnar till oss. Du kan läsa mer om hur dina
          personuppgifter hanteras i integritetspolicyn nedan.
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Du kan när som helst avsluta tjänsten i plattformen. Vi förbehåller
          oss vidare rätten att när som helst avsluta användarkonton i tjänsten
          i samband med upprepade överträdelser, vilket du kan läsa mer om i
          våra Forumregler & Användarvillkor. I samband med att en användare
          stängs av tar vi bort alla personuppgifter du har lämnat till oss.
          Undantag från detta kan dock göras om det är nödvändigt att spara
          personuppgifterna av något legitimt skäl, t.ex. i bevissyfte vid
          pågående utredning.
        </Text>

        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Ägarskap till webbplatsens innehåll
        </Text>

        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Noterat.io är ensam ägare eller laglig licenstagare till alla
          rättigheter och intressen i webbplatserna och webbplatsens innehåll.
          Webbplatserna och webbplatsinnehållet immateriella rättigheter som
          skyddas under svensk lag. All äganderätt, äganderätt och immateriella
          rättigheter till webbplatserna och webbplatsinnehållet förblir hos
          Noterat.io. Även samtliga rättigheter som inte på annat sätt görs
          anspråk på under Villkoren eller av Noterat.io förbehålls härmed.
        </Text>
        <Text
          style={[
            commonStyles.headlineBold,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Användarvillkor
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Registrering
        </Text>

        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          För att få tillgång till och använda vissa tjänster måste du
          registrera dig som medlem på Noterat.io. Noterat.io förbehåller sig
          rätten att, utan föregående meddelande, begränsa åtkomst till eller
          användning av vissa tjänster (eller några funktioner inom tjänsterna)
          till betalande användare, eller under förutsättning av andra villkor
          som Noterat.io kan stadga.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Regelefterlevnad
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Som ett villkor för din åtkomst till och användning av webbplatserna
          eller tjänsterna samtycker du till att du kommer att följa alla
          tillämpliga lagar,villkor och förordningar när du får åtkomst till
          eller använder webbplatserna eller tjänsterna.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Tillgänglighet
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Tjänsterna (eller alla funktioner inom Tjänsterna) är avsedda för
          användare i Sverige. Ingen garanti eller representation ges att en
          viss tjänst eller funktion eller funktion därav eller samma typ och
          omfattning av tjänsten eller funktioner och funktioner därav kommer
          att vara tillgängliga för användarna. Noterat.io kan efter eget
          gottfinnande begränsa, neka eller skapa olika nivåer av åtkomst till
          och användning av alla tjänster (eller funktioner inom tjänsterna) med
          avseende på olika användare.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Otillåten användning av information
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Du samtycker till att du inte kommer att (a) kopiera, reproducera,
          ladda ner, återpublicera, sälja, distribuera eller sälja vidare några
          tjänster eller någon information, text, bilder, grafik, videoklipp,
          ljud, kataloger, filer, databaser eller listor, etc. tillgängligt på
          eller via webbplatserna ("webbplatsens innehåll") och (b) kopiera,
          reproducera, ladda ner, kompilera eller på annat sätt utan Noterat.ios
          samtycke använda något webbplatsinnehåll i syfte att driva en
          verksamhet som konkurrerar med Noterat.io, eller på annat sätt
          kommersiellt utnyttjande av webbplatsinnehållet. Systematisk hämtning
          av webbplatsinnehåll från webbplatserna för att skapa eller kompilera,
          direkt eller indirekt, en samling, kompilering, databas eller katalog
          utan skriftligt tillstånd från Noterat.io är förbjudet. Användning av
          innehåll eller material på webbplatserna för något syfte som inte
          uttryckligen tillåts i villkoren är förbjudet och kommer att besvaras
          med rättsliga åtgärder.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Skyldighet att granska integritetspolicyn & andra villkor
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Användare som har tillgång till eller använder webbplatsen, eller
          webbplatser relaterade till Noterat.io, är skyldiga att läsa vår
          integritetspolicy .
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Datorsystemintegritet
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Datorsystemintegritet Användare åtar sig att inte vidta några åtgärder
          för att undergräva integriteten hos Noterat.io datorsystem eller
          nätverk och/eller någon annan användare eller att få obehörig åtkomst
          till sådana datorsystem eller nätverk.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Teknisk kontoinformation
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Teknisk kontoinformation Vid registrering på webbplatserna ska
          Noterat.io tilldela ett konto och utfärda ett användar-ID och lösenord
          (det senare ska väljas av en registrerad användare under
          registreringen) till varje registrerad användare. En uppsättning
          användar-ID och lösenord är unika för ett enda konto.
        </Text>
        <Text
          style={[
            commonStyles.headline,
            styles.aboutTitle,
            isMobile && styles.aboutTitleMobile,
          ]}
        >
          Lösenordsintegritet
        </Text>
        <Text style={[styles.aboutText, isMobile && styles.aboutTextMobile]}>
          Noterat.io åtar sig att tillämpa en adekvat nivå av säkerhet för att
          garantera användarnas integritet. Dock är varje medlem är ansvarig för
          att upprätthålla konfidentialitet för ditt användar-ID och lösenord
          och för all användning av och aktiviteter som sker under ditt konto
          (oavsett om sådan användning eller aktiviteter är auktoriserade eller
          inte). Ingen medlem får dela, tilldela eller tillåta användningen av
          ditt medlemskonto, ID eller lösenord av en annan person, inte ens till
          andra individer inom medlemmens egen affärsenhet (i tillämpliga fall).
          Medlemmen samtycker till att omedelbart meddela Noterat.io om du blir
          medveten om någon obehörig användning av ditt lösenord eller ditt
          konto eller något annat brott mot säkerheten för ditt konto.
        </Text>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  aboutPage: {
    width: "100%",
    flex: 1,
    textAlign: "center",
  },
  aboutMainTitle: {
    fontFamily: "Poppins_Bold",
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 48,
    margin: "2%",
  },

  aboutTitle: {
    marginTop: 10,
    textAlign: "left",
    width: "70%",
    maxWidth: "57rem",
    alignItems: "center",
    alignSelf: "center",
  },

  aboutTitleMobile: {
    width: "85%",
  },

  aboutText: {
    fontFamily: "museo_sans500",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 24,
    textAlign: "left",
    width: "70%",
    maxWidth: "57rem",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 6,
    marginBottom: 20,
  },
  aboutTextMobile: {
    fontSize: 12,
    lineHeight: 14,
    width: "85%",
  },
});

export default Användarvillkor;
