import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  Pressable,
  Image,
  ScrollView,
} from "react-native";
import Header from "../header/header";
import LinkComment from "./linkcomment";
import ProfilePicture from "../profilepicture";
import NoticesCheckbox from "./noticescheckbox";
import ProfileChooser from "./profilechooser";
import Coverage from "./coverage";
import Saved from "./saved";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { baseURLForServer } from "../../config";
import { useIsFocused, useRoute } from "@react-navigation/native";
import commonStyles from "../commonstyles";
import { commentFeaturToggle } from "../../featureToggles";
import { numberOfCoverages } from "../../helpers";
import Footer from "../footer/footer";

export default function Profile(props: PropTypes.object) {
  const [choice, setChoice] = useState(0);

  const [writtenComments, setWrittenComments] = useState([]);
  const [commentsReactedTo, setCommentsReactedTo] = useState([]);

  const userData = useSelector((state) => state.data.userData);

  const isMobile = useMediaQuery({
    maxWidth: 1000,
  });

  const isFocused = useIsFocused();
  const route = useRoute();

  useEffect(() => {
    route.params && route.params.section && setChoice(route.params.section);
  }, [isFocused]);

  useEffect(() => {
    if (userData.id) {
      fetch(baseURLForServer + "/comments/userId/" + userData.id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userData.jwt,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setWrittenComments(json);
        })
        .catch((error) => {
          console.log(error);
        });
      fetch(baseURLForServer + "/comments/userReacted/" + userData.id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userData.jwt,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setCommentsReactedTo(json);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userData]);

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  async function signOut(): Promise<any> {
    try {
      await Auth.signOut();
      props.navigation.navigate("Feed");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Header />
      <ScrollView style={{ paddingTop: 50, marginTop: 70 }}>
        <View style={styles.container}>
          <View style={[styles.mainInfo, isMobile && styles.limitedWidth]}>
            <View style={styles.pictureAndName}>
              <ProfilePicture
                firstName={userData.firstname}
                lastName={userData.lastname}
              />
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 12,
                  width: "100%",
                }}
              >
                <Text style={[styles.name, commonStyles.headlineBold]}>
                  {userData.firstname + " " + userData.lastname}
                </Text>
                <Text style={[{ marginTop: 8 }, commonStyles.standardFont]}>
                  {numberOfCoverages(userData.filterPresets)} bevakningar •{" "}
                  {userData.userEventCount} sparade
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  styles.partyText,
                  { marginTop: 16, marginBottom: 12 },
                  commonStyles.standardFont,
                ]}
              >
                Notiser via mail
              </Text>
              <NoticesCheckbox />
            </View>
            <ProfileChooser
              onChoose={(section) => setChoice(section)}
              chosen={choice}
            />
            {choice === 0 && <Coverage />}
            {choice === 1 && <Saved />}
            {commentFeaturToggle && (
              <View>
                {choice === 0 && (
                  <View>
                    {writtenComments.map((comment, index) => (
                      <LinkComment key={index} {...comment} />
                    ))}
                  </View>
                )}
                {choice === 1 && (
                  <View>
                    {commentsReactedTo.map((comment, index) => (
                      <LinkComment key={index} {...comment} />
                    ))}
                  </View>
                )}
              </View>
            )}
          </View>
          <Image
            source={require("../../public/images/gradient_black.png")}
            style={[styles.gradient, isMobile && { width: "100%" }]}
          />
          <Pressable
            onPress={signOut}
            style={[
              styles.logoutButton,
              commonStyles.yellowBackground,
              commonStyles.smallRound,
            ]}
          >
            <Text style={[{ color: "#202020" }, commonStyles.headlineFont]}>
              Logga ut
            </Text>
          </Pressable>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
  },
  mainInfo: {
    width: 952,
  },
  limitedWidth: {
    width: "100%",
    paddingHorizontal: 24,
  },
  pictureAndName: {
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
  },
  partyText: {
    marginTop: 24,
    color: "#202020",
  },
  logoutButton: {
    width: 120,
    height: 42,
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 36 - 16,
    marginBottom: 36,
  },
  name: {
    color: "#202020",
  },
  gradient: {
    marginTop: 16,
    width: 800,
    height: 12,
    marginBottom: 12,
    alignSelf: "center",
    opacity: 0.02,
    resizeMode: "stretch",
  },
});
