import React from "react";
import { StyleSheet, View, Text, Linking, Pressable } from "react-native";
import commonStyles from "../commonstyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigation } from "@react-navigation/native";
import { footerLinks, soMeLinks } from "./footer";

export default function Footer() {
  const navigation = useNavigation();

  return (
    <View style={styles.footer}>
      <View style={styles.footerWrapper}>
        <View style={[styles.infoBoxLeft, commonStyles.smallShadow]}>
          {footerLinks.map((link, index) => (
            <Pressable
              key={index}
              onPress={() => navigation.navigate(link.screenName)}
            >
              <Text style={[styles.footerText]}>{link.footerText}</Text>
            </Pressable>
          ))}
        </View>
        <View style={[styles.infoBoxRight, commonStyles.smallShadow]}>
          <Text
            style={[
              commonStyles.standardFont,
              {
                fontSize: 12,
                fontWeight: "400",
                lineHeight: 15,
                alignSelf: "flex-end",
              },
            ]}
          >
            © 2022 Noterat
          </Text>
          <View style={[styles.infoBoxRightSocial, commonStyles.smallShadow]}>
            {soMeLinks.map((soMelink, index) => (
              <Pressable
                key={index}
                onPress={() => Linking.openURL(soMelink.link)}
              >
                <FontAwesomeIcon
                  icon={soMelink.icon}
                  style={{
                    fontSize: 20,
                    lineHeight: 20,
                    color: "#202020",
                    marginLeft: 20,
                  }}
                  width={17}
                  height={17}
                />
              </Pressable>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    height: 99,
    width: "100%",
    backgroundColor: "#E4E0DB",
    flexDirection: "column",
    padding: 10,
  },
  footerWrapper: {
    width: "100%",
    flexDirection: "row",
  },
  infoBoxLeft: {
    width: "50%",
    left: 12,
    textAlign: "left",
    justifyContent: "center",
  },
  infoBoxRight: {
    width: "50%",
    justifyContent: "flex-end",
    flexStart: "left",
    textAlign: "right",
    right: 12,
  },
  footerText: {
    color: "#2D2D2D",
    fontSize: 12,
    fontWeight: "500",
    lineHeight: 14,
    marginTop: 0,
  },
  infoBoxRightSocial: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 3,
  },
  hovered: {
    textDecorationLine: "underline",
  },
});
