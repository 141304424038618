import React from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

function QuickButton(props: PropTypes.object) {
  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable
          onPress={props.onPress}
          style={[
            styles.quickButton,
            commonStyles.yellowBackground,
            commonStyles.borderStyle,
            isHovered && commonStyles.buttonIsHovered,
          ]}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={[
                { color: "#202020" },
                commonStyles.headlineFont,
                isHovered && commonStyles.buttonIsHovered,
              ]}
            >
              {props.text}
            </Text>
          </View>
        </Pressable>
      )}
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  quickButton: {
    height: 50,
    width: 216,
    paddingLeft: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
});

export default QuickButton;
