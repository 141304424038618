import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable, Modal } from "react-native";
import { Link } from "@react-navigation/native";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import commonStyles from "../commonstyles";
import ModalLogin from "../modallogin";
import { Hoverable } from "react-native-web-hooks";

export default function LoginButton(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  return (
    <View>
      {Object.keys(userData).length !== 0 ? (
        <Hoverable>
          {(isHovered) => (
            <Link to="/profile">
              <View
                style={props.wide && { width: "100%" }}
              >
                <View
                  style={[
                    styles.menuButton,
                    props.wide && styles.wide,
                    isHovered && commonStyles.buttonIsHovered,
                  ]}
                >
                  <Text
                    style={[
                      commonStyles.headlineFont,
                      isHovered && commonStyles.buttonIsHovered,
                    ]}
                  >
                    min profil
                  </Text>
                </View>
              </View>
            </Link>
          )}
        </Hoverable>
      ) : (
        <Hoverable>
          {(isHovered) => (
            <Pressable onPress={showModal}>
              <View
                style={props.wide && { width: "100%" }}
              >
                <View
                  style={[
                    styles.menuButton,
                    props.wide && styles.wide,
                    props.isWhite && styles.buttonWhiteBackground,
                    isHovered && commonStyles.buttonIsHovered,
                  ]}
                >
                  <Text
                    style={[
                      isHovered && commonStyles.buttonIsHovered,
                      commonStyles.headlineFont,
                    ]}
                  >
                    logga in
                  </Text>
                </View>
              </View>
            </Pressable>
          )}
        </Hoverable>
      )}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <ModalLogin close={() => setModalVisible(false)} />
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  menuButton: {
    alignItems: "center",
    backgroundColor: "#FFFE9A",
    borderRadius: 8,
    flexDirection: "row",
    height: 42,
    justifyContent: "center",
    paddingHorizontal: 24,
  },
  buttonWhiteBackground: {
    backgroundColor: "white",
    borderColor: "black",
    borderWidth: 1,
  },
  wide: {
    alignItems: "center",
    flexDirection: "column",
    height: 45,
    justifyContent: "center",
  },
});
