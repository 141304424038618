import React from "react";
import { Image, Pressable, StyleSheet, TextInput, View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { setFeedSearch, toggleMobileFilters } from "../../../slices/dataslice";
import commonStyles from "../../commonstyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMediaQuery } from "react-responsive";

export default function FilterSearchBox() {
  const feedSearch = useSelector((state) => state.data.filters.search);
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View
      style={[
        commonStyles.borderStyle,
        commonStyles.bigShadow,
        styles.contentView,
      ]}
    >
      <FontAwesomeIcon
        icon={solid("search")}
        style={{
          color: "#2d2d2d",
          height: 22,
          paddingLeft: 10,
          width: 22,
        }}
      />
      <TextInput
        style={[styles.textInput, commonStyles.standardFont]}
        placeholder="Sök bland händelser..."
        placeholderTextColor="#2d2d2d"
        onChangeText={(text) => dispatch(setFeedSearch(text))}
        value={feedSearch}
      />
      {!isDesktop && (
        <Pressable onPress={() => dispatch(toggleMobileFilters())}>
          <Image
            source={require("../../../public/images/filter_icon.png")}
            style={styles.filterIcon}
          />
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  contentView: {
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "row",
    marginTop: 21,
    width: "100%",
  },
  textInput: {
    color: "#2d2d2d",
    fontSize: 14,
    height: 55,
    outlineStyle: "none",
    paddingVertical: 8,
    paddingLeft: 15,
    width: "100%",
  },
  filterIcon: {
    height: 55,
    width: 55,
  },
});
