import React, { useState } from "react";
import { StyleSheet, Pressable, Text, View } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function ExpandableText(props: PropTypes.object) {
  const [expanded, setExpanded] = useState(false);

  return (
    <View>
      <View style={[!expanded && styles.notExpanded]}>{props.children}</View>
      <Pressable
        onPress={() => setExpanded(!expanded)}
        style={[styles.expandButton, commonStyles.bigRound]}
      >
        <Text style={[{ color: "#888683" }, commonStyles.headlineFont]}>
          {!expanded && "Expandera"}
          {expanded && "Minimera"}
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  notExpanded: {
    height: 250,
    overflow: "hidden",
  },
  expandButton: {
    marginTop: 12,
    backgroundColor: "#F9F8F7",
    padding: 16,
    flexDirection: "row",
    justifyContent: "center",
  },
});
