import React, { useState, useEffect } from "react";
import { StyleSheet, Pressable, Text, View } from "react-native";
import { baseURLForServer } from "../../config";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function WrittenAnswerBox(props: PropTypes.object) {
  const [opened, setOpened] = useState(false);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    if (
      props.connectedEvent !== null &&
      props.connectedEvent !== undefined &&
      props.id !== props.connectedEvent.id
    ) {
      fetch(baseURLForServer + "/events/" + props.connectedEvent.id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setInfo(json);
          console.log(json);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <View style={{ marginTop: 24 }}>
      {props.connectedEvent !== null &&
        props.connectedEvent !== undefined &&
        props.id === props.connectedEvent.id && (
          <Text
            style={[
              commonStyles.standardFont,
              commonStyles.bigRound,
              commonStyles.smallShadow,
              styles.noneFound,
            ]}
          >
            Inget skriftligt svar hittades!
          </Text>
        )}
      {props.id !== props.connectedEvent.id && !info && (
        <Text style={[commonStyles.standardFont, { textAlign: "center" }]}>
          Laddar svar...
        </Text>
      )}
      {info && (
        <View
          style={
            !opened && [
              styles.notOpened,
              commonStyles.bigRound,
              commonStyles.bigShadow,
            ]
          }
        >
          <Text
            style={[
              commonStyles.headlineFontBold,
              { marginBottom: 2, color: "#202020" },
            ]}
          >
            Skriftligt svar från{" "}
            {info.politicians[0].sortName.split(",")[1] +
              " " +
              info.politicians[0].sortName.split(",")[0]}
          </Text>
          <Text style={[commonStyles.standardFont, { marginBottom: 12 }]}>
            {info.datePosted}
          </Text>
          {opened && (
            <View style={[!opened && styles.notExpanded]}>
              {info.summaryText.split("LINEBREAK").map((segment, index) => (
                <Text
                  style={[
                    {
                      fontSize: 15,
                      marginBottom: 12,
                    },
                    commonStyles.standardFont,
                  ]}
                  key={index}
                >
                  {segment}
                </Text>
              ))}
            </View>
          )}
          <Pressable
            onPress={() => setOpened(!opened)}
            style={[styles.expandButton, commonStyles.bigRound]}
          >
            <Text style={[{ color: "#888683" }, commonStyles.headlineFont]}>
              {!opened && "Visa"}
              {opened && "Dölj"}
            </Text>
          </Pressable>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  notExpanded: {
    height: 250,
    overflow: "hidden",
  },
  notOpened: {
    padding: 12,
  },
  expandButton: {
    backgroundColor: "#F9F8F7",
    padding: 8,
    flexDirection: "row",
    justifyContent: "center",
  },
  noneFound: {
    backgroundColor: "white",
    color: "#888683",
    padding: 16,
    textAlign: "center",
  },
});
