import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Linking,
  ScrollView,
} from "react-native";
import commonStyles from "../commonstyles";
import Header from "../header/header";
import Footer from "../footer/footer";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import Back from "../navigation/back";

const Integritetspolicy = (props: PropTypes.object) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const bulletTexts = [
    "Samtycke: Den registrerade har gett sitt samtycke till behandlingen av hans eller hennes personuppgifter för ett eller flera specifika ändamål.",
    "Kontrakt: Behandling är nödvändig för utförandet av ett kontrakt till vilket den registrerade är part eller för att vidta åtgärder på begäran av den registrerade innan kontrakt ingås.",
    "Rättslig förpliktelse: Behandling är nödvändig för att följa en lagstadgad skyldighet som den personuppgiftsansvariga är föremål för",
    "Skydd för grundläggande intressen: Behandling är nödvändig för att skydda den registrerades eller andra fysiska personers vitala intressen.",
    "Allmänt intresse: Bearbetning är nödvändig för utförandet av en uppgift som utförs i allmänhetens intresse eller i utövandet av den officiella myndighet som är tillställd den personuppgiftsansvarige",
    "Intresseavvägning: Bearbetning är nödvändig för de legitima intressen som förvaltaren eller tredje part förfogar över, utom när sådana intressen överträtts av intressen eller grundläggande rättigheter och friheter hos den registrerade som kräver skydd av personuppgifter, särskilt om uppgifterna ämnet gäller är ett barn.",
  ];

  return (
    <View style={styles.mainView}>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {!isMobile && <Back navigation={props.navigation} />}
        <View style={[styles.bodyView, isMobile && styles.bodyViewMobile]}>
          <Text style={styles.h1}>Integritetspolicy</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Noterat.io skyddar din integritet och behandlar dina personuppgifter
            i enlighet med EU:s dataskyddsförordning General Data Protection
            Regulation (GDPR). Dina personuppgifter är säkra hos oss och används
            endast enligt förutbestämd laglig grund och verksamhetens syfte. Som
            personuppgiftsansvarig är Change Equity, representerad av Allan
            Caman, bunden till dataskyddsförordningens krav. På Noterat.io tar
            vi din integritet och din rätt att kontrollera dina personuppgifter
            på allvar. Vi använder endast dina personuppgifter för att
            tillhandahålla de tjänster du har begärt från oss.
          </Text>
          <Text style={styles.h3}>Vad är en personuppgift?</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Varje upplysning som avser en identifierad eller identifierbar
            fysisk person. En identifierbar person är en person som kan
            identifieras, direkt eller indirekt, genom hänvisning till ett
            identifikationsnummer eller till en eller flera faktorer som är
            specifika för hans fysiska, fysiologiska, psykiska, ekonomiska,
            kulturella eller sociala identitet. Detta kan inkludera (men inte
            begränsas till): Namn, signatur, personnummer och kontaktuppgifter
            såsom e-post och telefonnummer.
          </Text>
          <Text style={styles.h3}>Vad är känslig data?</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vissa personuppgifter är till sin natur särskilt känsliga och har
            därför ett starkare skydd i dataskyddsförordningen. Det handlar om
            så kallade känsliga personuppgifter som till exempel personuppgifter
            som avslöjar etniskt ursprung, politiska åsikter, religiös eller
            filosofisk övertygelse eller uppgifter om hälsa och sexualliv, men
            även personuppgifter som rör lagöverträdelser som innefattar brott.
          </Text>
          <Text style={styles.h3}>Vad är en personuppgiftsansvarig?</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Personuppgiftsansvarig är den organisation som bestämmer för vilka
            ändamål uppgifterna ska behandlas och hur behandlingen ska gå till.
            {"\n"}
            Personuppgiftsansvarig är:{"\n"}
            {<b>Change Equity</b>}
            {"\n"}
            {"\n"}
            Dataskyddsombud: {"\n"}
            Allan Caman, Ordförande{"\n"}
            <TouchableOpacity
              onPress={() => Linking.openURL("mailto:chair@change-equity.com")}
            >
              <Text style={{ textDecorationLine: "underline" }}>
                chair@change-equity.com
              </Text>
            </TouchableOpacity>
            {"\n"}
            +46722981457{"\n"}
            Kungsgatan 34{"\n"}
            641 36, Katrineholm{"\n"}
          </Text>
          <Text style={styles.h3}>Vad är rättslig grund?</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            I enlighet med förordningen är rättslig grund en nödvändighet för
            behandling av personuppgifter. Det finns sex tillämpbara rättsliga
            grunder för behandling av personuppgifter:{"\n"}
            {"\n"}
            {bulletTexts.map((text, i) => (
              <View style={styles.bulletRow} key={i}>
                <Text>{"  \u2022  "}</Text>
                <View style={styles.bulletText}>
                  <Text>{text}</Text>
                </View>
              </View>
            ))}
          </Text>
          <Text style={styles.h3}>Tredje part</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vi kommer aldrig sälja dina personuppgifter till någon tredje part.
            Vårt arbete för dig kan dock kräva att vi överför din information
            till våra leverantörer av IT-tjänster i syfte att fullfölja vår
            verksamhet och tillhandahålla tjänster som du efterfrågar. När vi
            använder tredjepartsleverantörer, ger vi endast ut de
            personuppgifter som är nödvändiga för att leverera tjänsterna.
          </Text>
          <Text style={styles.h3}>Cookies vi använder på Noterat.io.se</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vår webbplats använder cookies. Cookies är en textfil som vår
            webbplats begär att få spara på din dator. Cookies används för att
            samla information om besökarens standard log information och
            preferenser/beteenden. Denna information används sedan för att
            registrera besökare på hemsidan och för att skapa statistiska
            rapporter kring hemsidans aktivitet.{"\n"}
            {"\n"}
            Följande cookies används på webbplatsen:{"\n"}
            {" 1. "}“firstTimeUser” för att fastställa om besökaren är en
            förstagångsanvändare, för att därefter anpassa startsidan. {"\n"}
            Används om besökaren accepterar cookies. Förstapartscookie som
            lagras i 100 dagar.
          </Text>
          <Text style={styles.h3}>Stänga av alla cookies</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Om du inte accepterar att webbplatsen använder cookies kan du stänga
            av funktionen via din webbläsares säkerhetsinställningar. Då kommer
            inga cookies att lagras. Observera att användarupplevelsen kan
            försämras om du blockerar cookies.
          </Text>
          <Text style={styles.h2}>Överföring av data utanför Europa</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Som en del av de tjänster som vi erbjuder via denna webbplats kan
            informationen som du ger oss överföras till länder utanför EU
            ("EU"). Till exempel kan vissa av våra leverantörer från tredje part
            vara belägna utanför EU. Om så är fallet kommer vi vidta åtgärder
            för att se till att rätt säkerhetsåtgärder vidtas så att dina
            personuppgifter fortsätter att skyddas enligt vad som anges i denna
            policy. Genom att lämna dina personuppgifter godkänner du
            överföringen, lagringen eller behandlingen. När våra leverantörer
            befinner sig i USA har vi försäkrat oss om att deras tjänster
            omfattas av "Privacy Shield", där medverkande företag anses ha ett
            adekvat skydd och därmed möjliggör överföringen av information från
            EU till USA.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Om du använder våra tjänster när du är utanför EU, kan din
            information överföras utanför EU för att ge dig dessa tjänster.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            När du lämnar personuppgifter till oss, har vi åtgärder för att se
            till att de behandlas säkert. Dina personuppgifter används endast
            utifrån förutbestämd laglig grund eller det specifika syftet av
            Noterat.ios verksamhet. Dina uppgifter skyddas av en viss IT-miljö
            där endast behöriga personer har rätt åtkomst.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Icke-känsliga uppgifter (din e-postadress etc.) skickas normalt över
            internet, och kan aldrig garanteras 100% säkerhet. Som ett resultat
            av detta kan vi inte garantera säkerheten för alla personuppgifter
            som du skickar till oss och det sker därför på egen risk. När vi har
            fått dina personuppgifter gör vi vårt bästa för att säkerställa
            säkerheten på våra system. Där vi har gett (eller där du valt) ett
            lösenord som gör att du får tillgång till vissa delar av vår
            webbplats, är du ansvarig för att hålla detta lösenord hemligt. Vi
            ber dig att inte dela ditt lösenord med någon.
          </Text>
          <Text style={styles.h2}>Hur länge lagrar vi personuppgifter</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vi kommer att spara dina personuppgifter så länge medlemskapet
            sträcker sig.
          </Text>
          <Text style={styles.h2}>
            Tillgång till dina data, rättelse, radering och överföring
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Enligt dataskyddsförordningen har du rätt att begära ett gratis
            registerutdrag per år som visar hur dina personuppgifter behandlas i
            Noterat.ios olika system och register. Du har rätt att få
            information om vilka personuppgifter som behandlas, syftet med
            behandlingen, lagringstiden och de mottagare som uppgifterna lämnas
            ut till, logiken bakom automatiserad behandling av personuppgifter
            och konsekvenserna av sådan behandling.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Du har också rätt att få felaktiga data rättad eller raderad om
            behandlingen inte är utförd i enlighet med dataskyddsförordningen.
          </Text>
          <Text style={styles.h3}>Hur man gör en förfrågan</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            För att ansöka om ett registerutdrag kan du använda formuläret som
            du hittar längst ner på sidan. När du fyller i formuläret är det
            viktigt att ditt personnummer, ditt namn och din adress. Du ska
            också skriva under med din egna signatur. {"\n"}
            Skriv ut formuläret, skriv under och skicka din förfrågan till:{" "}
            {"\n"}
            {"\n"}
            Change Equity {"\n"}
            Allan Caman {"\n"}
            Professorsslingan 47, 1001{"\n"}
            114 17, Stockholm {"\n"}
            {"\n"}
            Registerutdraget skickas sedan via post till din
            folkbokföringsadress.
          </Text>
          <Text style={styles.h3}>Rättelse, radering och överföring</Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Om de personuppgifter vi behandlar är felaktiga, har du rätt att
            kontakta oss och begära rättelse och i vissa fall radering av
            uppgifterna. Vi kommer också att undersöka om din förfrågan är
            berättigad och i sådana fall ändra eller komplettera uppgifterna i
            enlighet med dina instruktioner.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            På grund av särskilda omständigheter kan du också i vissa fall göra
            invändningar mot behandlingen av dina personuppgifter. I sådana fall
            kommer Noterat.io att undersöka om det finns ett berättigat intresse
            för fortsatt bearbetning av dina uppgifter.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Du kan begära att behandlingen av dina personuppgifter blir
            begränsad under tiden av vår undersökning som beskrivs enligt ovan.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Du har också rätt att få de personuppgifter som du har gett oss, i
            ett strukturerat och maskinläsbart format och utan dröjsmål
            möjlighet att överföra datan till en annan personuppgiftsansvarig
            om: bearbetningen är baserad på samtycke eller på ett kontrakt och
            bearbetningen genomförs med automatiska medel.
          </Text>
          <Text style={[styles.p, isMobile && styles.pMobile]}>
            Vi vill se till att dina personuppgifter är korrekta och
            uppdaterade. Om du har frågor angående behandlingen av dina
            personuppgifter, kontakta oss via e-post{" "}
            <TouchableOpacity
              onPress={() => Linking.openURL("mailto:info@Noterat.io.se")}
            >
              <Text style={{ textDecorationLine: "underline" }}>
                info@Noterat.io.se
              </Text>
            </TouchableOpacity>
            ,{" "}
            <TouchableOpacity
              onPress={() => Linking.openURL("mailto:chair@change-equity.com")}
            >
              <Text style={{ textDecorationLine: "underline" }}>
                chair@change-equity.com
              </Text>
            </TouchableOpacity>
            eller skriv till ovanstående postboxadress.
          </Text>
          <Text style={styles.h3}>Ändringar i vår integritetspolicy</Text>
          <Text
            style={[styles.p, isMobile && styles.pMobile, { marginBottom: 50 }]}
          >
            Noterat.io förbehåller sig rätten att genomföra ändringar i vår
            integritetspolicy. Vi åtar oss vidare att informera er i samband med
            en sådan ändring.
          </Text>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  bodyView: { width: "80%", maxWidth: "57rem", alignSelf: "center" },
  bodyViewMobile: { width: "90%" },
  h1: {
    fontFamily: "Poppins_Bold",
    fontWeight: "700",
    fontSize: 28,
    lineHeight: 48,
    alignSelf: "center",
    marginBottom: 20,
  },
  h2: {
    fontFamily: "Poppins_Bold",
    fontSize: 15,
    lineHeight: 28,
  },
  h3: {
    fontFamily: "Poppins_500Medium",
    fontSize: 15,
    lineHeight: 28,
  },
  p: {
    fontFamily: "museo_sans500",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 20,
  },
  pMobile: {
    fontSize: 10,
    lineHeight: 12,
  },
  bulletRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    marginVertical: 2,
  },
  bulletText: {
    flex: 1,
  },
});

export default Integritetspolicy;
