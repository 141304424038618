import React from "react";
import { Image, View, Pressable, StyleSheet, Text } from "react-native";
import commonStyles from "../../commonstyles";
import { useNavigation } from "@react-navigation/native";
import HeaderMenuIcons from "./headermenuicons";

export default function MobileHeader() {
  const navigation = useNavigation();

  return (
    <View style={[styles.box]}>
      <View style={[styles.mainContainer]}>
        <View>
          <Pressable
            style={styles.logoImageAndText}
            onPress={() => navigation.navigate("LandingPage", {})}
          >
            <Image
              source={require("../../../public/images/n.png")}
              style={styles.logoImage}
            />
            <Text style={[styles.logoText, commonStyles.headlineFont]}>
              noterat
            </Text>
          </Pressable>
        </View>
        <View>
          <HeaderMenuIcons onlyLinks={true} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  mainContainer: {
    alignItems: "center",
    backgroundColor: "#FFF",
    borderBottomColor: "#2D2D2D",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: ".6rem",
    paddingHorizontal: "1rem",
  },
  logoImageAndText: {
    alignItems: "center",
    height: 34 + 4 + 15,
    justifyContent: "center",
  },
  logoImage: {
    height: 28,
    width: 27,
  },
  logoText: {
    color: "#202020",
    fontSize: 15,
    lineHeight: 15,
    marginTop: 4,
  },
});
