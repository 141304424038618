import React, { useEffect, useState } from "react";
import { View, TextInput, StyleSheet, Pressable, Text, Image } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { setPoliticianIds, setPoliticianIdsCoverage } from "../../../slices/dataslice";
import { baseURLForServer } from "../../../config";
import commonStyles from "../../commonstyles";
import Checkbox from "../../event/checkbox";
import { useMediaQuery } from "react-responsive";

export default function PoliticianSearchBox(props) {
  const [politicians, setPoliticians] = useState([]);
  const [page, setPage] = useState(0);
  const [politicianFeedSearch, setPoliticianFeedSearch] = useState("");

  if (props.coverageMode) {
    var coverage = useSelector((state) => JSON.parse(state.data.userData.filterPresets.politician));
  } else {
    var politicianIds = useSelector(
      (state) => state.data.filters.politicianIds
    );
  }
  const dispatch = useDispatch();

  const fetchPoliticians = (pageArg: number) => {
    return fetch(
      baseURLForServer +
      "/politicians?page=" +
      pageArg.toString() +
      "&size=5&party=V,S,MP,C,L,M,KD,SD&name=" +
      politicianFeedSearch,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    fetchPoliticians(0)
      .then((response) => response.json())
      .then((json) => {
        setPoliticians(json.items);
        setPage(0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [politicianFeedSearch]);

  const switchPage = (pageChange: number) => {
    const newPage = page + pageChange;
    fetchPoliticians(newPage)
      .then((response) => response.json())
      .then((json) => {
        if (json.items.length > 0) {
          setPoliticians(json.items);
          setPage(newPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View>
      {props.coverageMode
        ? coverage.map((p, key) => (
          <Checkbox
            key={key}
            checked
            text={p.name}
            onChange={() =>
              dispatch(
                setPoliticianIdsCoverage({
                  name: p.firstName + " " + p.familyName,
                  party: p.party,
                  id: p.id.toString(),
                })
              )
            }
          />
        ))
        : politicianIds.map((p, key) => (
          <Checkbox
            key={key}
            checked
            text={p.name}
            onChange={() =>
              dispatch(
                setPoliticianIds({
                  name: p.firstName + " " + p.familyName,
                  party: p.party,
                  id: p.id.toString(),
                })
              )
            }
          />
        ))}
      <TextInput
        style={[
          styles.searchBox,
          commonStyles.standardFont,
          commonStyles.borderStyle,
          isDesktop && { width: 190 },
        ]}
        placeholder="Sök bland politiker..."
        onChangeText={(text) => {
          setPoliticianFeedSearch(text);
        }}
        value={politicianFeedSearch}
      />
      {politicians.length > 0 && (
        <View style={styles.navigationContainer}>
          <Pressable
            onPress={() => switchPage(-1)}
            style={[
              styles.pageButton,
              commonStyles.borderStyle,
              commonStyles.yellowBackground,
            ]}
          >
            <Image
              source={require("../../../public/images/soft_arrow_black.svg")}
              style={[
                commonStyles.arrow,
                { transform: [{ rotateZ: "180deg" }] },
              ]}
            />
          </Pressable>
          <Pressable
            onPress={() => switchPage(1)}
            style={[
              styles.pageButton,
              commonStyles.borderStyle,
              commonStyles.yellowBackground,
            ]}
          >
            <Image
              source={require("../../../public/images/soft_arrow_black.svg")}
              style={commonStyles.arrow}
            />
          </Pressable>
        </View>
      )}
      <View>
        {politicians.map((p) => (
          <Pressable
            key={p.id}
            onPress={() => {
              if (props.coverageMode) {
                dispatch(
                  setPoliticianIdsCoverage({
                    name: p.firstName + " " + p.familyName,
                    party: p.party,
                    id: p.id.toString(),
                  })
                );
              } else {
                dispatch(
                  setPoliticianIds({
                    name: p.firstName + " " + p.familyName,
                    party: p.party,
                    id: p.id.toString(),
                  })
                );
              }
            }}
            style={[
              !props.coverageMode &&
              politicianIds
                .map((p) => String(p.id))
                .includes(p.id.toString()) &&
              commonStyles.purpleBackground,
              props.coverageMode &&
              coverage !== null &&
              coverage.politician &&
              JSON.parse(coverage.politician)
                .map((p) => p.id)
                .includes(p.id.toString()) &&
              commonStyles.purpleBackground,
              styles.listItem,
            ]}
          >
            <Text style={commonStyles.headlineFont}>
              {p.firstName + " " + p.familyName}
            </Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  searchBox: {
    fontSize: 14,
    height: 31,
    lineHeight: 14,
    outlineStyle: "none",
    paddingLeft: 10,
    paddingVertical: 5,
  },
  navigationContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 8,
  },
  pageButton: {
    height: 26,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  listItem: {
    borderRadius: 8,
    padding: 5,
    marginTop: 8,
  },
});
