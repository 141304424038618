import React from "react";
import { View, Pressable, Image, StyleSheet } from "react-native";

export default function BackButton(props: { navigation: any }) {
  return (
    <View style={styles.imageWrapper}>
      <Pressable
        onPress={() => {
          if (props.navigation.canGoBack()) {
            props.navigation.goBack();
          } else {
            props.navigation.navigate("Feed");
          }
        }}
      >
        <Image
          source={require("../public/images/back_arrow.svg")}
          style={styles.stretch}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  stretch: {
    width: 39,
    height: 30,
  },
  imageWrapper: {},
});
