import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import commonStyles from "../commonstyles";
import LoginButton from "./loginbutton";
import { useSelector } from "react-redux";
import { Hoverable } from "react-native-web-hooks";
import { useNavigation } from "@react-navigation/native";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "./mobileheader/mobileheader";
import MobileLinkButtons from "./mobileheader/mobilelinkbuttons";

export const linksHeader = [
  {
    currentURL: "Feed",
    textLink: "nyhetsflöde",
  },
  {
    currentURL: "Statistik",
    textLink: "statistik",
  },
  {
    currentURL: "Politikerregister",
    textLink: "politikerregister",
  },
  {
    currentURL: "OmOss",
    textLink: "om Noterat",
  },
  {
    currentURL: "FAQ",
    textLink: "FAQ",
  },
];

export default function Header() {
  const navigation = useNavigation();
  const csrf = useSelector((state) => state.data.csrf);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const [currentURL, setCurrentURL] = useState("");
  const mobileExpanded = useSelector((state) => state.data.mobileExpanded);

  useEffect(() => {
    setCurrentURL(localStorage.getItem("currentURL"));
  }, [currentURL]);

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View style={[styles.box]}>
      {isDesktop ? (
        <View style={[styles.mainContainer]}>
          <View>
            <Pressable
              style={styles.logoImageAndText}
              onPress={() => navigation.navigate("LandingPage", {})}
            >
              <Image
                source={require("../../public/images/n.png")}
                style={styles.logoImage}
              />
              <Text style={[styles.logoText, commonStyles.headlineFont]}>
                noterat
              </Text>
            </Pressable>
          </View>
          <View style={styles.buttonsContainer}>
            {linksHeader.map((header, index) => (
              <Hoverable key={index}>
                {(isHovered) => (
                  <Pressable
                    style={[
                      styles.links,
                      isHovered && styles.hovered,
                      currentURL === header.currentURL && styles.hovered,
                    ]}
                    onPress={() => navigation.navigate(header.currentURL, {})}
                  >
                    <Text style={[commonStyles.headlineFont, styles.textLinks]}>
                      {header.textLink}
                    </Text>
                  </Pressable>
                )}
              </Hoverable>
            ))}
            <LoginButton csrf={csrf} loggedIn={loggedIn} />
          </View>
        </View>
      ) : (
        <View>
          <MobileHeader />
          {mobileExpanded === "links" && (
            <MobileLinkButtons csrf={csrf} loggedIn={loggedIn} />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  mainContainer: {
    alignItems: "center",
    backgroundColor: "#FFF",
    borderBottomColor: "#2D2D2D",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: ".6rem",
    paddingHorizontal: "2rem",
  },
  logoImageAndText: {
    alignItems: "center",
    height: 34 + 4 + 15,
    justifyContent: "center",
  },
  logoImage: {
    height: 28,
    width: 27,
  },
  logoText: {
    color: "#202020",
    fontSize: 15,
    lineHeight: 15,
    marginTop: 4,
  },
  buttonsContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  links: {
    marginRight: "1.5rem",
    paddingBottom: 2,
  },
  textLinks: {
    fontSize: 15,
  },
  hovered: {
    borderBottomColor: "#2D2D2D",
    borderBottomWidth: 2,
  },
});
