import React, { useState } from "react";
import { StyleSheet, Pressable, Modal } from "react-native";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch, useSelector } from "react-redux";
import { baseURLForServer } from "../../config";
import { delike, like } from "../../slices/feedandeventslice";
import ModalLogin from "../modallogin";
import { userEventCountChange } from "../../slices/dataslice";

function Bookmark(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);

  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  const toggleBookmark = () => {
    if (props.userEvent) {
      // De-like...
      fetch(
        baseURLForServer +
        "/users/" +
        userData.id +
        "/events/" +
        props.userEvent.id,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userData.jwt,
          },
        }
      )
        .then((_response) => {
          dispatch(userEventCountChange(-1));
          dispatch(delike(props.eventId));
        })
        .catch((err) => console.log(err));
    } else {
      // Like...
      fetch(baseURLForServer + "/users/" + userData.id + "/events", {
        method: "POST",
        body: JSON.stringify({
          eventId: props.eventId,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userData.jwt,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(userEventCountChange(1));
          dispatch(like({ userEvent: json, eventId: props.eventId }));
        })
        .catch((err) => console.log(err));
    }
  };

  const showModal = () => setModalVisible(true);

  return (
    <Pressable
      onPress={Object.keys(userData).length ? toggleBookmark : showModal}
      style={[styles.mainContainer]}
    >
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <ModalLogin close={() => setModalVisible(false)} />
      </Modal>
      {props.userEvent && (
        <FontAwesomeIcon
          style={{ fontSize: 24, lineHeight: 24, color: '#C2BAFF' }}
          width={24}
          height={24}
          icon={solid("bookmark")}
        />
      )}
      {!props.userEvent && (
        <FontAwesomeIcon
          style={{ fontSize: 24, lineHeight: 24, color: "#202020" }}
          width={24}
          height={24}
          icon={regular("bookmark")}
        />
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    position: "absolute",
    right: 0,
  },
});

export default Bookmark;
