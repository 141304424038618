import React from "react";
import { Pressable, Text, StyleSheet } from "react-native";
import commonStyles from "../commonstyles";

const LoadMoreButton = (props: { onPress: () => void }) => {
  return (
    <Pressable onPress={() => props.onPress()} style={styles.loadMoreButton}>
      <Text style={[styles.loadMoreButtonText, commonStyles.standardFont]}>
        Visa mer...
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  loadMoreButton: {
    marginTop: 12,
    backgroundColor: "#f8f8f8",
    padding: 16,
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 8,
    marginBottom: 36,
  },
  loadMoreButtonText: {
    color: "#888683",
  },
});

export default LoadMoreButton;
