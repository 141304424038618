import React from "react";
import { View, Text } from "react-native";
import FeedOrganFiltering from "./feedorganfiltering";
import { useMediaQuery } from "react-responsive";
import commonStyles from "../../commonstyles";

export default function Field(props: { isDesktop: boolean; noText: boolean }) {
  const isMobile = useMediaQuery({
    maxWidth: 1200,
  });

  return (
    <View>
      {!isMobile && !props.noText && (
        <View>
          <Text
            style={[
              props.isDesktop
                ? { color: "#202020", fontSize: 20 }
                : { color: "#202020", fontSize: 16 },
              commonStyles.headlineFontBold,
            ]}
          >
            Samhällspolitiskt område
          </Text>
          <Text
            style={[
              {
                color: "#202020",
                marginTop: 6,
                marginBottom: 16,
                width: "100%",
              },
              props.isDesktop ? { fontSize: 14 } : { fontSize: 12 },
              commonStyles.standardFont,
            ]}
          >
            Ställ in vilka områden händelserna som visas ska tillhöra.
          </Text>
        </View>
      )}
      <FeedOrganFiltering />
    </View>
  );
}
