import React from "react";
import { StyleSheet, Pressable } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

function RoundedButton(props: PropTypes.object) {
  return (
    <Pressable
      onPress={props.onPress}
      style={[
        styles.mainContainer,
        props.post
          ? commonStyles.yellowBackground
          : commonStyles.lightBeigeBackground,
        commonStyles.smallRound,
      ]}
    >
      {props.children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    padding: 8,
  },
});

export default RoundedButton;
