import React from "react";
import { useMediaQuery } from "react-responsive";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Hoverable } from "react-native-web-hooks";
import commonStyles from "../../commonstyles";

const Content = () => {
  const navigation = useNavigation();
  const isTablet = useMediaQuery({
    maxWidth: 960,
    minWidth: 601,
  });

  return (
    <View style={styles.contentWrapper}>
      <View style={[commonStyles.contentSec]}>
        <View style={styles.textWrapper}>
          <Text style={[styles.textTitle]}>Anpassningsbart nyhetsflöde</Text>
          <Text style={[styles.textBody]}>
            Följ ett nyhetsflöde med offentliga handlingar från Riksdagen som
            uppdateras var tionde minut.{"\n"}
            Skapa ett konto för att spara händelser och skapa individuella
            bevakningar med notiser.{" "}
          </Text>
          <Hoverable>
            {(isHovered) => (
              <Pressable
                onPress={() => {
                  navigation.navigate("Feed");
                }}
                style={[styles.lpButton, isHovered ? styles.hovered : null]}
              >
                <Text
                  style={[
                    commonStyles.btnText,
                    isHovered ? styles.hovered : null,
                  ]}
                >
                  Gå till Nyhetsflödet
                </Text>
              </Pressable>
            )}
          </Hoverable>
        </View>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1, isTablet && { height: 250 }]}>
            <Image
              source={require("../../../public/images/news_feed_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
      </View>

      <View style={[commonStyles.contentSecWhite]}>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1, isTablet && { height: 250 }]}>
            <Image
              source={require("../../../public/images/statistics_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
        <View style={styles.textWrapper}>
          <Text style={[styles.textTitle]}>Användarvänlig statistik</Text>
          <Text style={[styles.textBody]}>
            Ta del av sammanställd statistik om Riksdagen som helhet, partierna
            såväl som individuella ledamöter.{"\n"} Because numbers don’t lie!
          </Text>
          <Hoverable>
            {(isHovered) => (
              <Pressable
                onPress={() => {
                  navigation.navigate("Statistik");
                }}
                style={[styles.lpButton, isHovered ? styles.hovered : null]}
              >
                <Text
                  style={[
                    commonStyles.btnText,
                    isHovered ? styles.hovered : null,
                  ]}
                >
                  Gå till Statistik
                </Text>
              </Pressable>
            )}
          </Hoverable>
        </View>
      </View>

      <View style={[commonStyles.contentSec]}>
        <View style={styles.textWrapper}>
          <Text style={[styles.textTitle]}>Politikerregister</Text>
          <Text style={[styles.textBody]}>
            Bläddra i politikerregistret och hitta statistik, offentliga
            handlingar och en personlig profil med offentlig information om
            varje ledamot i Riksdagen.
          </Text>
          <Hoverable>
            {(isHovered) => (
              <Pressable
                onPress={() => {
                  navigation.navigate("Statistik");
                }}
                style={[styles.lpButton, isHovered ? styles.hovered : null]}
              >
                <Text
                  style={[
                    commonStyles.btnText,
                    isHovered ? styles.hovered : null,
                  ]}
                >
                  Gå till Politikerregister
                </Text>
              </Pressable>
            )}
          </Hoverable>
        </View>
        <View style={[styles.imgWrapper]}>
          <View style={[styles.imgWrapper1, isTablet && { height: 250 }]}>
            <Image
              source={require("../../../public/images/politician_register_img.png")}
              style={[styles.img]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  contentWrapper: {
    justifyContent: "center",
    marginVertical: "1%",
  },
  textWrapper: {
    width: "50%",
    textAlign: "center",
    clear: "both",
    justifyContent: "center",
    alignSelf: "center",
    paddingHorizontal: "12%",
    alignItems: "center",
  },
  imgWrapper: {
    width: "50%",
    justifyContent: "flex-end",
    alignSelf: "center",
    backgroundColor: "#fffff",
  },
  imgWrapper1: {
    height: 350,
  },

  textTitle: {
    fontWeight: "700",
    fontSize: 32,
    lineHeight: 42,
    padding: 10,
  },
  textBody: {
    fontWeight: "400",
    fontSize: 20,
    lineHeight: 24,
  },
  textLinkTitle: {
    fontWeight: "600",
    fontSize: 20,
    lineHeight: 24,
    padding: "5%",
    marginTop: "3%",
  },
  textLink: {
    fontWeight: "600",
    fontSize: 20,
    lineHeight: 24,
    textDecorationLine: "underline",
  },
  lpButton: {
    borderRadius: 5,
    padding: 10,
    width: 200,
    height: 43,
    borderColor: "#2D2D2D",
    borderWidth: 1,
    justifyContent: "flex-end",
    lineHeight: 24,
    fontWeight: "400",
    marginTop: 20,
  },
  hovered: {
    backgroundColor: "#2D2D2D",
    color: "white",
    textShadowColor: "none",
  },
  img: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
    shadowColor: "#E4E0DB",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12,
    elevation: 10,
  },
});

export default Content;
