import { createSlice } from "@reduxjs/toolkit";
import { TimeRange } from "../helpers";

const initialState = {
  help: true,
  expandedSegment: "none",
  mobileExpanded: "none",
  filters: {
    filter: {
      "SKRIFTLIG FRÅGA": true,
      "KU ANMÄLAN": true,
      MOTION: true,
      IP: true,
      YTTRANDE: true,
    },
    subjects: [],
    search: "",
    politicianIds: [],
  },
  myFlowActive: false,
  filterSegmentState: {
    type: "Typ",
    presetsShowing: false,
  },
  loggedIn: false,
  checkedLoginStatus: {
    newLogin: false,
    oldLogin: false,
  },
  csrf: "",
  userData: {},
  politicianFilters: {
    from: { day: 1, month: 1, year: 2020 },
    to: { day: 1, month: 1, year: 2021 },
    parties: ["V", "S", "MP", "C", "L", "M", "KD", "SD"],
    age: { from: 0, to: 100 },
    gender: ["Man", "Kvinna"],
    search: "",
  },
  politicians: [],
  committees: [],
  showMobileFilters: false,
  timePeriod: "ALL",
  firstTimeFeedOrganFiltering: true,
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    setFirstTimeFeedOrganFilteringToFalse: (state) => {
      state.firstTimeFeedOrganFiltering = false
    },
    setTimePeriod: (state, action) => {
      state.timePeriod = action.payload;
    },
    toggleHelp: (state) => {
      state.help = !state.help;
    },
    setFeedSearch: (state, action) => {
      const newFilters = Object.assign({}, state.filters);
      newFilters.search = action.payload;
      state.filters = newFilters;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    resetPoliticianFilters: (state) => {
      state.politicianFilters = initialState.politicianFilters;
    },
    setFilters: (state, action) => {
      state.filters.filter[action.payload] =
        !state.filters.filter[action.payload];
    },
    setFilterSegmentStateType: (state, action) => {
      state.filterSegmentState.type = action.payload;
    },
    toggleMobileFilters: (state) => {
      state.showMobileFilters = !state.showMobileFilters;
    },
    togglePresetsShowing: (state, action) => {
      state.filterSegmentState.presetsShowing =
        !state.filterSegmentState.presetsShowing;
    },
    setSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    logOut: (state) => {
      state.userData = {};
    },
    setCSRF: (state, action) => {
      state.csrf = action.payload;
    },
    setParty: (state, action) => {
      state.userData = { ...state.userData, party: action.payload };
    },
    setCheckedStatus: (state, action) => {
      state.checkedLoginStatus[action.payload] = true;
    },
    setFilterParties: (state, action) => {
      const newFilters = Object.assign({}, state.politicianFilters);
      if (newFilters.parties.length === 8) {
        newFilters.parties = [action.payload];
      } else if (
        newFilters.parties.length === 1 &&
        newFilters.parties.indexOf(action.payload) !== -1
      ) {
        newFilters.parties = ["V", "S", "MP", "C", "L", "M", "KD", "SD"];
      } else if (newFilters.parties.indexOf(action.payload) !== -1) {
        newFilters.parties.splice(
          newFilters.parties.indexOf(action.payload),
          1
        );
      } else {
        newFilters.parties.push(action.payload);
      }
      state.politicianFilters = newFilters;
    },
    setFilterPartiesCoverage: (state, action) => {
      if (state.userData.filterPresets && state.userData.filterPresets.party) {
        var parties = JSON.parse(state.userData.filterPresets.party);
      } else {
        var parties: any = [];
      }

      if (parties.lenght === 0 || parties.length === 8) {
        parties = [action.payload];
      } else if (parties.indexOf(action.payload) !== -1) {
        parties.splice(parties.indexOf(action.payload), 1);
      } else {
        parties.push(action.payload);
      }

      state.userData = {
        ...state.userData,
        filterPresets: {
          ...state.userData.filterPresets,
          party: JSON.stringify(parties),
        },
      };
      console.log(state.userData.filterPresets);
    },
    setFilterDate: (state, action) => {
      const newFilters = Object.assign({}, state.politicianFilters);
      newFilters[action.payload.type][action.payload.dayMonthYear] =
        action.payload.value;
      state.politicianFilters = newFilters;
    },
    setFilterAge: (state, action) => {
      const newFilters = Object.assign({}, state.politicianFilters);
      newFilters.age[action.payload.which] = action.payload.value;
      state.politicianFilters = newFilters;
    },
    setFilterGender: (state, action) => {
      const newFilters = Object.assign({}, state.politicianFilters);
      if (newFilters.gender.indexOf(action.payload) !== -1) {
        newFilters.gender.splice(newFilters.gender.indexOf(action.payload), 1);
      } else {
        newFilters.gender.push(action.payload);
      }
      state.politicianFilters = newFilters;
    },
    setPoliticianFilterSearch: (state, action) => {
      const newFilters = Object.assign({}, state.politicianFilters);
      newFilters.search = action.payload;
      state.politicianFilters = newFilters;
    },
    setPoliticians: (state, action) => {
      state.politicians = action.payload;
    },
    appendPoliticians: (state, action) => {
      state.politicians = [...state.politicians].concat(action.payload);
    },
    setExpandedSegment: (state, action) => {
      if (state.expandedSegment === action.payload) {
        state.expandedSegment = "none";
      } else {
        state.expandedSegment = action.payload;
      }
    },
    setMobileExpanded: (state, action) => {
      state.mobileExpanded === action.payload
        ? (state.mobileExpanded = "none")
        : (state.mobileExpanded = action.payload);
      state.expandedSegment = "none";
    },
    setCommittees: (state, action) => {
      state.committees = action.payload
        .filter((obj) => obj.department !== null)
        .map(({ committee, title, id }) => ({
          id,
          committee,
          fullTitle: title,
          title: title.length > 40 ? title.substring(0, 40) + "..." : title,
          selected: true,
        }));
      state.filters.subjects = action.payload
        .filter((obj) => obj.department !== null)
        .map(({ id }) => id)

    },
    selectOneCommittee: (state, action) => {
      const newCommittees = state.committees.map((comm) => ({
        ...comm,
        selected: action.payload === comm.committee,
      }));
      state.committees = newCommittees;
    },
    selectAllCommittees: (state) => {
      const newCommittees = state.committees.map((comm) => ({
        ...comm,
        selected: true,
      }));
      state.committees = newCommittees;
    },
    setNewPreset: (state, action) => {
      state.userData = {
        ...state.userData,
        filterPresets: [...state.userData.filterPresets, action.payload],
      };
    },
    activatePreset: (state, action) => {
      // Temporary fix.
      return;
      state.filters = {
        filter: JSON.parse(action.payload.eventType),
        subjects: JSON.parse(action.payload.subject),
        search: action.payload.freeText,
      };
    },
    removePreset: (state, action) => {
      const newPresets = state.userData.filterPresets.filter(
        (preset) => preset.id !== action.payload
      );
      state.userData = { ...state.userData, filterPresets: newPresets };
    },
    toggleFeedFilterCommittee: (state, action) => {
      const index = state.filters.subjects.indexOf(action.payload)
      if (index !== -1) {
        state.filters.subjects.splice(index, 1)
      } else {
        state.filters.subjects.push(action.payload)
      }
    },
    addFeedFilterCommittee: (state, action) => {
      const index = state.filters.subjects.indexOf(action.payload)
      if (index === -1) {
        state.filters.subjects.push(action.payload)
      }
    },
    addFeedFilterCommitteeCoverage: (state, action) => {
      const subjects = JSON.parse(state.userData.filterPresets.subject) || [];
      const index = subjects.indexOf(action.payload)
      if (index === -1) {
        subjects.push(action.payload)
        state.userData = {
          ...state.userData,
          filterPresets: {
            ...state.userData.filterPresets,
            subject: JSON.stringify(subjects),
          },
        };
      }
    },
    removeFeedFilterCommittee: (state, action) => {
      const index = state.filters.subjects.indexOf(action.payload)
      if (index !== -1) {
        state.filters.subjects.splice(index, 1)
      }
    },
    removeFeedFilterCommitteeCoverage: (state, action) => {
      const subjects = JSON.parse(state.userData.filterPresets.subject) || [];
      const index = subjects.indexOf(action.payload)
      if (index !== -1) {
        subjects.splice(index, 1)
        state.userData = {
          ...state.userData,
          filterPresets: {
            ...state.userData.filterPresets,
            subject: JSON.stringify(subjects),
          },
        };
      }
    },
    toggleFeedFilterCommitteeCoverage: (state, action) => {
      const subjects = JSON.parse(state.userData.filterPresets.subject) || [];

      const index = subjects.indexOf(action.payload)
      console.log(subjects)
      console.log(index)
      if (index !== -1) {
        subjects.splice(index, 1)
        console.log(subjects)
        state.userData = {
          ...state.userData,
          filterPresets: {
            ...state.userData.filterPresets,
            subject: JSON.stringify(subjects),
          },
        }
      } else {
        subjects.push(action.payload)
        state.userData = {
          ...state.userData,
          filterPresets: {
            ...state.userData.filterPresets,
            subject: JSON.stringify(subjects),
          },
        }
      }
    },
    selectAllFeedCommittees: (state) => {
      const allCommittees = state.committees.map((committee) => committee.id);
      state.filters.subjects = allCommittees;
    },
    selectAllFeedCommitteesCoverage: (state) => {
      const subjects = state.committees.map((committee) => committee.id);
      state.userData = {
        ...state.userData,
        filterPresets: {
          ...state.userData.filterPresets,
          subject: JSON.stringify(subjects),
        },
      };
      console.log(state.userData.filterPresets);
    },
    setName: (state, action) => {
      state.userData = {
        ...state.userData,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
      };
    },
    setHeadline: (state, action) => {
      state.userData = { ...state.userData, headline: action.payload };
    },
    setPoliticianIds: (state, action) => {
      const politicianIds = state.filters.politicianIds;
      if (politicianIds.map((p) => p.id).includes(action.payload.id)) {
        const index = politicianIds.map((p) => p.id).indexOf(action.payload.id);
        politicianIds.splice(index, 1);
      } else politicianIds.push(action.payload);
      state.filters = { ...state.filters, politicianIds: politicianIds };
    },
    setPoliticianIdsCoverage: (state, action) => {
      if (
        state.userData.filterPresets &&
        state.userData.filterPresets.politician
      ) {
        var politicians = JSON.parse(state.userData.filterPresets.politician);
      } else {
        var politicians: any = [];
      }
      politicians.map((p) => p.id).includes(action.payload.id)
        ? politicians.splice(
          politicians.map((p) => p.id).indexOf(action.payload.id),
          politicians.map((p) => p.id).indexOf(action.payload.id) + 1
        )
        : politicians.push(action.payload);
      state.userData = {
        ...state.userData,
        filterPresets: {
          ...state.userData.filterPresets,
          politician: JSON.stringify(politicians),
        },
      };
      console.log(state.userData.filterPresets);
    },
    toggleMyFlow: (state) => {
      state.myFlowActive = !state.myFlowActive;
    },
    activateMyFlow: (state) => {
      state.myFlowActive = true;
    },
    setCoverage: (state, action) => {
      state.userData = { ...state.userData, filterPresets: action.payload };
    },
    newPolitician: (state, action) => {
      state.userData = {
        ...state.userData,
        filterPresets: {
          ...state.userData.filterPresets,
          politician: action.payload,
        },
      };
    },
    updateFilterPresets: (state, action) => {
      state.userData = {
        ...state.userData,
        filterPresets: {
          ...state.userData.filterPresets,
          subject: action.payload.subject,
          party: action.payload.party,
          politician: action.payload.politician,
        },
      };
    },
    userEventCountChange: (state, action) => {
      if (state.userData.userEventCount !== null) {
        state.userData = {
          ...state.userData,
          userEventCount:
            parseInt(state.userData.userEventCount) + action.payload,
        };
      } else {
        state.userData = { ...state.userData, userEventCount: 1 };
      }
    },
    toggleSubscribes: (state) => {
      state.userData = {
        ...state.userData,
        notices: !state.userData.notices,
      };
    },
  },
});

export const {
  setFirstTimeFeedOrganFilteringToFalse,
  toggleHelp,
  setFeedSearch,
  setFilters,
  setFilterSegmentStateType,
  togglePresetsShowing,
  setSort,
  setUserData,
  logOut,
  setCSRF,
  setParty,
  setCheckedStatus,
  setFilterParties,
  setFilterPartiesCoverage,
  setFilterDate,
  setFilterAge,
  setFilterGender,
  setPoliticianFilterSearch,
  setPoliticians,
  appendPoliticians,
  setExpandedSegment,
  setMobileExpanded,
  setCommittees,
  selectOneCommittee,
  selectAllCommittees,
  setNewPreset,
  activatePreset,
  removePreset,
  toggleFeedFilterCommittee,
  toggleFeedFilterCommitteeCoverage,
  addFeedFilterCommittee,
  addFeedFilterCommitteeCoverage,
  removeFeedFilterCommittee,
  removeFeedFilterCommitteeCoverage,
  selectAllFeedCommittees,
  selectAllFeedCommitteesCoverage,
  setName,
  setHeadline,
  setPoliticianIds,
  setPoliticianIdsCoverage,
  toggleMyFlow,
  activateMyFlow,
  setCoverage,
  newPolitician,
  resetFilters,
  resetPoliticianFilters,
  updateFilterPresets,
  userEventCountChange,
  toggleSubscribes,
  toggleMobileFilters,
  setTimePeriod,
} = dataSlice.actions;

export default dataSlice.reducer;
