import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import BackButton from "../backbutton";
import { useRoute } from "@react-navigation/native";

interface IBack {
  navigation: any;
  pageName?: string;
}

export default function Back({ navigation, pageName }: IBack) {
  const route = useRoute();
  return (
    <View style={styles.imageWrapper}>
      <BackButton navigation={navigation} />
      <Pressable onPress={() => navigation.navigate("LandingPage", {})}>
        <Text style={styles.title}>startsida | </Text>
      </Pressable>
      <Text
        style={{
          textDecorationLine: "underline",
          fontSize: 18,
          textTransform: "uppercase",
        }}
      >
        {pageName ? pageName : route.name}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  startPage: {
    display: "flex",
    fontSize: 18,
    padding: 6,
    fontWeight: "100",
    marginLeft: 15,
  },
  imageWrapper: {
    flexDirection: "row",
    alignItems: "center",
    width: "97vw",
    marginLeft: 15,
    margin: "auto",
  },
  title: {
    fontSize: 18,
    marginLeft: 20,
    textTransform: "uppercase",
  },
});
