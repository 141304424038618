import React from "react";
import { View, Text, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";

export default function BigPartyBadge(props: PropTypes.object) {
  return (
    <View
      style={[
        styles.mainContainer,
        styles[props.party],
        props.noMargin && { marginLeft: 0 },
      ]}
    >
      <Text style={[styles.text, commonStyles.headlineFont]}>
        {props.party !== "N" ? props.party : "?"}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: 46,
    height: 46,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  text: {
    color: "white",
    fontSize: 20,
    fontWeight: "700",
  },
  V: {
    backgroundColor: "#B00000",
  },
  S: {
    backgroundColor: "#ed1b34",
  },
  MP: {
    backgroundColor: "#83CF39",
  },
  C: {
    backgroundColor: "#009933",
  },
  L: {
    backgroundColor: "#6BB7EC",
  },
  M: {
    backgroundColor: "#1B49DD",
  },
  KD: {
    backgroundColor: "#231977",
  },
  SD: {
    backgroundColor: "#DDDD00",
  },
  R: {
    backgroundColor: "#E4E0DB",
  },
  N: {
    backgroundColor: "#e8e8e8",
  },
});
