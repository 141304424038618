import React, { useState } from "react";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import { baseURLForServer } from "../config";
import { Hoverable } from "react-native-web-hooks";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { reactToComment, removeComment } from "../slices/feedandeventslice";
import RespondBox from "./event/respondbox";
import commonStyles from "./commonstyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

function ShareReact(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);

  const [respondMode, setRespondMode] = useState(false);

  const dispatch = useDispatch();

  const getCurrentReaction = () => {
    if (props.comment.newUserReaction !== undefined) {
      if (props.comment.newUserReaction.reaction === undefined) {
        return "none";
      }
      return [
        props.comment.newUserReaction.reaction,
        props.comment.newUserReaction.id,
      ];
    } else {
      if (props.comment.userReaction.reaction === undefined) {
        return "none";
      }
      return [
        props.comment.userReaction.reaction,
        props.comment.userReaction.id,
      ];
    }
  };

  const getCurrentCount = (reaction: string) => {
    let addToCount = 0;
    if (
      props.comment.userReaction.reaction !== reaction &&
      props.comment.newUserReaction !== undefined &&
      props.comment.newUserReaction.reaction === reaction
    ) {
      addToCount = 1;
    }
    if (
      props.comment.userReaction.reaction === reaction &&
      props.comment.newUserReaction !== undefined &&
      props.comment.newUserReaction.reaction !== reaction
    ) {
      addToCount = -1;
    }
    if (reaction === "like") {
      return props.comment.likes + addToCount;
    } else {
      return props.comment.dislikes + addToCount;
    }
  };

  const react = (reaction: string) => {
    if (getCurrentReaction() !== "none") {
      if (getCurrentReaction()[0] === reaction) {
        // Remove.
        fetch(baseURLForServer + "/reactions/" + getCurrentReaction()[1], {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userData.jwt,
          },
        })
          .then((_response) =>
            dispatch(
              reactToComment({
                commentId: props.comment.id,
                newUserReaction: {},
              })
            )
          )
          .catch((err) => console.log(err));
      } else {
        // Update.
        fetch(baseURLForServer + "/reactions/" + getCurrentReaction()[1], {
          method: "PUT",
          body: JSON.stringify({
            reaction: reaction,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userData.jwt,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            dispatch(
              reactToComment({
                commentId: props.comment.id,
                newUserReaction: json,
              })
            );
          })
          .catch((err) => console.log(err));
      }
    } else {
      // Create.
      fetch(baseURLForServer + "/reactions/", {
        method: "POST",
        body: JSON.stringify({
          commentId: props.comment.id,
          userId: userData.id,
          reaction: reaction,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userData.jwt,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(
            reactToComment({
              commentId: props.comment.id,
              newUserReaction: json,
            })
          );
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteComment = (commentId: string) => {
    fetch(baseURLForServer + "/comments/" + commentId, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
    })
      .then((_res) => dispatch(removeComment(commentId)))
      .catch((error) => console.log(error));
  };

  return (
    <View style={{ width: "100%", alignSelf: "flex-end" }}>
      {!respondMode ? (
        <View style={styles.mainContentContainer}>
          <View style={styles.mainContainer}>
            {props.showRespondButton && Object.keys(userData).length !== 0 && (
              <Pressable
                style={[styles.respond, commonStyles.smallShadow]}
                onPress={() => setRespondMode(!respondMode)}
              >
                <Text style={commonStyles.standardFont}>Svara</Text>
              </Pressable>
            )}
            <View
              style={[
                commonStyles.smallShadow,
                { flexDirection: "row", borderRadius: 4 },
              ]}
            >
              <Pressable
                style={
                  Object.keys(userData).length === 0 && { cursor: "normal" }
                }
                onPress={() => {
                  Object.keys(userData).length !== 0 && react("like");
                }}
              >
                <View
                  style={[
                    styles.thumbsUp,
                    getCurrentReaction()[0] === "like" &&
                      commonStyles.lightBeigeBackground,
                  ]}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 20, lineHeight: 20, color: "#202020" }}
                    width={20}
                    height={20}
                    icon={regular("thumbs-up")}
                  />
                  <Text
                    style={[
                      styles.count,
                      commonStyles.standardFont,
                      getCurrentReaction()[0] === "like" && {
                        color: "#888683",
                      },
                    ]}
                  >
                    {getCurrentCount("like")}
                  </Text>
                </View>
              </Pressable>
              <View
                style={{ height: 40, width: 1, backgroundColor: "#e8e8e8" }}
              />
              <Pressable
                style={
                  Object.keys(userData).length === 0 && { cursor: "normal" }
                }
                onPress={() => {
                  Object.keys(userData).length !== 0 && react("dislike");
                }}
              >
                <View
                  style={[
                    styles.thumbsDown,
                    getCurrentReaction()[0] === "dislike" &&
                      commonStyles.lightBeigeBackground,
                  ]}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 20, lineHeight: 20, color: "#202020" }}
                    width={20}
                    height={20}
                    icon={regular("thumbs-down")}
                  />
                  <Text
                    style={[
                      styles.count,
                      commonStyles.standardFont,
                      getCurrentReaction()[0] === "dislike" && {
                        color: "#888683",
                      },
                    ]}
                  >
                    {getCurrentCount("dislike")}
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
          {Object.keys(userData).length !== 0 &&
            (userData.username === props.comment.user.username ||
              userData.isadmin) &&
            props.showDelete && (
              <Hoverable>
                {(isHovered) => (
                  <Pressable onPress={() => deleteComment(props.comment.id)}>
                    <Text
                      style={[
                        styles.deleteText,
                        commonStyles.standardFont,
                        isHovered && { textDecorationLine: "underline" },
                      ]}
                    >
                      Ta bort kommentar
                    </Text>
                  </Pressable>
                )}
              </Hoverable>
            )}
        </View>
      ) : (
        <RespondBox
          autoFocus={true}
          onCancel={() => setRespondMode(!respondMode)}
          onPost={() => setRespondMode(false)}
          inResponseTo={props.comment.id}
          eventId={props.comment.eventId}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContentContainer: {
    alignSelf: "flex-end",
  },
  mainContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  deleteText: {
    color: "#4a4a4a",
    cursor: "pointer",
    marginTop: 12,
    alignSelf: "flex-end",
  },
  respond: {
    backgroundColor: "white",
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    marginRight: 12,
    height: 40,
  },
  share: {
    backgroundColor: "white",
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    marginRight: 12,
    height: 40,
  },
  thumbsUp: {
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderRightWidth: 0,
    // width: 72,
    height: 40,
    padding: 16,
  },
  thumbsDown: {
    backgroundColor: "white",
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderLeftWidth: 0,
    // width: 71,
    height: 40,
  },
  count: {
    textAlign: "right",
    width: 16,
    fontSize: 12,
    // marginLeft: 6,
    color: "#4a4a4a",
  },
  respondContainer: {
    width: "100%",
  },
});

export default ShareReact;
