import React, { useState } from "react";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";
import { useMediaQuery } from "react-responsive";
import "chart.js/auto";
import MissionBox from "./missionbox";
import InfoBadge from "../politicianregistry/infobadge";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import FollowButton from "../feed/followbutton";

export default function Politician(props: PropTypes.object) {
  const [showAllInfo, setShowAllInfo] = useState(false);

  const isDesktop = useMediaQuery({
    minWidth: 1000 + 48,
  });

  return (
    <View>
      {props.info && (
        <View
          style={
            isDesktop ? { flexDirection: "row" } : { flexDirection: "column" }
          }
        >
          <View
            style={[
              styles.pictureShadow,
              commonStyles.bigShadow,
              commonStyles.smallRound,
              !isDesktop && { alignSelf: "center" },
            ]}
          >
            <Image
              source={props.info.imageUrl_192}
              style={[styles.picture, commonStyles.smallRound]}
            />
          </View>
          <View style={{ flex: 1 }}>
            <View
              style={
                isDesktop
                  ? { flexDirection: "row", alignItems: "center" }
                  : { flexDirection: "column", alignItems: "center", gap: 6 }
              }
            >
              <Text
                style={[
                  styles.nameText,
                  !isDesktop && {
                    alignSelf: "center",
                    marginLeft: 0,
                    marginTop: 12,
                  },
                  commonStyles.headlineBold,
                  isDesktop && { marginRight: 12 },
                ]}
              >
                {props.info.firstName + " " + props.info.familyName}
              </Text>
              <FollowButton
                politician={{
                  id: props.info.id,
                  firstName: props.info.firstName,
                  familyName: props.info.familyName,
                  party: props.info.party,
                }}
              />
            </View>
            <View
              style={[
                {
                  marginLeft: 16,
                  marginTop: 12,
                  alignSelf: "flex-start",
                  flexDirection: "row",
                  gap: 8,
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                },
                !isDesktop && { alignSelf: "center", marginLeft: 0 },
              ]}
            >
              <InfoBadge text={"Född " + props.info.birthYear} />
              {props.info.isCurrentlyActive && <InfoBadge text="Sittande" />}
              {props.info.constituency && (
                <InfoBadge text={"Valkrets " + props.info.constituency} />
              )}
            </View>
            <View
              style={[
                !showAllInfo && {
                  height: 120,
                  overflow: "hidden",
                },
              ]}
            >
              <MissionBox
                missions={props.info.politicalAssignment}
                personal={props.info.personalData}
                isDesktop={isDesktop}
              />
            </View>
          </View>
        </View>
      )}
      <Pressable
        style={[
          styles.expandInfoButton,
          commonStyles.smallRound,
          commonStyles.lightBeigeBackground,
        ]}
        onPress={() => setShowAllInfo(!showAllInfo)}
      >
        <Text style={[{ color: "#888683" }, commonStyles.headlineFont]}>
          {!showAllInfo ? "Visa all information" : "Dölj information"}
        </Text>
        <Image
          source={require("../../public/images/soft_arrow_beige.svg")}
          style={[
            styles.arrow,
            showAllInfo
              ? { transform: [{ rotateZ: "-90deg" }] }
              : { transform: [{ rotateZ: "90deg" }] },
          ]}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContent: {
    width: 1000,
    alignItems: "center",
    alignSelf: "center",
  },
  mainContentSmall: {
    width: "100%",
    paddingHorizontal: 24,
    alignItems: "center",
  },
  pictureShadow: {
    alignSelf: "flex-start",
  },
  picture: {
    width: 192 / 2,
    height: 256 / 2,
  },
  nameText: {
    marginLeft: 16,
    color: "#202020",
  },
  expandInfoButton: {
    marginTop: 16,
    width: "100%",
    height: 42,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
    marginLeft: 16,
  },
});
