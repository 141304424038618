import React from "react";
import { StyleSheet, View, Pressable, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterParties,
  setFilterPartiesCoverage,
} from "../../slices/dataslice";
import commonStyles from "../commonstyles";

function Party(props: { party: string; coverageMode: boolean }) {
  if (props.coverageMode) {
    var coverage = useSelector((state) => state.data.userData.filterPresets);
  } else {
    var parties = useSelector((state) => state.data.politicianFilters.parties);
  }
  const dispatch = useDispatch();

  return (
    <Pressable
      style={[
        styles.selection,
        !props.coverageMode &&
          parties.indexOf(props.party) >= 0 && {
            backgroundColor: "#fcfcfc",
          },
        props.coverageMode &&
          (coverage === null ||
            !coverage.party ||
            JSON.parse(coverage.party).indexOf(props.party) >= 0) && {
            backgroundColor: "#fcfcfc",
          },
      ]}
      onPress={() => {
        !props.coverageMode
          ? dispatch(setFilterParties(props.party))
          : dispatch(setFilterPartiesCoverage(props.party));
      }}
    >
      <Text style={[styles.partyText, commonStyles.headlineFont]}>
        {props.party}
      </Text>
    </Pressable>
  );
}

export default function PartyChooser(props) {
  return (
    <View style={styles.container}>
      <View style={styles.styleContainer}>
        <View style={styles.fourBox}>
          <Party {...props} party="V" />
          <Party {...props} party="S" />
          <Party {...props} party="MP" />
          <Party {...props} party="C" />
        </View>
        <View style={styles.fourBox}>
          <Party {...props} party="L" />
          <Party {...props} party="M" />
          <Party {...props} party="KD" />
          <Party {...props} party="SD" />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  styleContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: 82,
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    elevation: 2,
    padding: 6,
    borderRadius: 6,
  },
  fourBox: {
    width: 202,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selection: {
    width: 46,
    borderRadius: 4,
    height: 32,
    backgroundColor: "#e8e8e8",
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  partyText: {
    color: "#a0a0a0",
  },
});
