import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";

const Maintenance = () => {
  return (
    <View style={styles.mainView}>
      <Image
        source={require("../public/images/n.png")}
        style={styles.logoImage}
      />
      <Text style={styles.h1}>
        <br />
        Noterat.io is down for maintenance
        <br />
        Please come back later
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    textAlign: "center",
  },
  logoImage: {
    width: 54,
    height: 58,
    alignSelf: "center",
  },
  h1: {
    fontSize: 18,
  },
});

export default Maintenance;
