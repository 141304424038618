import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { baseURLForServer } from "../../config";
import Card from "../feed/card";
import commonStyles from "../commonstyles";

export default function Saved(props: PropTypes.object) {
  const [saved, setSaved] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // Update of user data triggers update of saved events...
  const userData = useSelector((state) => state.data.userData);

  useEffect(() => {
    fetch(baseURLForServer + "/users/" + userData.id + "/events", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setSaved(json.userEvents);
        setLoaded(true);
      })
      .catch((error) => {
        setLoaded(true);
        setSaved([]);
        console.log(error);
      });
  }, [userData]);

  return (
    <View style={styles.container}>
      {!loaded && (
        <Text style={[commonStyles.standardFont, { color: "#a4a4a4" }]}>
          Laddar...
        </Text>
      )}
      {loaded && saved.length === 0 && (
        <Text style={[commonStyles.standardFont, { color: "#a4a4a4" }]}>
          Inga sparade händelser
        </Text>
      )}
      {saved &&
        saved.map((event, i) => (
          <View
            key={i}
            style={[
              i !== saved.length - 1 && { marginBottom: 8 },
              { width: "100%" },
            ]}
          >
            <Card key={i} noMargin {...event} userEvent={event.user_event} />
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
  },
});
