import React from "react";
import { StyleSheet, View, Image, Text, Button } from "react-native";
import { Link } from "@react-navigation/native";
import PartyBadge from "../partybadge";
import InfoBadge from "./infobadge";
import ProfilePicture from "../profilepicture";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import FollowButton from "../feed/followbutton";

function PoliticianCard(props: PropTypes.object) {
  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });

  return (
    <View
      style={[
        styles.mainContainer,
        commonStyles.bigShadow,
        commonStyles.bigRound,
        isDesktop ? { padding: 16 } : { padding: 12 },
      ]}
    >
      <View style={!isDesktop && { width: "100%" }}>
        <View
          style={
            isDesktop
              ? { flexDirection: "row", alignItems: "center" }
              : { flexDirection: "column", alignItems: "center", gap: 6 }
          }
        >
          <Link to={"/politician/" + props.id}>
            <View
              style={
                isDesktop
                  ? { flexDirection: "row", alignItems: "center" }
                  : { flexDirection: "column", alignItems: "center", gap: 6 }
              }
            >
              <ProfilePicture
                firstName={props.firstName}
                lastName={props.familyName}
                size={48}
              />
              <Text
                style={[
                  isDesktop && { marginLeft: 12 },
                  styles.name,
                  commonStyles.headlineFont,
                ]}
              >
                {props.firstName + " " + props.familyName}
              </Text>
              {isDesktop && (
                <View
                  style={{
                    width: 1,
                    height: 16,
                    backgroundColor: "#E4E0DB",
                    marginLeft: 8,
                    marginRight: 4,
                  }}
                />
              )}
              <PartyBadge party={props.party} noMargin={!isDesktop} />
            </View>
          </Link>
          <View style={isDesktop && { marginLeft: 12 }}>
            <FollowButton
              politician={{
                id: props.id,
                firstName: props.firstName,
                familyName: props.familyName,
                party: props.party,
              }}
            />
          </View>
        </View>
        {isDesktop && (
          <View style={styles.badgeBox}>
            <InfoBadge text={"Född " + props.birthYear} />
            {props.isCurrentlyActive && <InfoBadge text="Sittande" />}
            {props.constituency && (
              <InfoBadge text={"Valkrets " + props.constituency} />
            )}
          </View>
        )}
      </View>
      {isDesktop && (
        <Link
          to={"/politician/" + props.id}
          style={[styles.arrowContainer, commonStyles.yellowBackground]}
        >
          <View style={{ justifyContent: "center", height: "100%" }}>
            <Image
              source={require("../../public/images/soft_arrow_black.svg")}
              style={[styles.arrow, commonStyles.yellowBackground]}
            />
          </View>
        </Link>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12,
    width: "100%",
  },
  picture: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: "#eeeeee",
  },
  name: {
    color: "#202020",
    fontSize: 16,
    marginRight: 4,
  },
  badgeBox: {
    marginTop: 16,
    flexDirection: "row",
    columnGap: 6,
  },
  arrowContainer: {
    backgroundColor: "rgba(229, 237, 255, 0.25)",
    paddingHorizontal: 16,
    borderRadius: 6,
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 0.8,
  },
  followButton: {
    marginTop: 16,
    flexDirection: "row",
    columnGap: 6,
  },
});

export default PoliticianCard;
