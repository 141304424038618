import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Feed from "./components/feed/feed";
import Event from "./components/event/event";
import Profile from "./components/profile/profile";
import Statisticspage from "./components/statistics/statisticspage";
import Politician from "./components/politician/politician";
import { useDispatch } from "react-redux";
import {
  setUserData,
  setCommittees,
  setName,
  logOut,
} from "./slices/dataslice";
import { baseURLForServer } from "./config";
import { useFonts } from "expo-font";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./src/aws-exports";
import { Home } from "./components/home/home";
import { LandingPage } from "./components/home/landingpage/landingpage";
import { Categories } from "./components/personalization/categories";
import Parties from "./components/personalization/parties";
import MonitoringList from "./components/personalization/monitoringList";
import { useNavigation } from "@react-navigation/native";
import FAQ from "./components/footer/faq/faq";
import ContactPage from "./components/footer/contact/contactpage";
import Användarvillkor from "./components/pages/anvandarvillkor";
import Redirect from "./components/redirectPage";
import OmOss from "./components/pages/omOss";
import Integritetspolicy from "./components/pages/integritetspolicy";
import Maintenance from "./components/maintenance";
import Politicianregistry from "./components/politicianregistry/politicianregistrypage";
Amplify.configure(awsconfig);

const Stack = createNativeStackNavigator();

export default function All() {
  useFonts({
    // const [fontsLoaded] = ...
    Poppins_500Medium: require("./assets/fonts/Poppins/Poppins-Medium.ttf"),
    Poppins_Bold: require("./assets/fonts/Poppins/Poppins-Bold.ttf"),
    museo_sans: require("./assets/fonts/museo_sans_ssv/Museo Sans 300/MuseoSans-300.otf"),
    museo_sans500: require("./assets/fonts/museo_sans_ssv/Museo Sans 500/MuseoSans_500.otf"),
    museo_sans700: require("./assets/fonts/museo_sans_ssv/Museo Sans 700/MuseoSans_700.otf"),
  });

  const dispatch = useDispatch();

  const navigation = useNavigation();

  const getUserInfo = (
    jwt: string,
    firstName: string,
    lastName: string,
    email: string
  ) => {
    let shouldRedirectToPreference = false;

    fetch(baseURLForServer + "/users/current", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${jwt}`,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          shouldRedirectToPreference = true;
        }
        return res;
      })
      .then((response) => response.json())
      .then((json) => {
        // console.log(jwt)
        dispatch(setUserData({ ...json, jwt: jwt }));
        // Set the name and email...
        fetch(baseURLForServer + "/users/" + json.id, {
          method: "PUT",
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            email: email,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${jwt}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            dispatch(
              setName({ firstname: json.firstName, lastname: json.lastName })
            );
            if (shouldRedirectToPreference) {
              navigation.navigate("Kategorier", {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(baseURLForServer + "/eventsubjects", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(setCommittees(json));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          Auth.currentAuthenticatedUser().then((currentUser) => {
            console.log(currentUser.attributes);
            getUserInfo(
              data.signInUserSession.accessToken.jwtToken,
              currentUser.attributes?.given_name ?? data.attributes.email,
              currentUser.attributes?.family_name ?? "",
              currentUser.attributes.email
            );
          });
          navigation.navigate(localStorage.getItem("currentURL"), {});
          break;
        case "cognitoHostedUI":
          getUser();
          break;
        case "signOut":
          dispatch(logOut());
          break;
        case "customOAuthState":
          break;
      }
    });
    getUser();
  }, []);

  const getUser = () => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        dispatch(
          setName({
            firstname: currentUser.attributes.given_name,
            lastname: currentUser.attributes.family_name,
          })
        );
        getUserInfo(
          currentUser.signInUserSession.accessToken.jwtToken,
          currentUser.attributes.given_name,
          currentUser.attributes.family_name,
          currentUser.attributes.email
        );
      })
      .catch(() => console.log("Not signed in"));
  };

  return (
    <Stack.Navigator
      initialRouteName="LandingPage"
      screenOptions={{
        headerShown: false,
        contentStyle: { backgroundColor: "white" },
      }}
    >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="LandingPage" component={LandingPage} />
      <Stack.Screen name="Kategorier" component={Categories} />
      <Stack.Screen name="Politiker" component={MonitoringList} />
      <Stack.Screen name="Partier" component={Parties} />
      <Stack.Screen name="Feed" component={Feed} />
      <Stack.Screen name="Event" component={Event} />
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="Statistik" component={Statisticspage} />
      <Stack.Screen name="Politician" component={Politician} />
      <Stack.Screen name="FAQ" component={FAQ} />
      <Stack.Screen name="Contact" component={ContactPage} />
      <Stack.Screen name="OmOss" component={OmOss} />
      <Stack.Screen name="Användarvillkor" component={Användarvillkor} />
      <Stack.Screen name="Integritetspolicy" component={Integritetspolicy} />
      <Stack.Screen name="Redirect" component={Redirect} />
      <Stack.Screen name="Maintenance" component={Maintenance} />
      <Stack.Screen name="Politikerregister" component={Politicianregistry} />
    </Stack.Navigator>
  );
}
