import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOneCommittee,
  selectAllCommittees,
} from "../../slices/dataslice";
import commonStyles from "../commonstyles";
import { useMediaQuery } from "react-responsive";

export default function OrganFiltering(props: PropTypes.object) {
  const organs = useSelector((state) => state.data.committees);

  const dispatch = useDispatch();

  const isSmall = useMediaQuery({
    maxWidth: 375
  });

  return (
    <View style={styles.mainContainer}>
      <Pressable
        style={[
          styles.organ,
          commonStyles.smallShadow,
          commonStyles.smallRound,
          { width: "100%", backgroundColor: "#F9F8F7" },
        ]}
        onPress={() => dispatch(selectAllCommittees())}
      >
        <Text style={[{ color: "#888683" }, commonStyles.standardFont]}>
          Välj alla ämnen
        </Text>
      </Pressable>
      {organs.map((organ, i) => (
        <Pressable
          key={i}
          style={[
            styles.organ,
            commonStyles.smallShadow,
            commonStyles.smallRound,
            organ.selected && { backgroundColor: "#F9F8F7" },
          ]}
          onPress={() => dispatch(selectOneCommittee(organ.committee))}
        >
          <Text style={[
            { color: "#888683" },
            isSmall && (organ.title.length > 38) && { fontSize: 12 },
            commonStyles.standardFont
          ]}>
            {organ.title}
          </Text>
        </Pressable>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
    paddingBottom: 8,
  },
  organ: {
    flexGrow: 1,
    borderWidth: 1,
    borderColor: "#E4E0DB",
    padding: 8,
    height: 32,
    backgroundColor: "#E4E0DB",
    alignItems: "center",
    justifyContent: "center",
  },
});
