import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleFeedFilterCommittee,
  toggleFeedFilterCommitteeCoverage,
  addFeedFilterCommittee,
  removeFeedFilterCommittee,
  setFirstTimeFeedOrganFilteringToFalse,
  addFeedFilterCommitteeCoverage,
  removeFeedFilterCommitteeCoverage,
} from "../../../slices/dataslice";
import commonStyles from "../../commonstyles";
import Checkbox from "../../event/checkbox";

export default function FeedOrganFiltering(props: PropTypes.object) {
  const firstTimeFeedOrganFiltering = useSelector(
    (state) => state.data.firstTimeFeedOrganFiltering
  );

  const dispatch = useDispatch();
  const [chosenOrgans, setChosenOrgans] = useState([]);
  const organs = useSelector((state) => state.data.committees);
  const coverage = useSelector((state) => state.data.userData.filterPresets);
  const subjects = useSelector((state) => state.data.filters.subjects);
  const [allChecked, setAllChecked] = useState<boolean>();

  useEffect(() => {
    let chosenOrgans;
    if (props.coverageMode) {
      console.log('coverage.subject useEffetc', coverage.subject)
      chosenOrgans = JSON.parse(coverage.subject);
      setAllChecked(chosenOrgans.length > 0);
    } else {
      chosenOrgans = subjects;
      setAllChecked(false);
    }
    setChosenOrgans(chosenOrgans);
  }, []);

  useEffect(() => {
    if (firstTimeFeedOrganFiltering && !props.coverageMode) {
      dispatch(setFirstTimeFeedOrganFilteringToFalse());
      organs
        .map((organ) => organ.id)
        .forEach((organId) => {
          dispatch(addFeedFilterCommittee(organId));
        });
      setAllChecked(true);
    }
  }, []);

  const handleOnChange = (organId: number) => {
    if (!props.coverageMode) {
      dispatch(toggleFeedFilterCommittee(organId));
    } else {
      dispatch(toggleFeedFilterCommitteeCoverage(organId));
    }

    const chosenOrgansAux = [...chosenOrgans];
    const index = chosenOrgansAux.indexOf(organId);
    if (index === -1) {
      chosenOrgansAux.push(organId);
    } else {
      chosenOrgansAux.splice(index, 1);
    }
    setChosenOrgans(chosenOrgansAux);
    setAllChecked(chosenOrgansAux.length > 0);
  };

  const toggleSelectAll = () => {
    if (allChecked) {
      if (props.coverageMode) {
        organs
          .map((organ) => organ.id)
          .forEach((organId) => {
            dispatch(removeFeedFilterCommitteeCoverage(organId));
          });
      } else {
        organs
          .map((organ) => organ.id)
          .forEach((organId) => {
            dispatch(removeFeedFilterCommittee(organId));
          });
      }
      setChosenOrgans([]);
    } else {
      if (props.coverageMode) {
        organs
          .map((organ) => organ.id)
          .forEach((organId) => {
            dispatch(addFeedFilterCommitteeCoverage(organId));
          });
      } else {
        organs
          .map((organ) => organ.id)
          .forEach((organId) => {
            dispatch(addFeedFilterCommittee(organId));
          });
      }
      setChosenOrgans(organs.map((organ) => organ.id));
    }
    setAllChecked(!allChecked);
  };

  return (
    <View style={styles.mainContainer}>
      <Pressable
        style={[
          styles.organ,
          commonStyles.borderStyle,
          { width: "100%", backgroundColor: "#2D2D2D" },
        ]}
        onPress={() => toggleSelectAll()}
      >
        <Text style={[{ color: "#fff" }, commonStyles.standardFont]}>
          {allChecked ? "Avmarkera alla ämnen" : "Välj alla ämnen"}
        </Text>
      </Pressable>
      {organs.map((organ, i) => (
        <Checkbox
          key={i}
          checked={chosenOrgans.includes(organ.id)}
          onChange={() => handleOnChange(organ.id)}
          onSelect={() => {
            !props.coverageMode
              ? dispatch(addFeedFilterCommittee(organ.id))
              : dispatch(addFeedFilterCommitteeCoverage(organ.id));
          }}
          onUnselect={() => {
            !props.coverageMode
              ? dispatch(removeFeedFilterCommittee(organ.id))
              : dispatch(removeFeedFilterCommitteeCoverage(organ.id));
          }}
          text={organ.title}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 4,
  },
  organ: {
    alignItems: "center",
    marginBottom: 10,
    padding: 8,
  },
});
