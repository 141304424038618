import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { baseURLForServer } from "../../config";
import { useMediaQuery } from "react-responsive";
import "chart.js/auto";
import ProfileBox from "./profilebox";
import EventBox from "./eventbox";
import Statistics from "../statistics/statistics";
import PropTypes from "prop-types";
import commonStyles from "../commonstyles";
import Share from "../share/share";
import Header from "../header/header";
import { useRoute } from "@react-navigation/native";
import Footer from "../footer/footer";

export default function Politician(props: PropTypes.object) {
  const [info, setInfo] = useState(undefined);

  const route = useRoute();

  const isDesktop = useMediaQuery({
    minWidth: 1000 + 48,
  });

  useEffect(() => {
    fetch(baseURLForServer + "/politicians/" + props.route.params.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setInfo(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  return (
    <>
      <Header />
      <ScrollView style={{ paddingTop: 50, marginTop: 70 }}>
        <View>
          <View
            style={isDesktop ? styles.mainContent : styles.mainContentSmall}
          >
            <View
              style={[
                styles.segmentContainer,
                { padding: 16 },
                commonStyles.bigShadow,
                commonStyles.bigRound,
              ]}
            >
              <ProfileBox info={info} />

              <View style={{ alignSelf: "flex-end", position: "absolute" }}>
                <Share />
              </View>
            </View>
            <View style={[styles.segmentContainer, { marginTop: 8 }]}>
              <Text style={[styles.segmentTitle, commonStyles.standardFont]}>
                STATISTIK
              </Text>
              <Statistics
                politicianOrParty="politician"
                identifier={info ? info.externalPoliticianId : undefined}
              />
            </View>
            <View style={[styles.segmentContainer, { marginTop: 8 }]}>
              <Text style={[styles.segmentTitle, commonStyles.standardFont]}>
                RELATERADE HÄNDELSER
              </Text>
              {info && (
                <EventBox events={info.events} navigation={props.navigation} />
              )}
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  mainContent: {
    width: 1000,
    alignItems: "center",
    alignSelf: "center",
  },
  mainContentSmall: {
    width: "100%",
    paddingHorizontal: 24,
    alignItems: "center",
  },
  segmentContainer: {
    width: "100%",
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  pictureShadow: {
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 8,
    alignSelf: "flex-start",
  },
  picture: {
    width: 192 / 2,
    height: 256 / 2,
    borderRadius: 8,
  },
  nameText: {
    marginLeft: 16,
    fontSize: 20,
    color: "#202020",
    fontWeight: "700",
  },
  expandInfoButton: {
    marginTop: 16,
    width: "100%",
    height: 42,
    borderRadius: 8,
    backgroundColor: "#fafafa",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  arrow: {
    width: 7,
    height: 12,
    opacity: 1,
    marginLeft: 16,
  },
  segmentTitle: {
    color: "#a4a4a4",
    fontSize: 14,
    marginBottom: 16,
  },
});
