import React from "react";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";
import commonStyles from "../commonstyles";

const Logo = (props: { navigation: any }) => {
  return (
    <View>
      <Pressable
        style={styles.logoImageAndText}
        onPress={() => props.navigation.navigate("Feed")}
      >
        <Image
          source={require("../../public/images/n.png")}
          style={styles.logoImage}
        />
        <Text style={[styles.logoText, commonStyles.headlineFont]}>
          noterat
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  logoImageAndText: {
    backgroundColor: "white",
    width: "100%",
    alignSelf: "center",
    paddingHorizontal: 24,
    paddingVertical: 24,
    alignItems: "center",
  },
  logoImage: {
    width: 27,
    height: 28,
  },
  logoText: {
    color: "#202020",
    fontSize: 15,
    marginTop: 4,
    lineHeight: 15,
  },
});

export default Logo;
