import React, { useState } from "react";
import { View, StyleSheet, Text, Modal } from "react-native";
import QuickButton from "./quickbutton";
import ProfilePicture from "../profilepicture";
import { useSelector } from "react-redux";
import { nameOrAnonymous } from "../../helpers";
import commonStyles from "../commonstyles";
import { useNavigation } from "@react-navigation/native";
import ModalLogin from "../modallogin";
import LoginButton from "../header/loginbutton";
import MyFlowButton from "../header/myflowbutton";

function ProfileCard() {
  const navigation = useNavigation();

  const userData = useSelector((state) => state.data.userData);

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  return (
    <View
      style={[
        styles.mainContainer,
        commonStyles.bigShadow,
        commonStyles.borderStyle,
      ]}
    >
      {Object.keys(userData).length !== 0 ? (
        <View style={{ alignItems: "center" }}>
          <View style={{ marginTop: 20 }}>
            <ProfilePicture
              firstName={userData.firstname}
              lastName={userData.lastname}
            />
          </View>
          <Text style={[styles.nameText, commonStyles.headlineFont]}>
            {nameOrAnonymous(userData.firstname, userData.lastname)}
          </Text>
          <View>
            <View
              style={{
                width: 210,
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              <View style={styles.quickInfo}>
                <Text
                  style={[
                    styles.quickInfoNumberText,
                    commonStyles.standardFont,
                  ]}
                >
                  {userData.countNoticesEvent || "0"}
                </Text>
                <Text
                  style={[styles.quickInfoTypeText, commonStyles.standardFont]}
                >
                  Nya
                </Text>
              </View>
              <View style={styles.quickInfo}>
                <Text
                  style={[
                    styles.quickInfoNumberText,
                    commonStyles.standardFont,
                  ]}
                >
                  {userData.userEventCount || "0"}
                </Text>
                <Text
                  style={[styles.quickInfoTypeText, commonStyles.standardFont]}
                >
                  Sparade
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 20,
                flexDirection: "column",
                alignItems: "center",
              }}
            ></View>
            <MyFlowButton />
            <QuickButton
              onPress={() => navigation.navigate("Profile", { section: 1 })}
              text="Sparade händelser"
            />
          </View>
        </View>
      ) : (
        <View style={[commonStyles.bigShadow, { padding: "2rem" }]}>
          <Text
            style={[
              {
                color: "#4a4a4a",
                textAlign: "center",
                lineHeight: 20,
                marginBottom: "1rem",
              },
              commonStyles.standardFont,
            ]}
          >
            Vill du spara händelser och skapa individuella bevakningar?
          </Text>
          <LoginButton onPress={showModal} isWhite />
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <ModalLogin close={() => setModalVisible(false)} />
          </Modal>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    alignSelf: "flex-start",
    flexDirection: "column",
    width: 240,
  },
  nameText: {
    fontSize: 16,
    marginTop: 20,
  },
  professionText: {
    color: "#4a4a4a",
    marginTop: 6,
  },
  quickInfo: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  quickInfoNumberText: {
    fontSize: 16,
    color: "#4a4a4a",
  },
  quickInfoTypeText: {
    fontSize: 14,
    color: "#4a4a4a",
    marginTop: 4,
  },
});

export default ProfileCard;
