import { useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, Text, Pressable, StyleSheet, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import commonStyles from "../commonstyles";
import LoadMoreButton from "./loadmorebutton";
import PartyChooser from "./partychooser";
import PoliticianCard from "./politiciancard";
import SearchBox from "./searchbox";
import { baseURLForServer } from "../../config";
import { appendPoliticians, setPoliticians } from "../../slices/dataslice";
import { useMediaQuery } from "react-responsive";
import { navigationRef } from "../../root_navigation";
import Footer from "../footer/footer";
import Header from "../header/header";
import Back from "../navigation/back";
import PropTypes from "prop-types";

const Politicianregistry = (props: PropTypes.object) => {
  const [pageState, setPageState] = useState(0);

  const isDesktop = useMediaQuery({
    minWidth: 1200,
  });
  const isNotSmall = useMediaQuery({
    minWidth: 600,
  });

  const route = useRoute();

  const politicians = useSelector((state) => state.data.politicians);

  const dispatch = useDispatch();

  const politicianFilters = useSelector(
    (state) => state.data.politicianFilters
  );

  const fetchPoliticians = (page) => {
    setPageState(page + 1);
    let partyString: string;
    politicianFilters.parties.forEach((party: string) => {
      partyString = partyString + "," + party;
    });
    // Send a request to the server...
    fetch(
      baseURLForServer +
        "/politicians?page=" +
        page.toString() +
        "&size=10&party=" +
        partyString +
        "&name=" +
        politicianFilters.search,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (page === 0) {
          dispatch(setPoliticians(json.items));
        } else {
          dispatch(appendPoliticians(json.items));
        }
        // setPageState(pageState + 1)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => fetchPoliticians(0), [politicianFilters]);

  useEffect(() => localStorage.setItem("currentURL", route.name), []);

  return (
    <>
      <Header />
      <ScrollView style={{ marginTop: 70, paddingTop: 15 }}>
        {isDesktop && <Back navigation={props.navigation} />}
        <View style={styles.container}>
          <View
            style={[
              styles.flexBox,
              !isDesktop && { flexDirection: "column", width: 552 },
              !isNotSmall && { width: "100%", paddingHorizontal: 24 },
            ]}
          >
            <View style={isDesktop && { flex: 0.3, marginRight: 24 }}>
              <View style={styles.filterSegment}>
                <View style={styles.filterTextView}>
                  <Text style={[styles.filterText, commonStyles.headlineFont]}>
                    FILTER
                  </Text>
                </View>
                <View style={styles.filterSegmentContent}>
                  <Text
                    style={[
                      styles.segmentText,
                      { marginBottom: 16 },
                      commonStyles.headlineFont,
                    ]}
                  >
                    Parti
                  </Text>
                  <PartyChooser />

                  {/* <Text style={[styles.segmentText, { marginBottom: 16 }]}>
            Tidsperiod
          </Text>
          <DatePicker which="from" />
          <Text
            style={{
              marginVertical: 12,
              textAlign: "center",
              color: "#a0a0a0",
            }}
          >
            Till
          </Text>
          <DatePicker which="to" />
          <Text style={[styles.segmentText, { marginBottom: 16 }]}>
            Ålder
          </Text>
          <AgePicker />
          <Text style={[styles.segmentText, { marginBottom: 16 }]}>
            Kön
          </Text>
          <GenderPicker /> */}
                </View>
              </View>
              <Pressable
                onPress={() => {
                  navigationRef.navigate("Feed");
                }}
                style={[
                  commonStyles.smallShadow,
                  commonStyles.smallRound,
                  { alignItems: "center", paddingVertical: 12 },
                ]}
              >
                <Text style={[commonStyles.standardFont, { color: "#202020" }]}>
                  Gå till nyhetsflöde
                </Text>
              </Pressable>
            </View>

            <View style={isDesktop ? { flex: 0.7 } : { marginTop: 16 }}>
              <SearchBox />
              {politicians.map((politician, i) => (
                <PoliticianCard key={i} {...politician} />
              ))}
              <LoadMoreButton onPress={() => fetchPoliticians(pageState)} />
            </View>
          </View>
          <Footer />
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
  },
  filterTextView: {
    paddingHorizontal: 16,
    justifyContent: "center",
    backgroundColor: "#fcfcfc",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: 54,
  },
  filterText: {
    color: "#4a4a4a",
    fontSize: 14,
  },
  filterSegment: {
    paddingBottom: 16,
    marginBottom: 16,
    shadowColor: "#e8e8e8",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    borderRadius: 12,
    alignSelf: "flex-start",
    width: "100%",
  },
  filterSegmentContent: {
    paddingHorizontal: 16,
  },
  segmentText: {
    color: "#4a4a4a",
    paddingTop: 16,
  },
  flexBox: {
    flexDirection: "row",
    marginTop: 12,
  },
});

export default Politicianregistry;
