import React from "react";
import { View, StyleSheet } from "react-native";
import Input from "./input";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { addComment } from "../../slices/feedandeventslice";
import { baseURLForServer } from "../../config";

function RespondBox(props: PropTypes.object) {
  const userData = useSelector((state) => state.data.userData);

  const dispatch = useDispatch();

  const postResponse = (textContent: string) => {
    fetch(baseURLForServer + "/comments/", {
      method: "POST",
      body: JSON.stringify({
        eventId: props.eventId,
        inResponseTo: props.inResponseTo,
        textContent: textContent,
        userId: userData.id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userData.jwt,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        props.onPost !== undefined && props.onPost();
        dispatch(
          addComment({
            id: json.id,
            eventId: json.eventId,
            userId: json.userId,
            textContent: json.textContent,
            inResponseTo: json.inResponseTo,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            user: {
              username: userData.username,
              firstName: userData.firstname,
              lastName: userData.lastname,
              party: userData.party,
            },
            responses: [],
            likes: 0,
            dislikes: 0,
            userReaction: {},
          })
        );
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <View style={props.inThread && styles.mainContainer}>
      <Input
        placeholder="Svara..."
        autoFocus={props.autoFocus}
        onCancel={props.onCancel}
        postComment={(textContent: string) => postResponse(textContent)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 24,
  },
});

export default RespondBox;
