import AsyncStorage from "@react-native-async-storage/async-storage";
import { baseURLForServer } from "./config";

export enum TimeRange {
  "ALL",
  "LAST WEEK",
  "LAST MONTH",
  "LAST SIX MONTHS",
  "LAST YEAR",
  "LAST FOUR YEARS",
}

const daysInMonths = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const storeData = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem("@" + key, value);
    return true;
  } catch (e) {
    // Saving error.
    return false;
  }
};

const getStoredData = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem("@" + key);
    if (value !== null) {
      // Value previously stored.
      return value;
    }
    // Value did not exist.
    return false;
  } catch (e) {
    // Error reading value.
    return false;
  }
};

const deleteData = async (key: string) => {
  try {
    await AsyncStorage.removeItem("@" + key);
  } catch (e) {
    return false;
  }
};

const checkToken = () => {
  // Return false if the LinkedIn token has expired.
  return true;
};

const urlEncodedParams = (params) => {
  let allParams = "";
  for (const key in params) {
    allParams = allParams + key + "=" + params[key] + "&";
  }
  return allParams.slice(0, -1);
};

/*
const generateAuthLink = (csrfCode: string) => {
  const base = authURL
  const params = {
    response_type: 'code',
    client_id: '78vup5ld2nkj4m',
    redirect_uri: baseURLForClient,
    state: csrfCode,
    scope: 'r_liteprofile'
  }
  return base + urlEncodedParams(params)
}
*/

const numberOfDays = (month: number, year: number) => {
  let leapYear = +(year % 4 === 0);
  if (leapYear && year % 100 === 0 && year % 400 !== 0) {
    leapYear = 0;
  }
  const addToDays = leapYear * +(month === 2);
  return daysInMonths[month] + addToDays;
};

const cleanHeadlineAndExtra = (headline: string, type: string) => {
  if (type === "MOTION") {
    const index = headline.search(/(19|20)\d{2}[/]\d+[:]\d+\s/g);
    if (index === -1) {
      return [headline, ""];
    }
    const firstMatch = headline.match(/(19|20)\d{2}[/]\d+[:]\d+\s/g)[0];

    if (index + firstMatch.length !== headline.length) {
      return [
        headline.substring(index + firstMatch.length),
        headline.substring(0, index + firstMatch.length - 1),
      ];
    } else {
      return [headline, ""];
    }
  }
  return [headline, ""];
};

const minutesToHoursAndMinutes = (minutes: number) => {
  return (
    Math.floor(minutes / 60) +
    " h, " +
    (Math.floor(minutes) - Math.floor(minutes / 60) * 60) +
    " m"
  );
};

const convertTimePeriod = (timePeriod: string) => {
  const getTimeOffset = (offset: number) => {
    let searchFromDate = new Date();
    if (offset === 0) searchFromDate = new Date(0);
    else searchFromDate.setDate(searchFromDate.getDate() - offset);
    return searchFromDate.toISOString();
  };

  switch (timePeriod) {
    case "LAST WEEK":
      return getTimeOffset(7);
    case "LAST MONTH":
      return getTimeOffset(28);
    case "LAST SIX MONTHS":
      return getTimeOffset(168);
    case "LAST YEAR":
      return getTimeOffset(365);
    case "LAST FOUR YEARS":
      return getTimeOffset(1461);
    default:
      return getTimeOffset(0);
  }
};

const getStatisticsUrl = (
  politicianOrParty: string,
  identifier: string[],
  organs,
  period: string
) => {
  if (politicianOrParty === "politician") {
    if (organs.length !== 1) {
      return baseURLForServer + "/statistics/party/organ?party=" + identifier;
    } else {
      return (
        baseURLForServer +
        "/statistics/politician?externalPoliticianId=" +
        identifier +
        "&organ=" +
        organs[0].committee
      );
    }
  } else {
    const convertedPeriod = convertTimePeriod(period);
    if (organs.length !== 1) {
      return (
        baseURLForServer +
        "/statistics/party/organ?party=" +
        identifier +
        "&period=" +
        convertedPeriod
      );
    } else {
      return (
        baseURLForServer +
        "/statistics/party?party=" +
        identifier +
        "&organ=" +
        organs[0].committee +
        "&period=" +
        convertedPeriod
      );
    }
  }
};

const getStatisticsUrlParliament = (organs, period: string) => {
  const convertedPeriod = convertTimePeriod(period);
  if (organs.length !== 1) {
    return (
      baseURLForServer +
      "/statistics/parliament/organ" +
      "?period=" +
      convertedPeriod
    );
  } else {
    return (
      baseURLForServer +
      "/statistics/parliament?organ=" +
      organs[0].committee +
      "&period=" +
      convertedPeriod
    );
  }
};

const nameOrPlaceholder = (firstName: string, lastName: string): string => {
  if (
    (firstName === null && lastName === null) ||
    (firstName === "" && lastName === "") ||
    (firstName === undefined && lastName === undefined)
  ) {
    return "";
  } else {
    return firstName + " " + lastName;
  }
};

const nameOrAnonymous = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    return firstName + " " + lastName;
  } else {
    return firstName;
  }
};

const numberOfCoverages = (coverage) => {
  let count = 0;
  if (!coverage) {
    return "";
  }
  if (coverage.subject && JSON.parse(coverage.subject)) {
    count = count + JSON.parse(coverage.subject).length;
  }
  if (coverage.party && JSON.parse(coverage.party)) {
    count = count + JSON.parse(coverage.party).length;
  }
  if (coverage.politician && JSON.parse(coverage.politician)) {
    count = count + JSON.parse(coverage.politician).length;
  }
  return count;
};

export {
  storeData,
  getStoredData,
  deleteData,
  checkToken,
  urlEncodedParams,
  numberOfDays,
  cleanHeadlineAndExtra,
  minutesToHoursAndMinutes,
  getStatisticsUrl,
  getStatisticsUrlParliament,
  nameOrPlaceholder,
  nameOrAnonymous,
  numberOfCoverages,
};
