import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Linking,
  Pressable,

} from "react-native";
import PropTypes from "prop-types";
import { contactFormFeatureToggle } from "../../../featureToggles";

import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";

export default function Contactinformation(props: PropTypes.object) {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isTablet = useMediaQuery({
    maxWidth: 870,
  });

  return (
    <View>
      <View
          style={[styles.titleBackground, styles.kontaktuppgifterTitle]}
      >
        <Text style={styles.h2}>Kontaktuppgifter</Text>
      </View>
      <Text style={[styles.p, styles.kontaktuppgifterP]}>
        {
          "Vi tar tacksamt emot alla förslag på förbättringar eller samarbeten. \nHar du funderingar? Hör av dig!"
        }
      </Text>
      <View style={styles.contactDetailsWrapper}>
        <Pressable
            style={[
              styles.contactDetail,
              isTablet && styles.contactDetailMobile,
              !contactFormFeatureToggle && styles.contactDetailMobile,
            ]}
            onPress={() =>
                Linking.openURL("https://goo.gl/maps/eL6pB866M8cNaFNN8")
            }
        >
        </Pressable>
        <Pressable
            style={[
              styles.contactDetail,
              isTablet && styles.contactDetailMobile,
              !contactFormFeatureToggle && styles.contactDetailMobile,
            ]}
            onPress={() => Linking.openURL(`tel:086411010`)}
        >
          <View
              style={[
                styles.iconView,
                isTablet && styles.iconViewMobile,
                !contactFormFeatureToggle && styles.iconViewMobile,
              ]}
          >
            <FontAwesomeIcon
                icon={faPhone}
                style={{
                  fontSize: 27,
                  lineHeight: 20,
                  color: "#202020",
                }}
                width={27}
                height={27}
            />
          </View>
          <View>
            <Text
                style={[
                  styles.contactDetailText,
                  styles.contactDetailh3,
                  isTablet && styles.contactDetailTextMobile,
                  !contactFormFeatureToggle &&
                  styles.contactDetailTextMobile,
                ]}
            >
              TELEFONNUMMER:
            </Text>
            <Text
                style={[
                  styles.contactDetailText,
                  isTablet && styles.contactDetailTextMobile,
                  !contactFormFeatureToggle &&
                  styles.contactDetailTextMobile,
                ]}
            >
              {isMobile || isTablet
                  ? "08 641 10 10\n(8-17 Vardagar)"
                  : "08 641 10 10 (8-17 Vardagar)"}
            </Text>
          </View>
        </Pressable>

        <Pressable
            style={[
              styles.contactDetail,
              isTablet && styles.contactDetailMobile,
              !contactFormFeatureToggle && styles.contactDetailMobile,
            ]}
            onPress={() =>
                Linking.openURL("mailto:chair@change-equity.com")
            }
        >
          <View
              style={[
                styles.iconView,
                isTablet && styles.iconViewMobile,
                !contactFormFeatureToggle && styles.iconViewMobile,
              ]}
          >
            <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  fontSize: 27,
                  lineHeight: 20,
                  color: "#202020",
                }}
                width={27}
                height={27}
            />
          </View>
          <View>
            <Text
                style={[
                  styles.contactDetailText,
                  styles.contactDetailh3,
                  isTablet && styles.contactDetailTextMobile,
                  !contactFormFeatureToggle &&
                  styles.contactDetailTextMobile,
                ]}
            >
              E-POSTADRESS:
            </Text>
            <Text
                style={[
                  styles.contactDetailText,
                  isTablet && styles.contactDetailTextMobile,
                  !contactFormFeatureToggle &&
                  styles.contactDetailTextMobile,
                ]}
            >
              chair@change-equity.com
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    width: "100%",
    flex: 1,
    textAlign: "center",
  },
  body: {
    width: "80%",
    maxWidth: 1072,
    alignSelf: "center",
    flex: 1,
    justifyContent: "center",
  },
  bodyMobile: { width: "100%" },
  article: {
    flexDirection: "row",
    shadowColor: "rgba(100, 100, 111, 0.2)",
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowRadius: 29,
    elevation: 5,
    marginTop: "3rem",
    marginBottom: "5rem",
  },
  articleMobile: {
    flexDirection: "column",
    shadowColor: "none",
    marginBottom: 0,
  },
  formSide: { backgroundColor: "#F9F8F7", width: "56%", padding: "3rem" },
  formSideMobile: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    padding: "1rem",
    paddingBottom: "3rem",
  },
  contactSide: {
    width: "44%",
    padding: "2rem",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  contactSideMobile: {
    backgroundColor: "#F9F8F7",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "2rem",
  },
  titleBackground: {
    height: 20,
    justifyContent: "center",
    marginTop: 16,
    alignSelf: "center",
    backgroundColor: "#FFFE9A",
  },
  kontaktaOssTitle: { marginBottom: "3rem" },
  kontaktaOssTitleMobile: { marginBottom: "1rem" },
  kontaktuppgifterTitle: { marginBottom: "0.5rem" },
  kontaktuppgifterP: { width: "80%", marginBottom: "2rem" },
  contactDetailsWrapper: { margin: "auto" },
  contactDetail: { flexDirection: "row", marginBottom: "2rem" },
  contactDetailMobile: { flexDirection: "column" },
  contactDetailText: {
    textAlign: "left",
    marginLeft: "2rem",
    lineHeight: 25,
    fontSize: 14,
    fontWeight: "600",
  },
  contactDetailTextMobile: { textAlign: "center", marginLeft: 0 },
  contactDetailh3: { fontSize: 16, fontWeight: "700" },
  iconView: { justifyContent: "center" },
  iconViewMobile: { alignItems: "center", marginBottom: "1rem" },
  h1: {
    fontFamily: "Poppins_Bold",
    fontSize: 28,
    fontWeight: "700",
    lineHeight: 48,
    marginTop: "2rem",
  },
  h2: {
    fontFamily: "Poppins_500Medium",
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 30,
    position: "relative",
    top: -4,
    left: -4,
  },
  p: {
    fontFamily: "museo_sans500",
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 6,
    marginBottom: 20,
    flexWrap: "wrap",
  },
  pMobile: {
    lineHeight: 14,
    fontSize: 14,
  },
  bodyWOForm: { alignItems: "center" },
  articleWOForm: { width: "90%", marginBottom: 20, marginTop: "1rem" },
  contactSideWOForm: { width: "100%", paddingTop: "2rem" },
});
