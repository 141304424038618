import React, { useRef, useState } from "react";
import { Animated, StyleSheet, Text, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import "@expo/match-media";
import MediaQuery from "react-responsive";
import { Link } from "@react-navigation/native";
import { HelpButton, CloseHelpButton } from "./helpbutton";
import Bookmark from "./bookmark";
import TopComment from "./topcomment";
import PartyBadge from "../partybadge";
import { cleanHeadlineAndExtra } from "../../helpers";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import commonStyles from "../commonstyles";
import { commentFeaturToggle } from "../../featureToggles";

const cardHelps = {
  "SKRIFTLIG FRÅGA": [
    "SKRIFTLIG FRÅGA",
    "Genom skriftliga frågor kan riksdagsledamöter året om ställa frågor till statsråd i begränsade ärenden. Statsråd kan besvara frågor med skriftliga svar.",
  ],
  "KU ANMÄLAN": [
    "KU ANMÄLAN",
    "Ett av kontrollredskapen som riksdagen kan använda sig av är Konstitutionsutskottet, KU, vars uppgift är att granska regeringen. Riksdagsledamöter kan göra KU-anmälningar till utskottet året runt. KU prövar sedan huruvida den anmälde ministern gjort fel eller inte.",
  ],
  MOTION: [
    "MOTION",
    "En motion är ett förslag väckt av en eller flera riksdagsledamot/riksdagsledamöter. Efter att en motion har lagts fram hamnar den i ett av riksdagens utskott och förbereds för att tas upp för beslut i kammaren.",
  ],
  IP: [
    "INTERPELLATION",
    "Interpellationer är en typ av frågor som debatteras i kammaren nästan varje vecka. Ledamoten ställer sin fråga, interpellationen, skriftligt till en minister i regeringen men får svaret både skriftligt och muntligt av ministern som kommer till kammaren. Vid interpellationsdebatten i kammaren börjar ministern med att läsa upp sitt svar på interpellationen. Sedan blir det debatt mellan ministern och ledamoten som ställt frågan. Även andra ledamöter får delta i debatten.",
  ],
  YTTRANDE: [
    "YTTRANDE",
    "Ett utskott som ansvarar för en viss fråga kan be om synpunkter från ett annat utskott. Det kallas yttrande och tas med i det betänkande som det ansvariga utskottet lämnar inför riksdagens beslut i frågan.",
  ],
};

function Card(props: PropTypes.object) {
  const [showHelp, setShowHelp] = useState(false);

  const helpOn = useSelector((state) => state.data.help);

  const anim = useRef(new Animated.Value(0)).current;
  const [questionOrAnswer, setQuestionOrAnswer] = useState(0);
  anim.addListener((val) => {
    if (val.value >= 0.25) {
      if (questionOrAnswer === 0) {
        setQuestionOrAnswer(3);
      } else if (questionOrAnswer === 1) {
        setQuestionOrAnswer(2);
      }
    }
  });
  const spin = anim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <Animated.View
      style={{
        flexDirection: "column",
        alignItems: "center",
        transform: [{ rotateY: spin }, { perspective: 1000 }],
      }}
    >
      <MediaQuery maxWidth={640}>
        {(matches: boolean) => (
          <View
            style={[
              styles.mainContainer,
              props.noMargin && { marginBottom: 0 },
              commonStyles.bigShadow,
              commonStyles.borderStyle,
              matches && styles.maxWidth,
            ]}
          >
            <View style={[styles.mainSegment, matches && styles.maxWidth]}>
              {showHelp && (
                <View>
                  <View style={{ flexDirection: "row", marginBottom: 12 }}>
                    <Text style={[styles.typeText, commonStyles.headlineFont]}>
                      {cardHelps[props.type][0]}
                    </Text>
                    <CloseHelpButton
                      onPress={() => {
                        setShowHelp(false);
                      }}
                    />
                  </View>
                  <Text style={[styles.helpText, commonStyles.standardFont]}>
                    {cardHelps[props.type][1]}
                  </Text>
                </View>
              )}
              {!showHelp && (
                <View>
                  <View style={{ flexDirection: "row", marginBottom: 12 }}>
                    <Text style={[styles.typeText, commonStyles.headlineFont]}>
                      {cardHelps[props.type][0]}
                    </Text>
                    {helpOn && (
                      <HelpButton
                        onPress={() => {
                          setShowHelp(true);
                        }}
                      />
                    )}
                  </View>
                  <Bookmark eventId={props.id} userEvent={props.userEvent} />
                  <Hoverable>
                    {(isHovered) => (
                      <Link
                        to={"/event/" + props.id}
                        style={[
                          commonStyles.headlineBold,
                          styles.titleText,
                          isHovered ? styles.hovered : null,
                        ]}
                      >
                        {cleanHeadlineAndExtra(props.headline, props.type)[0]}
                      </Link>
                    )}
                  </Hoverable>
                  <Text style={[styles.dateText, commonStyles.standardFont]}>
                    Publicerad {props.datePosted}
                    {cleanHeadlineAndExtra(props.headline, props.type)[1] !==
                      "" &&
                      ", " +
                      cleanHeadlineAndExtra(props.headline, props.type)[1]}
                  </Text>
                  {props.politicians && props.politicians.length > 0 && (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: "flex-end",
                        marginTop: 8,
                      }}
                    >
                      <Text
                        style={[
                          { color: "#a4a4a4" },
                          commonStyles.standardFont,
                        ]}
                      >
                        Involverar politiker från
                      </Text>
                      <PartyBadge
                        party={
                          props.type === "MOTION" ||
                            props.type === "IP" ||
                            props.type === "YTTRANDE"
                            ? props.politicians[0].party
                            : props.politicians.filter(
                              (p) =>
                                p.event_politician.role === "undertecknare"
                            ).length > 0
                              ? props.politicians.filter(
                                (p) =>
                                  p.event_politician.role === "undertecknare"
                              )[0].party
                              : ""
                        }
                        border={true}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>
            <View
              style={[
                styles.discussionSegment,
                matches ? styles.maxWidth : null,
              ]}
            >
              {commentFeaturToggle && (
                <View>
                  <Text
                    style={[styles.discussionTitle, commonStyles.headlineFont]}
                  >
                    TOPPKOMMENTAR
                  </Text>

                  <TopComment comment={props.comments[0]} />
                </View>
              )}
            </View>
          </View>
        )}
      </MediaQuery>
    </Animated.View>
  );
}

export default Card;

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 20,
    width: 660,
  },
  mainSegment: {
    padding: 20,
    borderColor: "#e8e8e8",
  },
  maxWidth: {
    width: "100%",
  },
  helpTitle: {
    fontSize: 20,
  },
  helpText: {
    color: "#4a4a4a",
  },
  typeText: {
    color: "#4a4a4a",
  },
  titleText: {
    paddingRight: 8,
  },
  hovered: {
    textDecorationLine: "underline",
  },
  dateText: {
    marginTop: 8,
    color: "#4a4a4a",
    fontSize: 14,
  },
  discussionSegment: {
    paddingHorizontal: 20,
    paddingVertical: 0, // 12
    backgroundColor: "#fcfcfc",
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  discussionTitle: {
    color: "#949494",
  },
});
