import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Pressable, View, Text } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import commonStyles from "../commonstyles";

interface CheckBoxProps {
    checked: boolean;
    onChange: () => void;
    onSelect: () => void;
    onUnselect: () => void;
    text: any;
}

const defaultProps: CheckBoxProps = {
    checked: false,
    onChange: () => { },
    onSelect: () => { },
    onUnselect: () => { },
    text: undefined,
};

const Checkbox = ({ checked: initialCheck, onChange, onSelect, onUnselect, text }: CheckBoxProps) => {
    const [checked, toggleChecked] = useState(initialCheck);
    const firstExecution = useRef(true);

    useEffect(() => {
        if (firstExecution.current === false) {
            if (initialCheck) {
                onSelect();
                toggleChecked(true)
            } else {
                onUnselect();
                toggleChecked(false)
            }
        } else {
            firstExecution.current = false
        }
    }, [initialCheck])

    function onCheckmarkPress() {
        onChange();
        toggleChecked(!checked);
    }

    return (
        <Pressable onPress={onCheckmarkPress}>
            <View style={styles.container}>
                <FontAwesomeIcon
                    style={{
                        marginRight: 10,
                        fontSize: 22,
                        lineHeight: 22,
                        color: "#202020",
                    }}
                    width={22}
                    height={22}
                    icon={checked ? regular("square-check") : regular("square")}
                />
                <Text
                    style={[
                        commonStyles.standardFont,
                        { color: "#202020" },
                    ]}
                >
                    {text}
                </Text>
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
    },
});

Checkbox.defaultProps = defaultProps;

export default Checkbox;
