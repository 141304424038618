import React, { useState } from "react";
import { TextInput, Text, View, StyleSheet, Pressable } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { useMediaQuery } from "react-responsive";

export default function ContactForm() {
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = () => {
    setEmailSent(true);
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <>
      <View
        style={[
          styles.titleBackground,
          styles.kontaktaOssTitle,
          ,
          isMobile && styles.kontaktaOssTitleMobile,
        ]}
      >
        <Text style={styles.h2}>Kontakta oss</Text>
      </View>
      <TextInput style={styles.input} placeholder="Namn/Organisation" />
      <TextInput style={styles.input} placeholder="E-postadress*" />
      <TextInput
        multiline={true}
        numberOfLines={5}
        style={styles.input}
        placeholder="Meddelande*"
      />
      {!emailSent ? (
        <View
          style={[styles.buttonWrapper, isMobile && styles.buttonWrapperMobile]}
        >
          <Hoverable>
            {(isHovered) => (
              <Pressable onPress={sendEmail}>
                <View
                  style={[styles.button, isHovered && styles.buttonIsHovered]}
                >
                  <Text
                    style={[
                      isHovered && styles.buttonIsHovered,
                      styles.buttonFont,
                    ]}
                  >
                    skicka
                  </Text>
                </View>
              </Pressable>
            )}
          </Hoverable>
        </View>
      ) : (
        <Text style={styles.feedBackText}>
          Tack! Vi har tagit emot ditt meddelande.
        </Text>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  h2: {
    fontFamily: "Poppins_500Medium",
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 30,
    position: "relative",
    top: -4,
    left: -4,
  },
  input: {
    backgroundColor: "#FFFFFF",
    borderColor: "rgba(228, 228, 228, 0.7)",
    borderWidth: 1,
    borderRadius: 3,
    padding: 10,
    placeholderTextColor: "rgba(45, 45, 45, 0.5)",
    marginBottom: 10,
  },
  buttonWrapper: {
    width: "40%",
    marginTop: "1.5rem",
    alignSelf: "center",
  },
  buttonWrapperMobile: { width: "35%" },
  buttonIsHovered: {
    backgroundColor: "#2D2D2D",
    color: "#FFF",
  },
  buttonFont: {
    fontFamily: "Poppins_500Medium",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 8,
    flexDirection: "row",
    height: 42,
    justifyContent: "center",
    paddingHorizontal: 24,
    borderColor: "#2D2D2D",
    borderWidth: 1,
  },
  titleBackground: {
    height: 20,
    justifyContent: "center",
    marginTop: 16,
    alignSelf: "center",
    backgroundColor: "#FFFE9A",
  },
  feedBackText: {
    color: "green",
    fontFamily: "museo_sans500",
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    marginTop: "2rem",
  },
  kontaktaOssTitle: { marginBottom: "3rem" },
  kontaktaOssTitleMobile: { marginBottom: "1rem" },
});
