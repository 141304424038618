import { View, Text, Pressable, Image, StyleSheet } from "react-native";
import React, { useState } from "react";
import commonStyles from "../commonstyles";
import { useMediaQuery } from "react-responsive";

function Arrow(props: { expanded: boolean; vertical: boolean }) {
    return (
        <View>
            {props.expanded ? (
                <Image
                    source={require("../../public/images/soft_arrow_black.svg")}
                    style={[
                        styles.arrow,
                        props.vertical && { transform: [{ rotateZ: "90deg" }] },
                    ]}
                />
            ) : (
                <Image
                    source={require("../../public/images/soft_arrow_black.svg")}
                    style={[
                        styles.arrow,
                        props.vertical && { transform: [{ rotateZ: "0deg" }] },
                    ]}
                />
            )}
        </View>
    );
}

interface ExpandableViewProps {
    onPress: () => void;
    textButton: string;
    content: any;
}

const ComponentExpandable = ({
    onPress,
    textButton,
    content,
}: ExpandableViewProps) => {
    const [expanded, setExpanded] = useState(false);

    const onClick = () => {
        setExpanded(!expanded);
        onPress();
    };

    const isDesktop = useMediaQuery({
        minWidth: 1200,
    });

    return (
        <View style={[styles.mainContainer, commonStyles.borderStyle]}>
            <Pressable onPress={() => onClick()} style={{ width: "100%" }}>
                <View style={styles.viewTitleTypes}>
                    <Text style={[styles.optionText, commonStyles.headlineFont]}>
                        {textButton}
                    </Text>
                    <Arrow expanded={expanded} vertical={true} />
                </View>
            </Pressable>
            {expanded && (
                <View
                    style={[styles.viewContant, isDesktop && { maxWidth: "fit-content" }]}
                >
                    {content}
                </View>
            )}
        </View>
    );
};

export default ComponentExpandable;

const styles = StyleSheet.create({
    mainContainer: {
        alignItems: "center",
        backgroundColor: "#FFF",
        paddingVertical: 8,
        paddingHorizontal: 16,
        width: "100%",
    },
    viewTitleTypes: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 5,
    },
    viewContant: {
        marginTop: 5,
        width: "100%",
    },
    arrow: {
        height: 12,
        opacity: 1,
        width: 7,
    },
    optionText: {
        color: "#202020",
        textAlign: "center",
    },
});
